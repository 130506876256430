import React, { useCallback, useEffect, useState } from "react";
import "./App.css";
import { useDispatch } from "react-redux";
import { Templatedata, Rightscreen } from "../Redux Toolkit/slice";
import { useSelector } from "react-redux";

function App() {
  var dispatch = useDispatch();
  const [isRun, setRun] = useState(false);
  const templateData = useSelector((state) => state.Data.Messagetemplatedata);
  const [message, setMessage] = useState("");
  const [merror, setMerror] = useState("");
  const [Uerror, setUerror] = useState("");
  const [lerror, setLerror] = useState("");
  const [berror, setBerror] = useState("");
  const [body, setBody] = useState("");
  const [count, setCount] = useState(0);
  const [switchStates, setSwitchStates] = useState(Array(count).fill(false));
  const [inputValues, setInputValues] = useState(Array(count).fill(""));
  const [urls, setUrls] = useState(Array(count).fill(""));
  const [characterCount, setCharacterCount] = useState(0);
  const [bCharCount, setBCharCount] = useState(0);
  const [hCharCount, setHCharCount] = useState(0);
  const [mschrCount, setMSChrCount] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedBtnType, setSelectedBtnType] = useState("QUICK_REPLY");
  const [selectedOption, setSelectedOption] = useState("none");
  const [buttonsData, setButtonsData] = useState([]);
  const [header, setHeader] = useState("");
  const [footermessage, setFooterMs] = useState("Sent via AWS CRM");
  const [headerImg, setHeaderImg] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showbtn, setShowBTN] = useState(false);
  const [showbtnlbl, setShowBTNlbl] = useState(false);
  const [selectedOption2, setSelectedOption2] = useState("");
  const [place, setPlace] = useState("");
  const [showurl, setShowUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [chkValid, setChkValid] = useState(true);
  const [imgrecord, setImgrec] = useState(null);
  useEffect(() => {
    if (templateData) {
      setMessage(templateData.template_name || "");
      setBody(templateData.body || "");
      setSelectedLanguage(templateData.language || "");
      setHeader(templateData.header || "");
      setFooterMs(templateData.footer || "Sent via AWS CRM");
      setSelectedBtnType(templateData.selectedButtonType || "");
      setInputValues(templateData.buttonsData?.map((button) => button.text) || []);
      setPlace(templateData.selectedButtonType === "URL" ? "URL" : "Quick Reply");
      setUrls(templateData.buttonsData?.map((button) => button.url) || []);
      setCount(templateData.numberOfButtons || 0);
      setCharacterCount(templateData.template_name?.length || 0);
      setBCharCount(templateData.body?.length || 0);
      setHCharCount(templateData.header?.length || 0);
      setMSChrCount(templateData.footer?.length || 0);
      setShowUrl(templateData.showUrl || "");
      setButtonsData(templateData.buttonsData || []);
      setSelectedOption(templateData.header_format || "");
      setFileName(templateData.imgrec ? templateData.imgrec.name : "");
      setHeaderImg(templateData.header_img || "");
    }
  }, [templateData]);
  
  
  const handleOption2Change = useCallback(
    (event) => {
      const value = event.target.value;
      const data=event.target.btns;
      setSelectedOption2(value);
      const newCount = parseInt(value);
      setCount(newCount);
      setInputValues(data?.map(e=>e.text)||[]);
      setUrls(data?.map(e=>e.url)||[]);
      setSwitchStates(data?.map(e=>e.type==="URL"?true:false)||[]);
      
      setShowBTNlbl(true);

      
    },
    []
  );
  

  useEffect(() => {
    handleBtnTypeChange();
    if (templateData?.selectedOption2 && isRun === false) {
      handleOption2Change({ target: { value: templateData.selectedOption2,btns:templateData.buttonsData } });
      if (templateData.header || templateData.header_img) {
        setSelectedOption(templateData.header_format || "");
        setHeaderImg(templateData.header_img || "")
        if(templateData.header_format==="Image"){
          setShowImage(true);
          setShowInput(false);
          setFileName(templateData.imgrec?templateData.imgrec.name: null);
        }
        if(templateData.header_format==="text"){
          setShowImage(false);
          setShowInput(true);
        }
      }
    }
  }, [handleOption2Change, isRun, templateData.selectedOption2]);

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value === "Image") {
      setShowImage(true);
      setShowInput(false);
    } else if (value === "text") {
      setShowInput(true);
      setShowImage(false);
    } else {
      setShowInput(false);
      setShowImage(false);
    }
  };

  const handleMessageChange = (e) => {
    const { value } = e.target;
    const regex = /^[a-z0-9_]*$/;

    if (value.length > 60) {
      setMerror("Template name cannot exceed 60 characters.");
    } else if (!regex.test(value)) {
      setMerror(
        "*No capital letters, spaces, or special characters are allowed except underscores*"
      );
    } else {
      setMerror("");
    }

    setMessage(value);
    setCharacterCount(value.length);
  };
  const handleHeaderChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= 60) {
      setHeader(inputText);
      setHCharCount(inputText.length);
    }
  };
  const handleFooterChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= 60) {
      setFooterMs(inputText);
      setMSChrCount(inputText.length);
    }
  };
  const handleChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= 1024) {
      setBody(inputText);
      setBCharCount(inputText.length);
    }
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };
  const handleBtnTypeChange = () => {
    setShowBTN(true);
    setSelectedOption2("");
    setInputValues([""]);
    setCount(0);
    setShowUrl("none");
    setShowBTNlbl(false);
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const imageData = reader.result;
        setHeaderImg(imageData);
      };
      setImgrec(file);
      setFileName(file.name);
    } else {
      setFileName(null);
      setImgrec(null);
    }
  };
  

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (file) {
      const fileURL = URL.createObjectURL(file);
      setHeaderImg(fileURL);
      setFileName(file.name || null);
      setImgrec(file);
    }
  };

  const handleSwitchChange = (index, value) => {
    const newSwitchStates = [...switchStates];
    newSwitchStates[index] = value;
    setSwitchStates(newSwitchStates);
  };

  const handleTextInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };

  const handleUrlInputChange = (index, value) => {
    const newUrls = [...urls];
    newUrls[index] = value;
    setUrls(newUrls);
  };

  const HandleSave = (e) => {
    e.preventDefault();

    if (message === "") {
      setMerror("*Please Enter Tamplate Name*");
      setBerror("");
      setLerror("");
      setUerror("");
    } else if (selectedLanguage === "") {
      setLerror("*Please Select Language*");
      setMerror("");
      setBerror("");
      setUerror("");
    } else if (body === "") {
      setBerror("*Please Enter Body Text*");
      setMerror("");
      setLerror("");
      setUerror("");
    } else {
      if (chkValid) {
        let isValidUrl = true;
        const urlRegex =
          /\b((http|https|ftp):\/\/)?(([\w-]+\.)+[\w-]{2,})(:\d{2,5})?(\/[^\s]*)?\b/;

        for (let i = 0; i < urls.length; i++) {
          if (urls[i] && !urlRegex.test(urls[i])) {
            isValidUrl = false;
            break;
          }
        }
        if (!isValidUrl) {
          setIsValid(false);

          setUerror("*This is not a valid URL*");
          setMerror("");
          setLerror("");
          setBerror("");
        } else {
          let Data = [];
          Array.from({ length: count }, (_, index) => {
            if (switchStates[index]) {
              const textValue = document.getElementById(`input${index}`).value;
              const linkValue = document.getElementById(`URL${index}`).value;
              let obj = {
                type: "URL",
                text: textValue,
                url: linkValue,
              };
              Data = [...Data, obj];
              setButtonsData(Data);
            } else {
              const textValue = document.getElementById(`input${index}`).value;
              let obj = { type: "QUICK_REPLY", text: textValue };
              Data = [...Data, obj];
              setButtonsData(Data);
            }
          });

          let dtt = {
            template_name: message,
            body: body,
            language: selectedLanguage,
            header: header,
            header_img: headerImg,
            imgrec:imgrecord,
            category: "MARKETING",
            numberOfButtons: count,
            selectedButtonType: selectedBtnType,
            header_format: selectedOption,
            buttonsData: Data ? Data : [],
            footer: footermessage,
            selectedOption2: selectedOption2,
            showUrl: showurl,
          };
          dispatch(Templatedata(dtt));
          dispatch(Rightscreen("9"));
        }
      } else {
        let Data = [];
        Array.from({ length: count }, (_, index) => {
          if (switchStates[index]) {
            const textValue = document.getElementById(`input${index}`).value;
            const linkValue = document.getElementById(`URL${index}`).value;
            let obj = {
              type: "URL",
              text: textValue,
              url: linkValue,
            };
            Data = [...Data, obj];
            setButtonsData(Data);
          } else {
            const textValue = document.getElementById(`input${index}`).value;
            let obj = { type: "QUICK_REPLY", text: textValue };
            Data = [...Data, obj];
            setButtonsData(Data);
          }
        });
        let dtt = {
          template_name: message,
          body: body,
          language: selectedLanguage,
          header: header,
          header_img: headerImg,
          category: "MARKETING",
          numberOfButtons: count,
          imgrec:imgrecord,
          selectedButtonType: selectedBtnType,
          header_format: selectedOption,
          buttonsData: Data ? Data : [],
          footer: footermessage,
          selectedOption2: selectedOption2,
          showUrl: showurl,
        };

        dispatch(Templatedata(dtt));
        dispatch(Rightscreen("9"));
      }
    }
  };
  return (
    <div className=" container p-0 m-0" 
    style={{ borderRadius: '0',
       
      height: "82vh",
     
      width: "auto",
      backgroundColor: "#ffffff",
      margin: "0",
     }}>
      <h1 className="title">Create Message Template</h1>
      <div className="group">
        <div className="input-row">
          <label className="labelT" htmlFor="templateName">
            Template Name
          </label>
          <div className="character-count">{characterCount}/60</div>
        </div>
        {
          <input
            type="text"
            id="templateName"
            value={message}
            onChange={handleMessageChange}
            placeholder="Template Name"
            className="input-field"
          />
        }
        <p
          style={{
            display: "flex",
            justifyContent: "start",
            fontSize: 16,
            color: "red",
          }}
        >
          {merror}
        </p>
        <div style={{ marginTop: 25 }} className="input-row">
          <label className="labelT lab2" htmlFor="language">
            Language
          </label>
          <select
            style={{ fontFamily: "Arial, sans-serif" }}
            id="language"
            value={selectedLanguage}
            onChange={handleLanguageChange}
            className="language-dropdown1"
          >
            <option value="" disabled hidden>
              Select Language
            </option>
            <option value="en_US">English</option>
            <option value="ar">Arabic</option>
          </select>
        </div>
        <p
          style={{
            display: "flex",
            justifyContent: "end",
            fontSize: 16,
            color: "red",
            marginRight: 360,
          }}
        >
          {lerror}
        </p>
      </div>
      <div className="group">
        <div style={{ width: "100%" }}>
          <div className="input-row">
            <div className="lab2">
              <span className="labelT1">Message Header </span>(Optional)
            </div>
            <select
              id="options"
              onChange={handleOptionChange}
              className="language-dropdown1"
              value={selectedOption}
            >
              <option value="none">None</option>
              <option value="Image">Image</option>
              <option value="text">Text</option>
            </select>
          </div>
          {showInput && (
            <div style={{ marginTop: 15 }}>
              <div className="input-row">
                <label className="subheading" htmlFor="templateName">
                  Enter Text
                </label>
                <div className="character-count">{hCharCount}/60</div>
              </div>
              <input
                type="text"
                id="templateName"
                value={header}
                onChange={handleHeaderChange}
                placeholder="Enter Text"
                className="input-field"
              />
            </div>
          )}
          {showImage && (
            <div
              style={{ marginTop: 15 }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <div className="input-row">
                <label className="subheading" htmlFor="templateName">
                  Select Image
                </label>
              </div>
              <div
                className="input-field5"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: 0,
                }}
              >
                <label
                  htmlFor="filepic"
                  style={{
                    backgroundColor: "#EFEFEF",
                    height: 35,
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  {" "}
                  Select File
                </label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  id="filepic"
                  onChange={handleFileChange}
                />
                <p style={{ marginLeft: 8, height: 35, padding: 5 }}>
                  {fileName ? `${fileName}` : "No file chosen"}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="group">
        <div style={{ width: "100%" }}>
          <div className="input-row">
            <label className="labelT" htmlFor="templateName">
              Body
            </label>
            <div className="character-count">{bCharCount}/1024</div>
          </div>
          <div>
            <textarea
              placeholder="Enter Text Here"
              rows="5"
              name="body"
              value={body}
              onChange={handleChange}
              className="input-field2"
              required
            />
          </div>
        </div>
        <p
          style={{
            display: "flex",
            justifyContent: "start",
            fontSize: 16,
            color: "red",
          }}
        >
          {berror}
        </p>
      </div>
      <div className="group">
        <div style={{ width: "100%", marginTop: -10 }}>
          <div className="input-row">
            <div className="lab2">
              <span className="labelT1">Message Footer </span>(Optional)
            </div>
            <div style={{ marginTop: 20 }} className="character-count">
              {mschrCount}/60
            </div>
          </div>
          <input
            style={{ marginTop: 7 }}
            type="text"
            id="templateName"
            value={footermessage}
            onChange={handleFooterChange}
            placeholder="Sent via AWS CRM"
            className="input-field2"
          />
        </div>
      </div>
      <div className="group">
        {/* <div className="input-row">
          <label className="labelT lab2" htmlFor="language">
            Button Type
          </label>
          <select
            id="language"
            value={selectedBtnType}
            onChange={handleBtnTypeChange}
            className="language-dropdown1"
          >
            <option value="" disabled hidden>
              Select Button Type (Optional)
            </option>
            <option value="QUICK_REPLY">Quick Reply</option>
            <option value="URL">URL</option>
          </select>
        </div> */}

        <div className="input-row" style={{ marginTop: 25 }}>
          <label className="labelT1" htmlFor="language">
            Numbers Of Button(s)
          </label>
          {
            <div className="radio-container">
              <input
                type="radio"
                value="1"
                checked={selectedOption2 === "1"}
                onChange={handleOption2Change}
                style={{
                  height: "19px",
                  width: "19px",
                  marginTop: "3px",
                  marginRight: "2px",
                }}
              />
              <label className="radio-label"> One </label>

              <input
                type="radio"
                value="2"
                checked={selectedOption2 === "2"}
                onChange={handleOption2Change}
                style={{
                  height: "19px",
                  width: "19px",
                  marginTop: "3px",
                  marginRight: "2px",
                }}
              />
              <label className="radio-label"> Two </label>

              <input
                type="radio"
                value="3"
                checked={selectedOption2 === "3"}
                onChange={handleOption2Change}
                style={{
                  height: "19px",
                  width: "19px",
                  marginTop: "3px",
                  marginRight: "2px",
                }}
              />
              <label className="radio-label"> Three </label>
            </div>
          }
        </div>

        {showbtnlbl && (
          <div style={{ width: "100%", marginTop: 10 }}>
            {Array.from({ length: count }, (_, index) => (
              <div className="input-row2" key={index}>
                <div className="bturlDiv">
                  
                  <label
                    
                    className="subheading2"
                    htmlFor={`input${index}`}
                  >
                    {!switchStates[index] ? "Quick Reply" : "URL"} Text{" "}
                    {index + 1}
                  </label>
                  <br />
                  <input
                    type="text"
                    id={`input${index}`}
                    value={inputValues[index] || ""} // Controlled input
                    onChange={(e) =>
                      handleTextInputChange(index, e.target.value)
                    }
                    placeholder={`Text ${index + 1}`}
                    className="input-field"
                  />
                </div>
                <label className="switch">
                    <input
                      type="checkbox"
                      checked={switchStates[index]} // Dynamic switch state
                      onChange={(e) =>
                        handleSwitchChange(index, e.target.checked)
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                <div
                  style={{ display: !switchStates[index] ? "none" : "block" }}
                  className="bturlDiv"
                >
                  <label className="subheading2" htmlFor={`URL${index}`}>
                    URL Link {index + 1}
                  </label>
                  <br />
                  <input
                    type="text"
                    id={`URL${index}`}
                    value={urls[index] || ""} // Controlled input
                    onChange={(e) =>
                      handleUrlInputChange(index, e.target.value)
                    }
                    placeholder={`http://`}
                    className="input-field"
                  />
                </div>
              </div>
            ))}
            <p
              style={{
                display: "flex",
                justifyContent: "end",
                fontSize: 16,
                color: "red",
                marginRight: 290,
              }}
            >
              {Uerror}
            </p>
          </div>
        )}
      </div>
      <div className="upload-button-container">
        <button className="upload-button" onClick={HandleSave}>
          Next
        </button>
      </div>
    </div>
  );
}

export default App;
