// slice.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchBotData, fetchGeneralData, fetchUsers } from './reduxmodels/BotData'; // Adjust the import path

const initialState = {
  usersdetail: [],
  Messagetemplatedata: [],
  count: '',
  broadcastdata: [{ id: 1, name: 'New Broad Cast' }],
  users: '',
  isTemplateEditable: false,
  getTemplate: null,
  getreactInstance: null,
  botName: null,
  urlButtonErrors: {},
  items: [], // For general data
  botData: null, // For bot-specific data
  admins: [], // Added this line for admins state
};

const counterSlice = createSlice({
  name: 'Reducer',
  initialState,
  reducers: {
    Templatedata: (state, action) => {
      state.Messagetemplatedata = action.payload;
    },
    Rightscreen: (state, action) => {
      state.count = action.payload;
    },
    Broadcastdata: (state, action) => {
      state.broadcastdata = action.payload;
    },
    Users: (state, action) => {
      state.users = action.payload;
    },
    IsEditAble: (state, action) => {
      state.isTemplateEditable = action.payload;
    },
    IsTemplate: (state, action) => {
      state.getTemplate = action.payload;
    },
    ReactFlowInstance: (state, action) => {
      state.getreactInstance = action.payload;
    },
    SetBotName: (state, action) => {
      state.botName = action.payload;
    },
    SetUrlButtonError: (state, action) => {
      state.urlButtonErrors = {
        ...state.urlButtonErrors,
        [action.payload.nodeId]: action.payload.error,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBotData.fulfilled, (state, action) => {
        state.botData = action.payload;
      })
      .addCase(fetchBotData.rejected, (state, action) => {
        console.error('Fetch bot data failed:', action.payload.error);
      })
      .addCase(fetchGeneralData.fulfilled, (state, action) => {
        state.items = action.payload;
      })
      .addCase(fetchGeneralData.rejected, (state, action) => {
        console.error('Fetch general data failed:', action.payload.error);
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.admins = action.payload; // Update state with fetched data
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        console.error('Fetch Admins data failed:', action.payload.error);
      });
  },
});

export const {
  Users,
  Templatedata,
  Rightscreen,
  Broadcastdata,
  IsTemplate,
  ReactFlowInstance,
  SetUrlButtonError,
  SetBotName
} = counterSlice.actions;

export default counterSlice.reducer;
