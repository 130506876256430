import React, { useEffect, useState } from "react";
import loginStyle from "./Login.module.css";
import { BsPerson, BsLock } from "react-icons/bs";
import Logo from "../components/images/Logo (1).png";
import ilustration from "../components/images/Ecco illuist.png"
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { ApiEndPoint, decodeToken } from "../components/utils/utlis.js";
import Footer from "../Term And Services/footer.jsx";
import Footer1 from "../Term And Services/footer1.jsx";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const jwtToken = sessionStorage.getItem("jwtToken");
  const { userId } = decodeToken(jwtToken);

  useEffect(() => {
    const { isExpired } = decodeToken(jwtToken);
    console.log("IS EXPIRED:", isExpired);
    if (!isExpired) {
      if (isExpired) {
        navigate("/");
      } else {
       
          navigate("/dashboard", {
            replace: true,
          }); 
      }
    }
  }, [jwtToken, navigate]);

  const handleNavigation = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${ApiEndPoint}/login`, {
        email,
        password,
      });
      console.log("USER Data:", response.data);
      if (response.status === 200) {
        console.log("USER success:", response);
        const { token } = response.data;
        login(token, response.data.userId, response.data.username,response.data.role);
       
          navigate("/dashboard", {
            replace: true,
          });
      } else {
        setErrorMessage(response.data.error);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Invalid credentials. Please try again later.");
    }
  };

  return (
    <Container fluid style={{ marginTop: 20, paddingLeft: 50, paddingRight: 50}}>
      <Row className="text-center" style={{
        borderRadius: '10px', // Rounded corners for the card
        boxShadow: '0 0 14px rgba(0, 0, 0, 0.2)', // Shadow for the card effect
      }}>
        <Col sm={12} md={6} lg={6} className="d-flex align-items-center justify-content-center" style={{
          backgroundColor: "#f3ebe9"
        }}>
          <Row className="text-center" style={{ height: '100%' }}>
  <Col
    sm={12}
    md={12}
    lg={12}
    className="d-flex align-items-center justify-content-center"
    style={{
      height: '80%', // Equal height for both columns
    }}
  >
    <div
      className="mt-3"
      style={{
        height: '100%', // Occupy the full height of the column
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={ilustration} // Adjust the image path as necessary
        alt="Login"
        style={{
          height: '100%', // Ensures the image covers the entire height of the parent div
          width: '100%', // Ensures the image covers the entire width of the parent div
          objectFit: 'contain', // Ensures the image maintains its aspect ratio
        }}
      />
    </div>
  </Col>

  <Col
    sm={12}
    md={12}
    lg={12}
    className="d-flex align-items-center justify-content-center"
    style={{
      height: '20%', // Same height for the second column
    }}
  >
    <div
      className="mt-3"
      style={{
        height: '100%', // Occupy the full height of the column
        width: '45vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize:24,
        color:"grey",
        letterSpacing:4
      }}
    >
      Ecco Bot
    </div>
  </Col>
</Row>

        </Col>
        <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-center px-5">
          {/* Add Logo Image Here */}
          <div className="d-flex justify-content-center mb-3">
            <img
              src={Logo} // Path to your logo image
              alt="Logo"
              style={{ width: '100px', height: '100px', objectFit: 'contain', marginBottom: '20px' }}
            />
          </div>
          <div className="text-center mb-3"  style={{
            letterSpacing: 1,
            fontSize:24,
            
            color:"grey"
          }}>Sign In</div>
          <Form onSubmit={handleNavigation}>
            <Form.Group className="mb-3 text-start">
              <Form.Label style={{
                 color:"grey",
              }}>Email</Form.Label>
              <div className="input-group">
                <span className="input-group-text">
                  <BsPerson color="#25D366" />
                </span>
                <Form.Control
                  aria-invalid="false"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  type="text"
                  className="form-control-lg"
                  onFocus={(e)=>{
                    e.target.style.borderColor="#25D366";
                    e.target.style.boxShadow="none";
                    e.target.style.fill="#25D366"
                   
                  }}
                  onBlur={(e)=>{
                  e.target.style.borderColor = "#d3d3d3"
                    e.target.style.boxShadow="none";
                    e.target.style.fill="#25D366"
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 text-start">
              <Form.Label style={{
                 color:"grey",
              }}>Password</Form.Label>
              <div className="input-group" >
                <span className="input-group-text" >
                  <BsLock  color="#25D366"/>
                </span>
                <Form.Control
                  aria-invalid="false"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  placeholder="Enter Password"
                  type="password"
                  className="form-control-lg"
                  onFocus={(e)=>{
                    e.target.style.borderColor="#25D366";
                    e.target.style.boxShadow="none";
                     e.target.style.fill="#25D366"
                  }}
                  onBlur={(e)=>{
                    e.target.style.borderColor = "#d3d3d3"
                    e.target.style.boxShadow="none";
                    e.target.style.fill="#25D366"
                  }}
                />
              </div>
            </Form.Group>
            
            <Button
              className="btn btn-primary   mt-3 border border-none"
              type="submit"
              style={{backgroundColor:"#25D366"}}
              onMouseEnter={(e)=>{
                e.target.style.backgroundColor="#FF7640"
              }}
              onMouseLeave={(e)=>{
                e.target.style.backgroundColor="#25D366"
              }}
            >
              Sign in
            </Button>
          </Form>
          {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}
          <div className="mt-3 text-center">
            <Link className="text-muted font-size-13" to="/ForgetPassword">
              Forgot password?
            </Link>
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <Footer />
            <div style={{ marginLeft: 20 }}>
              <Footer1 />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
  
  
};
export default Login;
