import React, { useEffect, useState } from "react";
import "../pages/chat/Chat.module.css";
import UserListWidget from "../pages/chat/widgets/UserListWidget";
import axios from "axios";
import User_profile from "./User_profile";
import { BsSortDown, BsSortUp, } from "react-icons/bs";
import { useRef } from "react";
import { ApiEndPoint } from "../pages/components/utils/utlis";
import { GrContactInfo } from "react-icons/gr";
import { FaFilter } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import LeftChatTextWidget from "../pages/chat/LeftChatTextWidget";
const ViewAdmin = () => {
  //const Users = useSelector((state) => state.Data.usersdetail);
  const [Users, setUsers] = useState([]);
  //const { token, userId, login } = useAuth();
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState(false);
  const [isFilterSheetOpen, setIsFilterSheetOpen] = useState(false);
  const filterRef = useRef(null);


  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, [Users]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/admins`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }

  };
  const [showFilters, setShowFilters] = useState(false);

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const handleFilterOptionClick = (option) => {
    setSelectedOption(option);
    setIsFilterSheetOpen(false); // Close the filter sheet after selection
  };
  const toggleFilterSheet = () => {
    setIsFilterSheetOpen(!isFilterSheetOpen);
  };

  const handleSearchChange = (e) => {
    setIsFilterSheetOpen(false);
    setSearchQuery(e.target.value);
  };
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterSheetOpen(false);
        setShowFilters(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);

    };
  }, []);

  // Filter users based on search query
  const filteredUsers = Users
    .filter((user) => {
      // Check if user.name is not null and not undefined
      if (user.name && typeof user.name === "string") {
        const lowerCaseName = user.name.toLowerCase();
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        return lowerCaseName.includes(lowerCaseSearchQuery);
      }
      return false; // Filter out user with null or non-string name
    })


    .sort((a, b) => {
      if (selectedOption === "A-Z") {
        return a.name.localeCompare(b.name); // Sort A-Z
      }
      if (selectedOption === "Z-A") {
        return b.name.localeCompare(a.name); // Sort Z-A
      }
      if (selectedOption === "Old to New") {
        return new Date(a.createdAt) - new Date(b.createdAt); // Sort Old to New
      }
      if (selectedOption === "New to Old") {
        return new Date(b.createdAt) - new Date(a.createdAt); // Sort New to Old
      }
      return 0;
    });
  const userListHeight = `calc(98vh - 104px)`;
  return (
    // main dive
    <div
      className="container-fluid p-0 m-0 d-flex"
      style={{
        height: `calc(93vh - 69px)`,
        width: "100%",
        gap: "10px", // Add space between columns

      }}
    >
      {/* Column 1 */}
      <div
        className="p-2 m-0 bg-white"
        style={{
          borderRadius: "12px", // Rounded corners
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for card effect
          overflow: "hidden", // Prevents overflow from rounded corners
          flex: "0 0 20%", // Fix the width of the first div to 20%
          minWidth: "200px", // Minimum width to prevent shrinking too much
          maxWidth: "300px", // Maximum width to prevent it from expanding too much
          position: "relative", // Ensures the filter options position correctly relative to this column
          height:"86vh",
          
        }}
      >
        {/* Search Container */}
        <div className="d-flex flex-column position-relative">
          <div className="d-flex justify-content-between align-items-center">
            <input
              type="search"
              placeholder="Search User"
              className="form-control"
              onChange={handleSearchChange}
              value={searchQuery}
              style={{
                boxShadow: "none",
              }}
              onFocus={(e) => {
                e.target.style.borderColor = "#FF7640"; // Orange border on focus
              }}
              onBlur={(e) => {
                e.target.style.borderColor = "#24D366"; // Green border on unfocus
              }}
            />

            {/* Filter Icon */}

            {/* Filter Icon */}
            <div className="filter-container position-relative" ref={filterRef}>
              <div
                className="text-gray cursor-pointer ms-2"
                style={{
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid #24D366',
                  borderRadius: '50%',
                }}
                onClick={toggleFilterSheet}
              >
                {/* Custom Icon to Toggle the Filter Sheet */}
                <div><FaFilter color="#24D366" /></div>
              </div>
            </div>
          </div>

          {/* Filter Sheet */}
          {isFilterSheetOpen && (
            <div
              className="position-absolute bg-white shadow-lg p-3"
              style={{
                top: '50px', // Position it below the search bar
                right: '0',  // Align to the right side of the container
                width: '100%',
                borderRadius: '12px',
                zIndex: '1000',
              }}
            >
              <div
                className="filter-option cursor-pointer p-2"
                onClick={() => handleFilterOptionClick("A-Z")}
              >
                By Name: A-Z
              </div>
              <div
                className="filter-option cursor-pointer p-2"
                onClick={() => handleFilterOptionClick("Z-A")}
              >
                By Name: Z-A
              </div>
              <div
          className="filter-option cursor-pointer p-2"
          onClick={() => handleFilterOptionClick("Old to New")}
        >
          By Date: Oldest to Newest
        </div>
        <div
          className="filter-option cursor-pointer p-2"
          onClick={() => handleFilterOptionClick("New to Old")}
        >
          By Date: Newest to Oldest
        </div>
            </div>
          )}
        </div>

        {/* User List */}
        <div
          style={{
            fontSize: "1.1rem",
            maxHeight: userListHeight,
            overflowY: "auto",
            marginTop: "5px",
          }}
        >
          {/* User List Content */}
          {filteredUsers.map((val) => (
            <UserListWidget
              key={val.id}
              user={val}
              onClick={() => handleUserClick(val)}
              picture={val.profilePicture}
              color={val.color_code}
            />
          ))}
        </div>
      </div>

      {/* Column 2 */}
      {/* <div className="text-center d-flex flex-column justify-content-center h-100 w-100"
        style={{
          borderRadius: "10px", // Rounded corners
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for card effect
          overflow: "hidden", // Prevents overflow from rounded corners
          backgroundColor: "#ffffff", // Background color of the card

        }}
      >
        {selectedUser ? (<User_profile className="Theme3" user={selectedUser} />) : (
          <div className="gap-3 text-center d-flex flex-column justify-content-center align-items-center h-100 w-100">
            <GrContactInfo className="fs-1" />
            <div>
              <h4>Admin Detail</h4>
              <p>Click any Admin to view details</p>
            </div>
          </div>
         )
        }
      </div> */}



      <div
        className="text-center d-flex flex-column justify-content-center h-82 w-100"
        style={{  
          borderRadius: "10px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          backgroundColor: "#ffffff",
          padding: isDesktop ? "2rem" : isTablet ? "1.5rem" : "1rem",
          maxWidth: isDesktop ? "90%" : isTablet ? "95%" : "100%",
          height:"86vh",
        }}
      >
        {selectedUser ? (
          <User_profile className="Theme3" user={selectedUser} />
        ) : (
          <div
            className="gap-3 text-center d-flex flex-column justify-content-center align-items-center h-100 w-100"
            style={{
              padding: isDesktop ? "2rem" : isTablet ? "1rem" : "0.5rem",
            }}
          >
            <GrContactInfo
              className={isDesktop ? "fs-1" : isTablet ? "fs-2" : "fs-3"}
            />
            <div>
              <h4
                style={{
                  fontSize: isDesktop ? "1.75rem" : isTablet ? "1.5rem" : "1.25rem",
                }}
              >
                Admin Detail
              </h4>
              <p
                style={{
                  fontSize: isDesktop ? "1rem" : isTablet ? "0.9rem" : "0.8rem",
                }}
              >
                Click any Admin to view details
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ViewAdmin;
