import React, { useState } from "react";
import loginStyle from "./Login.module.css";
import { BsPerson, BsLock } from "react-icons/bs";
import Logo from "../components/images/Logo (1).png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TbPasswordFingerprint } from "react-icons/tb";
import { ApiEndPoint } from "../components/utils/utlis";
import { Col, Container, Row } from "react-bootstrap";
import ilustration from "../components/images/Ecco illuist.png"

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [semail, setSEmail] = useState(true);
  const [scode, setSCode] = useState(false);
  const [spass, setSpass] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false); // This tracks the password validation error

  const handlecode = async (event) => {
    event.preventDefault();
    setLoadingCode(true);
    try {
      const response = await axios.get(`${ApiEndPoint}/checkCode/${code}`);
      if (response.status === 200) {
        setSEmail(false);
        setSCode(false);
        setSpass(true);
        setErrorMessage("");
      } else {
        setErrorMessage("4 Digit verification code is incorrect");
      }
    } catch {
      setErrorMessage("4 Digit verification code is incorrect. Please try again.");
    } finally {
      setLoadingCode(false);
    }
  };

  const handleemail = async (event) => {
    event.preventDefault();
    setLoadingEmail(true);
    try {
      const response = await axios.get(`${ApiEndPoint}/admin/${email}`);
      if (response.status === 200) {
        setSEmail(false);
        setSCode(true);
        setSpass(false);
        setErrorMessage("");
      } else {
        setErrorMessage("Email address does not exist. Please try again.");
      }
    } catch {
      setErrorMessage("Email address does not exist. Please try again.");
    } finally {
      setLoadingEmail(false);
    }
  };

  const handleNavigation = async (event) => {
    event.preventDefault();
    setLoadingPassword(true);
    try {
      const response = await axios.put(`${ApiEndPoint}/passUpdate`, {
        email,
        password,
      });
      if (response.status === 200) {
        navigate("/", {
          replace: true,
        });
      } else {
        setErrorMessage(response.data.error);
      }
    } catch {
      setErrorMessage("User not found. Please try again.");
    } finally {
      setLoadingPassword(false);
    }
  };

  

  return(
    <Container fluid style={{ marginTop: 20, paddingLeft: 50, paddingRight: 50}}>

<Row className="text-center" style={{
        borderRadius: '10px', // Rounded corners for the card
        boxShadow: '0 0 14px rgba(0, 0, 0, 0.2)', // Shadow for the card effect
      }}>
        <Col sm={12} md={6} lg={6} className="d-flex align-items-center justify-content-center" style={{
          backgroundColor: "#f3ebe9"
        }}>
          <Row className="text-center" style={{ height: '100%' }}>
  <Col
    sm={12}
    md={12}
    lg={12}
    className="d-flex align-items-center justify-content-center"
    style={{
      height: '80%', // Equal height for both columns
    }}
  >
    <div
      className="mt-3"
      style={{
        height: '100%', // Occupy the full height of the column
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={ilustration} // Adjust the image path as necessary
        alt="Login"
        style={{
          height: '100%', // Ensures the image covers the entire height of the parent div
          width: '100%', // Ensures the image covers the entire width of the parent div
          objectFit: 'contain', // Ensures the image maintains its aspect ratio
        }}
      />
    </div>
  </Col>

  <Col
    sm={12}
    md={12}
    lg={12}
    className="d-flex align-items-center justify-content-center"
    style={{
      height: '20%', // Same height for the second column
    }}
  >
    <div
      className="mt-3"
      style={{
        height: '100%', // Occupy the full height of the column
        width: '45vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize:24,
        color:"grey",
        letterSpacing:4
      }}
    >
      Ecco Bot
    </div>
  </Col>
</Row>

        </Col>
        <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-center px-5">
        {/* Add Logo Image Here */}
        <div className="d-flex justify-content-center mb-3">
            <img
              src={Logo} // Path to your logo image
              alt="Logo"
              style={{ width: '100px', height: '100px', objectFit: 'contain', marginBottom: '20px' }}
            />
          </div>
          <div className="text-center mb-3"  style={{
            letterSpacing: 1,
            fontSize:24,
            
            color:"grey"
          }}>Forget Password</div>
          <div className="text-center">
    
    <form>
      {semail && (
        <>
          <div className="mb-3 text-start">
            <label
            style={{
              color:"grey",
           }}
            >Email</label>
            <div className="input-group mb-3">
            <span className="input-group-text">
                  <BsPerson color="#25D366" />
                </span>
              <input
                className={`form-control`}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email"
                required
                onFocus={(e)=>{
                  e.target.style.borderColor="#25D366";
                  e.target.style.boxShadow="none";
                  e.target.style.fill="#25D366"
                 
                }}
                onBlur={(e)=>{
                e.target.style.borderColor = "#d3d3d3"
                  e.target.style.boxShadow="none";
                  e.target.style.fill="#25D366"
                }}
              />
            </div>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </div>
          <button
           className="btn btn-primary   mt-3 border border-none"
           type="submit"
           style={{backgroundColor:"#25D366"}}
           onMouseEnter={(e)=>{
             e.target.style.backgroundColor="#FF7640"
           }}
           onMouseLeave={(e)=>{
             e.target.style.backgroundColor="#25D366"
           }}
            onClick={handleemail}
            disabled={loadingEmail}
          >
            {loadingEmail ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Search"
            )}
          </button>
        </>
      )}

      {scode && (
        <>
          <div className="mb-3 text-start">
            <label
            
            style={{
              color:"grey",
           }}>
              Verification Code
            </label>
            <div className="input-group mb-3">
              <span className={`input-group-text`}>
                <TbPasswordFingerprint color="#25D366"/>
              </span>
              <input
                className={`form-control`}
                type="number"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter Code"
                required
                onFocus={(e)=>{
                  e.target.style.borderColor="#25D366";
                  e.target.style.boxShadow="none";
                  e.target.style.fill="#25D366"
                 
                }}
                onBlur={(e)=>{
                e.target.style.borderColor = "#d3d3d3"
                  e.target.style.boxShadow="none";
                  e.target.style.fill="#25D366"
                }}
              />
            </div>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </div>
          <button
            className="btn btn-primary   mt-3 border border-none"
            type="submit"
            style={{backgroundColor:"#25D366"}}
            onMouseEnter={(e)=>{
              e.target.style.backgroundColor="#FF7640"
            }}
            onMouseLeave={(e)=>{
              e.target.style.backgroundColor="#25D366"
            }}
            onClick={handlecode}
            disabled={loadingCode}
          >
            {loadingCode ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Verify Code"
            )}
          </button>
        </>
      )}

      {spass && (
        <>
          <div className="mb-3 text-start">
            <label className={loginStyle["form-label"]}>Password</label>
            <div className="input-group">
              <span className={`input-group-text`}>
                <BsLock color="#25D366"/>
              </span>
              <input
                className={`form-control`}
                type="password"
                value={password}
                
                onChange={(e) => {
                  const input = e.target.value;
                  setPassword(input);
                  // Validate password length: must be between 6 and 16 characters
                  setPasswordError(input.length < 6 || input.length > 16);
                }}
                placeholder="Enter Password"
                required
                onFocus={(e)=>{
                  e.target.style.borderColor="#25D366";
                  e.target.style.boxShadow="none";
                  e.target.style.fill="#25D366"
                 
                }}
                onBlur={(e)=>{
                e.target.style.borderColor = "#d3d3d3"
                  e.target.style.boxShadow="none";
                  e.target.style.fill="#25D366"
                }}
              />
            </div>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            {passwordError && (
        <p className="text-danger">
          Password must be between 6 and 16 characters long.
        </p>
      )}
          </div>

          <button
            className={`btn btn-primary mt-3 border border-none`}
            type="button"
            onClick={handleNavigation}
            disabled={passwordError || loadingPassword} 

            style={{backgroundColor:"#25D366"}}
            onMouseEnter={(e)=>{
              e.target.style.backgroundColor="#FF7640"
            }}
            onMouseLeave={(e)=>{
              e.target.style.backgroundColor="#25D366"
            }}
            required
          >
            {loadingPassword ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Change Password"
            )}
          </button>
        </>
      )}
    </form>

</div>

        </Col>


 </Row>


    </Container>
  );
 
};

export default ForgetPassword;
