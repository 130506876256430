import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ImCross } from "react-icons/im";
import { GrContactInfo } from "react-icons/gr";
import axios from "axios";
import { FaFilter } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import { ApiEndPoint } from "../pages/components/utils/utlis";
import ContactFormModal, { UpdateForm } from "../pages/ContactForm/ContactForm";
import UserListWidget from "../pages/chat/widgets/UserListWidget";
import style from "../Style/CSS.module.css";
import "../Style/CSS.css";

const Contacts = () => {
  const [remainingHeight, setRemainingHeight] = useState(0);
  const userListRef = useRef(null);
  const [isFilterSheetOpen, setIsFilterSheetOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const filterRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterSheetOpen(false);
        setShowFilters(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);

    };
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${ApiEndPoint}/getAllUsers`);
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const handleUserClick = (userData) => {
    setSelectedUser(userData);
  };

  const handleCrossClick = () => {
    setSelectedUser(null);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleFilterSheet = () => {
    setIsFilterSheetOpen(!isFilterSheetOpen);
  };

  const handleFilterOptionClick = (option) => {
    setSelectedOption(option);
    setIsFilterSheetOpen(false);
  };

  const filteredUsers = users
    .filter((user) => user.name?.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => {
      if (selectedOption === "A-Z") return a.name.localeCompare(b.name);
      if (selectedOption === "Z-A") return b.name.localeCompare(a.name);
      if (selectedOption === "Old to New") return new Date(a.createdAt) - new Date(b.createdAt);
      if (selectedOption === "New to Old") return new Date(b.createdAt) - new Date(a.createdAt);
      return 0;
    });

  return (
    <div
      className="p-0 m-0"
      style={{
        height: "86vh",
        width: "100%",
        backgroundColor: "#ffffff",
        display: "flex",
        gap: "10px",
        borderRadius: isDesktop ? "0" : "12px",
        marginLeft: isDesktop ? "10px" : "0"
      }}
    >
      {/* First Column */}
      <div
        className="col-lg-3 col-md-3 col-sm-12 border-end p-2 d-flex flex-column h-100 position-relative"
        style={{
          width: isDesktop ? '21%' : isTablet ? '30%' : '100%',
          borderRadius: '12px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div className="d-flex justify-content-between align-items-center mb-2">
          <input
            type="search"
            placeholder="Search Client"
            className="form-control"
            onChange={handleSearchChange}
            value={searchQuery}
            style={{ boxShadow: "none", borderColor: "#24D366" }}
            onFocus={(e) => { e.target.style.borderColor = "#FF7640"; }}
            onBlur={(e) => { e.target.style.borderColor = "#24D366"; }}
          />
          {/* Filter Icon */}
          <div className="filter-container position-relative" ref={filterRef}>
            <div
              className="text-gray cursor-pointer ms-2"
              style={{
                width: '30px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #24D366',
                borderRadius: '50%',
              }}
              onClick={toggleFilterSheet}
            >
              <FaFilter color="#24D366" />
            </div>
          </div>
        </div>
        <div
          className="flex-grow-1"
          style={{
            overflow: isDesktop ? 'auto' : isTablet ? 'hidden' : 'auto', // Hide scrollbar on tablet
          }}
        >
          {filteredUsers.map((val) => (
            <UserListWidget
              key={val.id}
              user={val}
              onClick={() => handleUserClick(val)}
              picture={val.profilepic}
              color={val.color_code}
            />
          ))}
        </div>
        {isFilterSheetOpen && (
          <div
            className="position-absolute bg-white shadow-lg p-3"
            style={{
              top: '50px',
              right: '0',
              width: '100%',
              borderRadius: '12px',
              zIndex: '1000',
            }}
          >
            <div className="filter-option cursor-pointer p-2" onClick={() => handleFilterOptionClick("A-Z")}>By Name: A-Z</div>
            <div className="filter-option cursor-pointer p-2" onClick={() => handleFilterOptionClick("Z-A")}>By Name: Z-A</div>
            <div
          className="filter-option cursor-pointer p-2"
          onClick={() => handleFilterOptionClick("Old to New")}
        >
          By Date: Oldest to Newest
        </div>
        <div
          className="filter-option cursor-pointer p-2"
          onClick={() => handleFilterOptionClick("New to Old")}
        >
          By Date: Newest to Oldest
        </div>
          </div>
        )}
      </div>

      {/* Second Column */}
      <div
        className={`text-center d-flex flex-column justify-content-center h-82 w-100`}
        style={{
          borderRadius: "10px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          backgroundColor: "#ffffff",
          padding: isDesktop ? "2rem" : isTablet ? "1.5rem" : "1rem",
          maxWidth: isDesktop ? "90%" : isTablet ? "95%" : "100%",
          height:"86vh",
        }}
      >
        {selectedUser === null ? (
          <div className="gap-3 text-center d-flex flex-column justify-content-center align-items-center h-100 w-100">
          <GrContactInfo className="fs-1" />
          <div>
            <h4>Contact Detail</h4>
            <p>Click any contact to view details</p>
          </div>
        </div>
        ) : (
          <div
            className="text-center d-flex flex-column justify-content-center align-items-center"
            style={{
              padding: isDesktop ? '2rem' : isTablet ? '1.5rem' : '1rem',
              height: isDesktop ? '100%' : isTablet ? '75%' : '100%',
              width: isDesktop ? '100%' : isTablet ? '90%' : '100%',
            }}
          >
            <UpdateForm user={selectedUser} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Contacts;
