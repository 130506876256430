import React from 'react';
import style from '../Style/CSS.module.css'; // Import your CSS module file

const UserItem = ({ user, handleUserClick }) => {
  return (
    <div  className={`d-flex justify-content-start mt-1  mb-3 ${style.hover}`} style={{marginLeft:"3px",marginRight:"3px"}}onClick={() => handleUserClick(user)}>
      <div >
      <div
            className="rounded-circle d-flex justify-content-center mt-1 "
            style={{
              backgroundImage: user.profilePic
                ? `url(data:image/jpeg;base64,${user.profilePic})`
                : "none",
              backgroundColor: user.profilePic
                ? "transparent"
                : user.color_code, // Set background color if profilePic is null
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "40px",
              height: "40px",
              margin: "auto",
              border: `1px solid #FFF`,
              boxShadow: `none`,
            }}
          >
            <div style={{ margin: "auto", textAlign: "center", color: "#FFF" }}>
              {user && user.profilePic
                ? ""
                : user.name && user.name.length > 0 && user.name.includes(" ")
                ? user.name[0] + user.name.split(" ")[1][0]
                : user.name[0][0]}
            </div>
           

          </div>
         
      </div>
      <div className='ms-2 d-flex justify-content-center mt-1'><p className='fs-5'>{user.name}</p></div>
      
    </div>
  );
};

export default UserItem;
