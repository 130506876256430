import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";

import "bootstrap/dist/css/bootstrap.min.css";
import { RiFileExcel2Line } from "react-icons/ri";
import { IoPersonAddOutline } from "react-icons/io5";

import style from "../Style/CSS.module.css";
import Broadcast from "./Broadcast";
import { Rightscreen } from "../Redux Toolkit/slice";
import ChatStyle from "../pages/chat/Chat.module.css";
import { HiOutlineUserGroup, HiOutlineUserline } from "react-icons/hi";
import axios from "axios";
import { ApiEndPoint } from "../pages/components/utils/utlis";
import ExcelUploadPopup from "../pages/ContactForm/dropzonComponent";
import ContactFormModal from "../pages/ContactForm/ContactForm";
import { AiOutlineUsergroupAdd } from "react-icons/ai";

const Sendmessage = () => {
  var dispatch = useDispatch();
  const dt = useSelector((state) => state.Data.getTemplate);
  const [Broadcastdata, setBroadcastData] = useState([]);
  const [selectedBroadcasts, setSelectedBroadcasts] = useState([]);

  const [messageTemplates, setMessageTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [ShowBroadcastPopup, setShowBroadcastPopup] = useState();
  const [showModal, setShowModal] = useState(false); // State for the main modal
  const [showContactModal, setshowContactModal] = useState(false);

  useEffect(() => {
    fetchBroadcasts();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const closeContactModal = () => {
    setshowContactModal(false);
  };

  const openContactModal = () => {
    setshowContactModal(true);
  };

  const handleBroadcastIconClick = () => {
    setShowBroadcastPopup(true); // Set state to true to show File popup
  };

  const fetchBroadcasts = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/broadcasts`);

      console.log("Broadcasts:", response.data);
      setBroadcastData(response.data.broadcasts);
    } catch (error) {
      console.error("Error fetching broadcasts:", error);
    }
  };
  useEffect(() => {
    fetchTemplates();
    handleTemplateChange(dt);
  }, []);
  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getTemplate`);
      setMessageTemplates(response.data.data);
      console.log("Template Messages:", response.data);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };
  const [users, setUsers] = useState([]);
  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getAllUsers`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const handleSendMessage = async () => {
    if (!selectedTemplate) {
      console.error(
        "Please select a template and at least one user to send the message."
      );
      return;
    }

    try {
      if (contactType === "broadcast") {
        console.log("Selected Template:", selectedTemplate);
        const response = await axios.post(`${ApiEndPoint}/send-messages`, {
          templateName: selectedTemplate.name,
          languageCode: selectedTemplate.language,
          broadcasts: selectedBroadcasts,
        });
      }
      if (contactType === "selectContact") {
        const response = await axios.post(`${ApiEndPoint}/send-messages`, {
          templateName: selectedTemplate.name,
          languageCode: selectedTemplate.language,
          listOfNumbers: selectedUsers.map((user) => ({
            phoneNumber: user.phone,
          })),
        });
        console.log("Message sent successfully:", response.data);
      }

      // Optionally, you can update UI or show a success message here
    } catch (error) {
      console.error("Error sending message:", error);
      // Optionally, you can update UI or show an error message here
    }
  };

  const handleTemplateChange = (templateId) => {
    const selectedTemplate = messageTemplates.find(
      (template) => template.id === templateId
    );
    setSelectedTemplate(selectedTemplate);
  };
  const handleCheckbox = (user, isChecked) => {
    const updatedSelectedUsers = isChecked
      ? [...selectedUsers, { id: user.id, phone: user.phone }]
      : selectedUsers.filter((selectedUser) => selectedUser.id !== user.id);
    setSelectedUsers(updatedSelectedUsers);
  };
  const handleBroadcast = (val, isChecked) => {
    const updatedSelectedBroadcasts = isChecked
      ? [...selectedBroadcasts, val]
      : selectedBroadcasts.filter((selected) => selected.id !== val.id);
    setSelectedBroadcasts(updatedSelectedBroadcasts);
  };

  const isSelected = (val) => {
    return selectedBroadcasts.some((item) => item.id === val.id);
  };

  const [contactType, setcontactType] = useState("");
  const SelectContactList = (e) => {
    setcontactType(e.target.value);
  };
  const handleSubmit = () => {
    handleSendMessage();
  };

  return (
    <div className={`container-fluid col-12 d-flex `}>
      {/* Title */}
      <div
        className={`Theme col-12 h-100 d-flex flex-column  gap-3  mt-1  rounded p-4`}
      >
        <p className="fw-bold fs-4">Message Template</p>

        <div>
          <div>
            <select
              className="p-2 form-select input w-100"
              name="category"
              value={selectedTemplate ? selectedTemplate.id : ""}
              onChange={(e) => handleTemplateChange(e.target.value)}
            >
              <option value="">Select message template</option>
              {messageTemplates &&
                messageTemplates.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.name} - {template.language}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div>
          <div className="gap-2 p-3 d-flex flex-column input">
            <div className=" d-flex w-25 justify-content-between">
              <div className="gap-1 d-flex">
                <input
                  type="radio"
                  id="broadcast"
                  name="contentType"
                  value="broadcast"
                  checked={contactType === "broadcast"}
                  onChange={SelectContactList}
                />
                <label className="fw-bold ">Broadcast</label>
              </div>

              <AiOutlineUsergroupAdd
                title="Add Broadcast"
                onClick={() => setShowBroadcastPopup(true)}
                className="fw-bolder fs-4"
              />
            </div>
            {contactType === "broadcast" && (
              <div>
                {Broadcastdata.map((val, index) => (
                  <div key={index} className="mt-4 d-flex">
                    <label className="d-flex align-items-center">
                      <input
                        id={`checkbox-${val.id}`}
                        type="checkbox"
                        checked={selectedBroadcasts.some(
                          (broadcast) => broadcast.id === val.id
                        )}
                        onChange={(e) => handleBroadcast(val, e.target.checked)}
                      />
                      <span className="ms-2 fs-5">{val.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}

            <hr />
            <div className=" d-flex justify-content-between w-25">
              <div className="gap-1 d-flex">
                <input
                  type="radio"
                  id="selectContact"
                  name="contentType"
                  value="selectContact"
                  checked={contactType === "selectContact"}
                  onChange={SelectContactList}
                />

                <label className="fw-bold ">Select contact</label>
              </div>

              <RiFileExcel2Line
                className="fw-bold fs-4 "
                title="Upload Excel File"
                onClick={handleShowModal}
              />
              <IoPersonAddOutline
                className="fw-bold fs-4 "
                onClick={openContactModal}
              />
            </div>
            {contactType === "selectContact" && (
              <div className={style.scro} style={{ height: "200px" }}>
                {users &&
                  users.map((user, index) => (
                    <div
                      className={
                        ChatStyle.userListWidget +
                        " col-12 col-lg-5 col-xl-3 list-group-item list-group-item-action border-0"
                      }
                      style={{ maxHeight: "100%", overflowY: "auto" }}
                    >
                      <label
                        htmlFor={`checkbox-${user.id}`}
                        className="border-0 list-group-item list-group-item-action d-flex align-items-center"
                      >
                        {/* Checkbox input */}
                        <input
                          id={`checkbox-${user.id}`}
                          type="checkbox"
                          checked={selectedUsers.some(
                            (selectedUser) => selectedUser.id === user.id
                          )}
                          onChange={(e) =>
                            handleCheckbox(user, e.target.checked)
                          }
                        />
                        {/* User profile information */}
                        <div
                          className="rounded-circle d-flex justify-content-center align-items-center"
                          style={{
                            backgroundImage: user.profilePic
                              ? `url(data:image/jpeg;base64,${user.profilePic})`
                              : "none",
                            backgroundColor: user.profilePic
                              ? "transparent"
                              : user.color_code, // Set background color if profilePic is null
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            width: "40px",
                            height: "40px",
                            margin: "auto",
                            border: `1px solid #FFF`,
                            boxShadow: `none`,
                            marginLeft: "10px",
                          }}
                        >
                          <div
                            style={{
                              margin: "auto",
                              textAlign: "center",
                              color: "#FFF",
                            }}
                          >
                            {user && user.profilePic
                              ? ""
                              : user.name &&
                                user.name.length > 0 &&
                                user.name.includes(" ")
                              ? user.name[0] + user.name.split(" ")[1][0]
                              : user.name[0][0]}
                          </div>
                        </div>
                        <div
                          className="flex-grow-1"
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          {user && user.name} {/* Display user's name */}
                        </div>
                      </label>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        {/* <button
          className={`${style.btn} w-100 mt-3 mb-3`}
          onClick={handleSubmit}
        >
          Submit
        </button> */}
        <div className="gap-5 p-1 mt-3 d-flex justify-content-around">
          <button
            onClick={() => dispatch(Rightscreen("2"))}
            className={`${style.btn} rounded`}
            style={{ border: "none" }}
          >
            Previous
          </button>
          <button
            onClick={handleSubmit}
            style={{ border: "none" }}
            className={`${style.btn}  rounded `}
          >
            Send
          </button>
        </div>
        <ExcelUploadPopup isOpen={showModal} onClose={handleCloseModal} />
        <ContactFormModal
          isOpen={showContactModal}
          onClose={closeContactModal}
        />

        {ShowBroadcastPopup && (
          <>
            <Modal
              show={true}
              onHide={() => setShowBroadcastPopup(false)}
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <Broadcast />
              </Modal.Body>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};
export default Sendmessage;
