import { Container, Row, Col } from 'react-bootstrap'; // Import Bootstrap components
import React from 'react';
import ApexChart from 'react-apexcharts';
import { useMediaQuery } from 'react-responsive'
import Chart from 'react-apexcharts'
import LineChartWithDropdown from './LineChart';
import Donut from './Donut';
import DonutChart from './Donut';
import { stroke } from 'pdf-lib';
import { colors } from '@mui/material';



const dummyData = {
  "Jan": {
    "weekly": Array.from({ length: 5 }, (_, i) => Math.floor(Math.random() * 80)),
    "monthly": Array.from({ length: 12 }, () => Math.floor(Math.random() * 180)),
    "pie": [44, 55, 13, 43, 22]
  },
  "Feb": {
    "weekly": Array.from({ length: 5 }, (_, i) => Math.floor(Math.random() * 100)),
    "monthly": Array.from({ length: 12 }, () => Math.floor(Math.random() * 180)),
    "pie": [33, 45, 15, 32, 25]
  }
};
const lineChartData = {
  categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'], // Weekly labels
  series: [
    {
      name: 'January',
      data: [65, 59, 80, 81, 56], // Example data for January
    },
    {
      name: 'February',
      data: [75, 69, 90, 91, 66], // Example data for February
    },
  ],
};

const donutChartData = {
  labels: ['Region 1', 'Region 2', 'Region 3', 'Region 4', 'Region 5'],
  series: [30, 20, 25, 15, 10], // Example proportions for regions
};
const barChartData = {
  categories: ['Jan', 'Feb', 'Mar', 'Apr'], // Monthly labels
  series: [
    {
      name: 'Sales',
      data: [120, 150, 180, 200], // Sales data for each month
    },
    {
      name: 'Revenue',
      data: [100, 140, 170, 190], // Revenue data for each month
    },
  ],
};
const chartData = {
  lineChart: {
    categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
    series: [
      { name: 'January', data: [65, 59, 80, 81, 56] },
      { name: 'February', data: [75, 69, 90, 91, 66] },
    ],
  },
  donutChart: {
    labels: ['Region 1', 'Region 2', 'Region 3', 'Region 4', 'Region 5'],
    
    series: [30, 20, 25, 15, 10],
  },
  barChart: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr','May'],
    series: [
      { name: 'Sales', data: [120, 150, 180, 200,230] },
      { name: 'Revenue', data: [100, 140, 170, 190 ,210] },
    ],
    
  },
};


const weeks = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const pieData = [30, 20, 25, 15, 10]; // Example data (replace with your actual data)

// Calculate the total sum of data to compute percentages
const total = pieData.reduce((acc, curr) => acc + curr, 0);
const percentageData = pieData.map(value => (value / total) * 100);
const labels = ['Region 1', 'Region 2', 'Region 3', 'Region 4', 'Region 5'];

const Apex = ({ 
  type = 'line', 
  series = [], 
  categories = [], 
  labels = [], 
  height = '400px', 
  width = '400px', // Set a fixed width for consistency
  Labelcolor = '#25D366'  
}) => {
  // Define dynamic colors for specific labels (regions in this case)
  const dynamicLabelColors = labels
    ? labels.map((label) =>
        label.includes('Region') ? 'white' : '#FFFFFF'
      )
    : [];

    const seriesColors = labels?.map((label) => {
      if (label.includes('Region 1')) return '#AD4BAE';
      if (label.includes('Region 2')) return '#C69899';
      if (label.includes('Region 3')) return '#00B8C8';
      if (label.includes('Region 4')) return '#BDDF5F';
      if (label.includes('Region 5')) return '#FF7C5F';
      return Labelcolor || '#FFFFFF';
    });

   

  const options = {
    chart: {
      type: type,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        
        offsetY: -35, // Adjust vertical alignment of the toolbar
      
      },
      dropShadow: {
        enabled: false,
      },
    },
    colors:seriesColors || [Labelcolor],
  
    stroke:{
      curve:'smooth', // Makes the line smooth
      width: 2,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        // Calculate percentage and round to one decimal place
        const total = opts.w.globals.seriesTotals.reduce((a, b) => a + b, 0);
        return `${((val / total) * 100).toFixed(1)}%`;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: dynamicLabelColors.length > 0 ? dynamicLabelColors : [Labelcolor],
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: '15px',
          colors: categories.map(() => 'white'), // Dynamically set white for all labels
        },
      },
    },
    yaxis: {
      min: 0,
      max: 200,
      tickAmount: 5,
      labels: {
        style: {
          colors: ['white'],
          fontSize: '15px',
        },
        formatter: value => `${value.toFixed(0)}%`,
      },
    },
    title: {
      //text: title,
      align: 'left',
      style: {
        color: '#FFFFFF',
        fontSize: '18px',
        fontWeight: 'bold',
      },
    },
    labels: labels,
    legend: {
      show: true,
      labels: {
        colors: '#FFFFFF',
      },
    },
    grid: {
      padding: {
        bottom: 20,
      },
    },
  };

  return (
    <div>
      {/* Custom CSS for toolbar icon colors */}
      <style>
        {`
          .apexcharts-toolbar svg {
            fill: white;
          }
        `}
      </style>
      <div id="chart-container" style={{ position: 'relative', height: height }}>
      <ApexChart
        options={options}
        series={series}
        type={type}
        height={height}
        width={width} // Apply fixed width
      />
      </div>
    </div>
  );
};






const DashChart = () => {
  const dailyData = dummyData['Jan'].weekly; // Example data for daily chart
  const monthlyData = dummyData['Jan'].monthly; // Example data for monthly chart
  const pieData = dummyData['Jan'].pie; // Example data for pie chart
  const isDesktop = useMediaQuery({ query: '(min-width: 1367px)' });
  const isLaptop = useMediaQuery({ query: '(min-width: 1024px) and (max-width: 1366px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1023px)' });
  

  return (
    <div className="container-fluid mt-3" >
      <div className="row g-3" style={{ maxHeight: '85vh', overflowY: 'auto' }}>

        {/* Desktop layout */}
        {isDesktop && (
          <>
            <div className="col-4 d-flex align-items-stretch mb-3">
              <div className="card text-center shadow" style={{ backgroundColor: "#6FC58F", borderRadius: "10px", width: '100%' }}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <h2 style={{ fontWeight: "bold", color: "white", textAlign: "left", marginLeft: "17px",marginBottom:"15px" }}>
                    Daily Customer Visits
                  </h2>
                  <Apex
                    type="line"
                    series={chartData.lineChart.series}
                    categories={chartData.lineChart.categories}
                    height="290px"
width="100%"

                  />
                </div>
              </div>
            </div>
            <div className="col-4 d-flex align-items-stretch mb-3">
              <div className="card text-center shadow" style={{ backgroundColor: "#A66CFF", borderRadius: "10px", width: '100%' }}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <h2 style={{ fontWeight: "bold", color: "white", textAlign: "left", marginLeft: "17px",marginBottom:"15px" }}>
                    Monthly Customer Visits
                  </h2>
                  <Apex 
  type="donut" 
  series={chartData.donutChart.series} 
  labels={chartData.donutChart.labels} 
  height="290px" 
 width="100%"
/>

                </div>
              </div>
            </div>
            <div className="col-4 d-flex align-items-stretch mb-3">
              <div className="card text-center shadow" style={{ backgroundColor: "#FF8E62", borderRadius: "10px", width: '100%' }}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <h2 style={{ fontWeight: "bold", color: "white", textAlign: "left", marginLeft: "17px",marginBottom:"5px" }}>
                    Customer Distribution
                  </h2>
                  <Apex
                    type="bar"
                    series={chartData.barChart.series}
                    categories={chartData.barChart.categories}
                    height="300px"
                 width="100%"
  
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {/* Laptop layout */}
        {isLaptop && (
          <>
            <div className="col-6 d-flex align-items-stretch">
              <div className="card text-center shadow" style={{ backgroundColor: "#24D366", borderRadius: "10px", width: '100%' }}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <h2 style={{ fontWeight: "bold", color: "white", textAlign: "left", marginLeft: "20px" }}>
                    Daily Customer Visits
                  </h2>
                  <Apex
                    type="line"
                    series={chartData.lineChart.series}
                    categories={chartData.lineChart.categories}
                    height="280px"
width="100%"
                    
                  />
                </div>
              </div>
            </div>
            <div className="col-6 d-flex align-items-stretch ">
              <div className="card text-center shadow" style={{ backgroundColor: "black", borderRadius: "10px", width: '100%'}}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <h2 style={{ fontWeight: "bold", color: "white", textAlign: "left", marginLeft: "20px" }}>
                    Monthly Customer Visits
                  </h2>
                  <Apex
                    type="donut"
                    series={chartData.donutChart.series}
                    labels={chartData.donutChart.labels}
                    height="280px"
                   width="100%"
 //A66CFF                  
                  />
                </div>
              </div>
            </div>
            <div className="col-12 d-flex align-items-stretch">
              <div className="card text-center shadow" style={{ backgroundColor: "#FF7640", borderRadius: "10px", width: '100%'}}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <h2 style={{ fontWeight: "bold", color: "white", textAlign: "left", marginLeft: "20px" }}>
                    Customer Distribution
                  </h2>
                  <Apex 
                    type="bar"
                    series={chartData.barChart.series}
                    categories={chartData.barChart.categories}
                    height="290px"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        
       {/* Tablet layout */}
{isTablet && (
  <div className="d-flex flex-column align-items-stretch gap-3">
    <div className="card text-center shadow" style={{ backgroundColor: "#24D366", borderRadius: "10px", width: '100%' }}>
      <div className="card-body d-flex flex-column justify-content-center">
        <h2 style={{ fontWeight: "bold", color: "white", textAlign: "left", marginLeft: "20px" }}>
          Daily Customer Visits
        </h2>
        <Apex
          type="line"
          series={chartData.lineChart.series}
          categories={chartData.lineChart.categories}
          height="200px" // Adjusted height for tablet
          width="100%"
        />
      </div>
    </div>
    <div className="card text-center shadow" style={{ backgroundColor: "#A66CFF", borderRadius: "10px", width: '100%' }}>
      <div className="card-body d-flex flex-column justify-content-center">
        <h2 style={{ fontWeight: "bold", color: "white", textAlign: "left", marginLeft: "20px" }}>
          Monthly Customer Visits
        </h2>
        <Apex 
  type="donut" 
  series={chartData.donutChart.series} 
  labels={chartData.donutChart.labels} 
  height="200px" 
  width="100%"
/>

      </div>
    </div>
    <div className="card text-center shadow" style={{ backgroundColor: "#FF7640", borderRadius: "10px", width: '100%' }}>
      <div className="card-body d-flex flex-column justify-content-center">
        <h2 style={{ fontWeight: "bold", color: "white", textAlign: "left", marginLeft: "20px" }}>
          Customer Distribution
        </h2>
        <Apex
          type="bar"
          series={chartData.barChart.series}
          categories={chartData.barChart.categories}
          height="200px" // Adjusted height for tablet
          width="100%"
        />
      </div>
    </div>
  </div>
)}


      </div>
    </div>
  );
};
export default DashChart;





// const DashChart = () => {
//   const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
//   const isTablet = useMediaQuery({ query: "(min-width: 768px) and (max-width: 1023px)" });
//   const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

//   const weeks = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"];
//   const dailyData = [50, 60, 70, 40, 90];
//   const monthlyData = [150, 200, 250, 180, 220];
//   const months = ["Jan", "Feb", "Mar", "Apr", "May"];
//   const customerDistribution = [44, 55, 41, 17, 15];
//   const distributionLabels = ["Region A", "Region B", "Region C", "Region D", "Region E"];

//   const axisLabelStyle = {
//     fontSize: '13px',
//     fontWeight: 'bold', // Keep text bold
//     color: '#333',      // Default color for axis labels
//   };


//   const dailyChartConfig = {
//     chart: { type: 'bar' },
//     series: [{ name: 'Daily Visits', data: dailyData }],
//     xaxis: {
//       categories: weeks,
//       labels: {
//         style: {
//           ...axisLabelStyle, // Use the existing styles
//           color: '#FF4500',  // Change color to your desired value
//         },
//       },
//       axisBorder: {
//         show: true,
//         color: '#333',
//       },
//       axisTicks: {
//         show: true,
//         color: '#333',
//       },
//     },
//     yaxis: {
//       labels: {
//         style: axisLabelStyle,
//         formatter: (value) => `${value}%`, // Convert numbers to percentages
//       },
//     },
//     colors: ['#007BFF'], // Updated color
//     offsetY: -75,
//   };

//   const monthlyChartConfig = {
//     chart: { type: 'line'},
//     series: [{ name: 'Monthly Visits', data: monthlyData }],
//     xaxis: {
//       categories: months,
//       labels: {
//         style: axisLabelStyle,
//       },
//       axisBorder: {
//         show: true,
//         color: '#333',
//       },
//       axisTicks: {
//         show: true,
//         color: '#333',
//       },
//     },
//     yaxis: {
//       labels: {
//         style: axisLabelStyle,
//         formatter: (value) => `${value}%`, // Convert numbers to percentages
//       },
//     },
//     colors: ['#28A745'], // Updated color
//   };

//   const customerDistributionConfig = {
//     chart: {
//       type: 'donut'
//     },
//     series: [44, 55, 41, 17, 15],
//     labels: ['Region A', 'Region B', 'Region C', 'Region D', 'Region E'],
//     colors: ['#415C00', '#33FF57', '#3357FF', '#F3FF33', '#F333FF'],
//   };

//   const chartSeries = [
//     {
//       name: `${selectedMonth} Weekly Data`,
//       data: weeklyData.map((value, index) => ({
//         x: `Week ${index + 1}`,
//         y: value,
//       })),
//     },
//   ];

//   const chartConfig = {
//     chart: {
//       height: 300,
//       type: chartType,
//       toolbar: {
//         show: true,
//         tools: {
//           download: true,
//           reset: true,
//         },
//       },
//     },
//     series: chartSeries,
//     xaxis: {
//       type: "category",
//       categories: weeklyData.map((_, index) => `Week ${index + 1}`),
//       labels: {
//         style: {
//           fontWeight: "bold",
//           colors: "#000",
//         },
//       },
//     },
//     yaxis: {
//       min: 0,
//       max: 100,
//       tickAmount: 5, // Divide the axis into intervals of 20%
//       labels: {
//         style: {
//           fontSize: "14px",
//           fontWeight: "bold",
//           color: "#333",
//         },
//         formatter: (value) => `${value}%`, // Format Y-axis labels as percentages
//       },
//     },
//     stroke: {
//       curve: "straight",
//       width: 4,
//     },
//     grid: {
//       padding: {
//         right: 25,
//         left: 15,
//         top:0,
//         bottom:0,
//       },
//     },
//     tooltip: {
//       enabled: true,
//     },
//     colors: ["#28A745"],
//   };
  


//   return (
//     <div className="container-fluid mt-2">
//       <div className="row g-3">
//         {/* Layout for Desktop */}
//         {isDesktop && (
//           <>
//             <div className="col-4 d-flex align-items-stretch mb-2">
//               <div className="card text-center shadow" style={{ backgroundColor: "#dc6c3a", borderRadius: "10px", width: "100%",height:"80%" }}>
//                 <div style={{ fontWeight: "bold", color: "white", textAlign: "left", padding: "15px" }}>
//                   <h2>Daily Customer Visits</h2>
//                 </div>
//                 <div className="card-body d-flex flex-column justify-content-start ">
//                   <ApexChart
//                     options={dailyChartConfig}
//                     series={dailyChartConfig.series}
//                     type="bar" // Ensure 'type' is defined here
//                     height={250}
//                   />

//                 </div>
//               </div>
//             </div>
//             <div className="col-4 d-flex align-items-stretch mb-2">
//               <div className="card text-center shadow" style={{ backgroundColor: "#CACFF0", borderRadius: "10px", width: "100%",height:"80%" }}>
//                 <div style={{ fontWeight: "bold", color: "white", textAlign: "left", padding: "20px" }}>
//                   <h2>Monthly Customer Visits</h2>
//                 </div>
//                 <ApexChart
//         options={chartConfig}
//         series={chartSeries}
//         type={chartType}
//         height={250}
//       />
//               </div>
//             </div>
//             <div className="col-4 d-flex align-items-stretch mb-2">
//               <div className="card text-center shadow" style={{ backgroundColor: "#D3C5E5", borderRadius: "10px", width: "100%",height:"80%" }}>
//                 <div style={{ fontWeight: "bold", color: "white", textAlign: "left", padding: "20px" }}>
//                   <h2>Customer Distribution</h2>
//                 </div>
//                 <div className="card-body d-flex flex-column justify-content-center">
//                   <DonutChart
//                     type="donut"
//                     series={customerDistribution}
//                     labels={distributionLabels}
//                     height="300px"
//                   />
//                 </div>
//               </div>
//             </div>
//           </>
//         )}

//         {/* Layout for Tablet */}
//         {isTablet && (
//           <>
//             <div className="col-6 d-flex align-items-stretch mb-3">
//               <div className="card text-center shadow" style={{ backgroundColor: "#D7AE94", borderRadius: "10px", width: "100%" }}>
//                 <div style={{ fontWeight: "bold", color: "white", textAlign: "left", padding: "20px" }}>
//                   <h2>Daily Customer Visits</h2>
//                 </div>
//                 <div className="card-body d-flex flex-column justify-content-center">
//                   <ApexChart
//                     options={dailyChartConfig}
//                     series={dailyChartConfig.series}
//                     type="bar" // Ensure 'type' is defined here
//                     height={300}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="col-6 d-flex align-items-stretch mb-3">
//               <div className="card text-center shadow" style={{ backgroundColor: "#CACFF0", borderRadius: "10px", width: "100%" }}>
//                 <div style={{ fontWeight: "bold", color: "white", textAlign: "left", padding: "20px" }}>
//                   <h2>Monthly Customer Visits</h2>
//                 </div>
//                 <div className="card-body d-flex flex-column justify-content-center">
//                   <LineChartWithDropdown />
//                 </div>
//               </div>
//             </div>
//             <div className="col-12 d-flex align-items-stretch mb-3">
//               <div className="card text-center shadow" style={{ backgroundColor: "#D3C5E5", borderRadius: "10px", width: "100%"}}>
//                 <div style={{ fontWeight: "bold", color: "white", textAlign: "left", padding: "20px" }}>
//                   <h2>Customer Distribution</h2>
//                 </div>
//                 <div className="card-body d-flex flex-column justify-content-center">
//                   <DonutChart
//                     type="donut"
//                     series={customerDistribution}
//                     labels={distributionLabels}
//                     height="300px"
                    
//                   />
//                 </div>
//               </div>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default DashChart;
