import React from 'react';

// Inline CSS styling for basic formatting
const styles = {
  container: {
    margin: '0 auto',
    padding: '20px',
    maxWidth: '800px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
  heading1: {
    fontSize: '28px',
    margin: '20px 0',
    textAlign: 'center',
  },
  heading2: {
    fontSize: '22px',
    margin: '15px 0',
    color: '#333',
  },
  text: {
    marginBottom: '10px',
    color: '#555',
  },
  contactInfo: {
    fontWeight: 'bold',
    marginTop: '15px',
  },
};

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading1}>Privacy Policy of Dione Software</h1>

      <h2 style={styles.heading2}>1. Introduction</h2>
      <p style={styles.text}>
        Dione Software (“we”, “us”, or “our”) is committed to protecting your privacy and ensuring the confidentiality of your personal information.
        This Privacy Policy outlines how we collect, use, share, and protect your data when you use our services as a Tech Provider for Meta’s WhatsApp Cloud API and related integrations.
      </p>

      <h2 style={styles.heading2}>2. Information We Collect</h2>
      <p style={styles.text}>
        We may collect the following types of information when you interact with our services:
      </p>
      <ul style={styles.text}>
        <li><strong>Personal Information:</strong> names, email addresses, phone numbers, and business-related information necessary for registering and managing WhatsApp API services.</li>
        <li><strong>Business Information:</strong> Information about your business, such as business name, tax identification number, and location, to offer and maintain services.</li>
        <li><strong>Usage Data:</strong> Data on how you interact with our services, including API usage, IP addresses, browser type, device information, and log data.</li>
        <li><strong>Cookies:</strong> We may use cookies and similar tracking technologies to enhance your experience on our platform.</li>
      </ul>

      <h2 style={styles.heading2}>3. How We Use Your Information</h2>
      <p style={styles.text}>
        We use the collected data to:
      </p>
      <ul style={styles.text}>
        <li>Provide, maintain, and improve our services.</li>
        <li>Manage customer accounts and authenticate users.</li>
        <li>Offer customer support and troubleshoot technical issues.</li>
        <li>Monitor usage patterns and enhance service performance.</li>
        <li>Communicate with you about service updates, offers, or other information.</li>
      </ul>

      <h2 style={styles.heading2}>Contact Us</h2>
      <p style={styles.text}>
        For any questions or concerns regarding this Privacy Policy, please contact us at:
      </p>
      <p style={styles.contactInfo}>
      <strong>Email:</strong> hello@dione.software<br />
        <strong>Address:</strong> 106-A1, BLOCK A, SATELITE TOWN, Rawalipindi
      </p>
    </div>
  );
};

export default PrivacyPolicy;
