import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ImCross, ImCloudDownload } from 'react-icons/im';

const File = ({ setShowFilePopup }) => {
    const [dragging, setDragging] = useState(false);
    const [fileName, setFileName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleClose = () => {
        setShowFilePopup(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        handleFiles(e.dataTransfer.files);
    };

    const handleFileChange = (e) => {
        handleFiles(e.target.files);
    };

    const handleFiles = (files) => {
        const excelFiles = Array.from(files).filter(file => {
            const fileName = file.name.toLowerCase();
            return fileName.endsWith('.xlsx') || fileName.endsWith('.xls');
        });

        if (excelFiles.length === 0) {
            setErrorMessage('Please choose only Excel files.');
            setFileName('');
        } else {
            setErrorMessage('');
            const file = excelFiles[0]; 
            setFileName(file.name);

            const interval = setInterval(() => {
                setUploadProgress(prevProgress => {
                    const increment = Math.random() * 5; 
                    const newProgress = prevProgress + increment;
                    if (newProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return newProgress;
                });
            }, 100); 

            setTimeout(() => {
                console.log('Excel file:', file);
                clearInterval(interval);
                setUploadProgress(0);
            }, 5000); 
        }
    };

    return (
        <>
            <Modal show={true} onHide={handleClose} >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className={`drop-area ${dragging ? 'active' : ''} file`}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <div>
                            <ImCloudDownload className='size' />
                        </div>
                        <div className="drag-text mb-11">
                            {fileName ? `Excel File: ${fileName}` : 'Drag & Drop Excel File Here'}
                        </div>
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        {uploadProgress > 0 && uploadProgress < 100 &&
                            <div className="progress w-75 mt-3 ml-10 ">
                                <div className="progress-bar" role="progressbar" style={{ width: `${uploadProgress}%`, backgroundColor: 'goldenrod' }}></div>
                            </div>}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default File;
