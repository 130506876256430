import React, { useState } from "react";
import './BotName.css';

const BotName = ({ onSave, onClose }) => {
  const [botName, setBotName] = useState("");
  const [botType, setBotType] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-z0-9_]*$/;

    if (regex.test(value) && value.length <= 40) {
      setBotName(value);
      setError("");
    } else {
      setError("Only lowercase letters, digits, and underscores are allowed.");
    }
  };

  const handleTypeChange = (e) => {
    setBotType(e.target.value);
    setError("");
  };

  const handleSave = () => {
    if (!botName || !botType) {
      setError("Please enter a bot name and select a bot type.");
    } else if (!error) {
      onSave({ botName, botType });
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">New Bot</div> <select
          className="popup-select"
          value={botType}
          onChange={handleTypeChange}
        >
          <option value="">Select Bot Type</option>
          <option value="simple">Conversational Bot</option>
          <option value="broadcast">Broadcast Bot</option>
        </select>

        <input
          className="popup-input"
          type="text"
          value={botName}
          onChange={handleChange}
          placeholder="Enter Bot Name"
        />

        {error && <div className="error-message">{error}</div>}
        <div
          style={{
            display: 'flex', // Flexbox for alignment
            justifyContent: 'center', // Center the buttons horizontally
            gap: '20px', // Equal spacing between the buttons
            marginTop: '5px', // Optional: Add space above the button group
          }}
        >
          <button
            onClick={handleSave}
            style={{
              backgroundColor: '#25D366', // Green background
              color: 'white', // White text
              border: 'none',
              padding: '8px 16px', // Adjust padding for a nice size
              borderRadius: '6px', // Rounded corners
              cursor: 'pointer', // Pointer cursor on hover
            }}
          >
            Save
          </button>
          <button
            onClick={onClose}
            style={{
              backgroundColor: 'rgb(255, 118, 64)', // Orange background
              color: 'white', // White text
              border: 'none',
              padding: '8px 16px', // Adjust padding for consistency
              borderRadius: '6px', // Rounded corners
              cursor: 'pointer', // Pointer cursor on hover
            }}
          >
            Cancel
          </button>
        </div>


      </div>
    </div>
  );
};

export default BotName;
