import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import style from '../Style/CSS.module.css'; // Import custom CSS module
import { FaBirthdayCake, FaLocationArrow, FaPhoneAlt, FaUser } from 'react-icons/fa'; // Import icons
import { MdEmail, MdOutlineTitle } from "react-icons/md"; // Import icons
import { RiLockPasswordFill } from "react-icons/ri"; // Import icons
import '../Style/CSS.css'; // Import custom CSS file

const Admin = () => {
  // Initial data
  const data = {
    name: 'Sabir Hussain',
    title: 'conversation',
    email: 'dione@gmail.com',
    phone: '+923317368549',
    password: 'dionesoftware house'
  };

  // States
  const [info, setInfo] = useState(true); // State to track which section to display
  const [currentPassword, setCurrentPassword] = useState(""); // State to store current password
  const [newPassword, setNewPassword] = useState({
    newpassword:'',
    confirmpassword:''
  }); // State to store new password
  const [editableFields, setEditableFields] = useState({
    name: false,
    title: false,
    email: false,
    phone: false,
    password: false
  });
  const [formData, setFormData] = useState(data); // State to store form data

  const toggleEdit = (field) => {
    setEditableFields({ ...editableFields, [field]: !editableFields[field] });
  };

  const handleSave = (e, field) => {
    e.preventDefault();
    toggleEdit(field);
    // You can handle saving the changes here
    console.log(formData); // Log the updated form data
  };

  const handleChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };
//Save Password data
const Password=(e)=>{
const {name,value}=e.target;
setNewPassword(()=>({...newPassword,[name]:value}))
}
  // Function to handle form submission
  const handlePassword = (e) => {
    e.preventDefault();
    // Add logic to handle password change here
  console.log(newPassword)
if(currentPassword!==formData.email)
{
alert("current password incorrect")
}
else if(newPassword.newpassword!==newPassword.confirmpassword)
{
  alert("Confirm Password not match with new password")
}  
};


//save picture name in state
const [cu, up] = useState({
  profilePic: null, // To store the profile picture file
});
  // Function to handle changes in form inputs
  const FTN = (e) => {
    const { name, type, files } = e.target;
   if (type === 'file') {
      // If input type is file
      if (files && files.length > 0) {
        // Update state with the selected file
        up((prev) => ({ ...prev, [name]: files[0] }));
      }
    } 
  };

  return (
    <div className="container-fluid m-0 p-0 fs-6 w-100">
      <div className="height-30">
        {/* Profile Picture Section */}

       {/* Input for selecting profile picture */}
       <div className="avatar-container mb-1 text-center">
                </div>
            {/* Profile Picture Section */}
            <div>
              <div className="d-flex margin ">
                {/* Input for selecting profile picture */}
                <div className="avatar-container mb-1 text-center">
                  <label htmlFor="profilePicInput">
                    <img
                      src={
                        cu.profilePic
                          ? URL.createObjectURL(cu.profilePic)
                          : 'profile.png'
                      }
                      alt="Profile"
                      style={{
                        maxWidth: '100px',
                        maxHeight: '100px',
                        minWidth: '120px',
                        minHeight: '120px',
                        borderRadius: '50%',
                        border: '1px solid goldenrod',
                        boxShadow: 'goldenrod 0px 2px 5px',
                      }}
                      className="avatar-img"
                    />
                  </label>
                  {/* Actual file input hidden, styled to look like the avatar */}
                  <input
                    type="file"
                    accept="image/*"
                    id="profilePicInput"
                    name="profilePic"
                    onChange={FTN}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
</div>
  <p>{formData.name}</p>
        {/* <button className={`${style.btn} guest`}>Save Changes</button>*/}
        <div className={`d-flex gap-5 pt-3 cursor-pointer`}> 
          <p onClick={() => setInfo(true)} className='bottom'>Personal info</p>
          <p onClick={() => setInfo(false)} className='bottom'>Password</p>
        </div>
      </div>
      <div className="height-70 mt-3">
        {info ? (
          // Personal Info Section
          <>
            <div className='d-flex Textcolor'>
              <div className='mx-auto'>
                <p className='fs-5 fw-bolder'>Overview</p>
                {/* Title Field */}
                <div className='d-flex gap-1 mt-4'>
                  <span><MdOutlineTitle className='border border-black rounded-circle p-1 fs-4' /></span>
                  <span>Title:</span>
                  {editableFields.title ? (
                    <form onSubmit={(e) => handleSave(e, 'title')}>
                      <input
                        type="text"
                        value={formData.title}
                        className='alicebg'
                        onChange={(e) => handleChange(e, 'title')}
                      />
                      <button type="submit">Save</button>
                    </form>
                  ) : (
                    <>
                      <span className='fw-bold'>{formData.title}</span>
                      <span className='cursor-pointer position' onClick={() => toggleEdit('title')}>
                        <img src='edit.png' alt='error' height='20px' width='20px' />
                      </span>
                    </>
                  )}
                </div>
                {/* Name Field */}
                <div className='d-flex gap-1 mt-4'>
                  <span><FaUser className='border border-black rounded-circle p-1 fs-4' /></span>
                  <span>Name:</span>
                  {editableFields.name ? (
                    <form onSubmit={(e) => handleSave(e, 'name')}>
                      <input
                        type="text"
                        value={formData.name}
                        className='alicebg'
                        onChange={(e) => handleChange(e, 'name')}
                      />
                      <button type="submit">Save</button>
                    </form>
                  ) : (
                    <>
                      <span className='fw-bold'>{formData.name}</span>
                      <span className='cursor-pointer position' onClick={() => toggleEdit('name')}>
                        <img src='edit.png' alt='error' height='20px' width='20px' />
                      </span>
                    </>
                  )}
                </div>
                {/* Email Field */}
                <div className='d-flex gap-1 mt-4'>
                  <span><MdEmail className='border border-black rounded-circle p-1 fs-4' /></span>
                  <span>Email:</span>
                  {editableFields.email ? (
                    <form onSubmit={(e) => handleSave(e, 'email')}>
                      <input
                        type="email"
                        value={formData.email}
                        className='alicebg'
                        onChange={(e) => handleChange(e, 'email')}
                      />
                      <button type="submit">Save</button>
                    </form>
                  ) : (
                    <>
                      <span className='fw-bold'>{formData.email}</span>
                      <span className='cursor-pointer position' onClick={() => toggleEdit('email')}>
                        <img src='edit.png' alt='error' height='20px' width='20px' />
                      </span>
                    </>
                  )}
                </div>
                {/* Phone Field */}
                <div className='d-flex gap-1 mt-4'>
                  <span><FaPhoneAlt className='border border-black rounded-circle p-1 fs-4' /></span>
                  <span>Phone:</span>
                  {editableFields.phone ? (
                    <form onSubmit={(e) => handleSave(e, 'phone')} className='d-flex'>
                      <input
                        type="text"
                        value={formData.phone}
                        className='alicebg m-0 p-0'
                        onChange={(e) => handleChange(e, 'phone')}
                      />
                      <button type="submit">Save</button>
                    </form>
                  ) : (
                    <>
                      <span className='fw-bold'>{formData.phone}</span>
                      <span className='cursor-pointer position' onClick={() => toggleEdit('phone')}>
                        <img src='edit.png' alt='error' height='20px' width='20px' />
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>): (
          // Password Section
          <form className='d-flex textcolor w-100 flex-column gap-3 mb-3 marginx' onSubmit={handlePassword}>
            <div className="input-group flex-nowrap input wid mt-2 ">
              <span className='fs-4 pl-2 p-1.5'><RiLockPasswordFill /></span>
              <input
              required
                type="password"
                placeholder="Current password"
                name='currentpassword'
                aria-describedby="addon-wrapping"
                className='in ml-4 pl-3'
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div className="input-group flex-nowrap input wid mt-6">
              <span className='fs-4 pl-1 p-1.5'><RiLockPasswordFill /></span>
              <input
              required
                type="password"
                placeholder="New password"
                name='newpassword'
                aria-describedby="addon-wrapping"
                className='in pl-3'
                onChange={Password}
              />
            </div>
            <div className="input-group flex-nowrap input wid mt-6">
              <span className='fs-4 pl-2 p-1.5'><RiLockPasswordFill /></span>
              <input
              required
                type="password"
                placeholder="Confirm password"
                name='confirmpassword'
                className='in pl-3'
                onChange={Password}
                aria-describedby="addon-wrapping"
              />
            </div>
            <button type='submit' className={`${style.btn} wid mt-4`}>Save</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Admin;
