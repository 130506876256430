import React from 'react';

// Inline CSS styling for basic formatting
const styles = {
  container: {
    margin: '0 auto',
    padding: '20px',
    maxWidth: '800px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
  heading1: {
    fontSize: '28px',
    margin: '20px 0',
    textAlign: 'center',
  },
  heading2: {
    fontSize: '22px',
    margin: '15px 0',
    color: '#333',
  },
  text: {
    marginBottom: '10px',
    color: '#555',
  },
  contactInfo: {
    fontWeight: 'bold',
    marginTop: '15px',
  },
};

const TermsOfService = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading1}>Terms of Service for Dione Software</h1>
      <h2 style={styles.heading2}>1. Introduction</h2>
      <p style={styles.text}>
        Welcome to Dione Software! These Terms of Service (“Terms”) govern your access to and use of our services, including access to the WhatsApp Cloud API as a Tech Provider (“Services”). By using our services, you agree to be bound by these Terms.
      </p>

      <h2 style={styles.heading2}>2. Eligibility</h2>
      <p style={styles.text}>
        To use our Services, you must:
      </p>
      <ul style={styles.text}>
        <li>Be at least 18 years old.</li>
        <li>Have the authority to bind the entity you represent.</li>
        <li>Agree to comply with all applicable laws and regulations.</li>
      </ul>

      <h2 style={styles.heading2}>3. Account Registration</h2>
      <p style={styles.text}>
        You are required to create an account with accurate and complete information to access our Services. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
      </p>

      <h2 style={styles.heading2}>4. Service Usage</h2>
      <p style={styles.text}>
You agree to use the Services responsibly and in compliance with Meta’s WhatsApp Cloud API guidelines, as well as the following:
      </p>
      <ul style={styles.text}>
        <li>Do not use the Services for any unlawful, harmful, or abusive purposes.</li>
        <li>Do not attempt to interfere with or disrupt the integrity of our Services or the accounts of other users.</li>
        <li>Do not engage in unauthorized access, scraping, or reverse engineering of our platform or services.</li>
      </ul>

      <h2 style={styles.heading2}>Contact Us</h2>
      <p style={styles.text}>
        For questions regarding these Terms, please contact us at:
      </p>
      <p style={styles.contactInfo}>
        <strong>Email:</strong> hello@dione.software<br />
        <strong>Address:</strong> 106-A1, BLOCK A, SATELITE TOWN, Rawalipindi
      </p>
    </div>
  );
};

export default TermsOfService;
