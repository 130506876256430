import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./pages/Auth/AuthContext";
import { UserProvider } from "./pages/chat/widgets/userContext";
import { Provider } from "react-redux";
import store from "./Redux Toolkit/store";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/Style/custom.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

const root = document.getElementById("root");
const rootElement = ReactDOM.createRoot(root);

rootElement.render(
  <ErrorBoundary>
    <React.StrictMode>
      <BrowserRouter> {/* Wrap your entire app inside BrowserRouter */}
        <AuthProvider>
          <UserProvider>
            <Provider store={store}>
              <App />
            </Provider>
          </UserProvider>
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary>
);

// Measure performance
reportWebVitals();
