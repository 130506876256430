import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ImCross, ImUserPlus } from 'react-icons/im';
import { RiAdminFill, RiAdminLine, RiFileExcel2Fill } from "react-icons/ri";
import File from '../Modal/File';
import Admin from '../Modal/Admin';
import style from '../Style/CSS.module.css'
import '../Style/CSS.css'

import { GrContactInfo } from "react-icons/gr";
import axios from 'axios';
import { ApiEndPoint } from '../pages/components/utils/utlis';
import UserItem from './userComponent';
import ExcelUploadPopup from '../pages/ContactForm/dropzonComponent';
import ContactFormModal from '../pages/ContactForm/ContactForm';
//Remove This Array when Backend data fetch
const Array = [{ name: 'Mudassir Bhatti', phoneNumber: '971317368549', imageName: 'img7.avif', email: "bhatti@gmail.com" },
{ name: 'Khatija', phoneNumber: '99317368549', imageName: 'img2.jpg', email: "bhatti@gmail.com" },
{ name: 'Hussain', phoneNumber: '90317368549', imageName: 'img3.png', email: "bhatti@gmail.com" },
{ name: 'Sabir', phoneNumber: '89317368549', imageName: 'img4.avif', email: "bhatti@gmail.com" },
{ name: 'Rizwan', phoneNumber: '21317368549', imageName: 'img5.avif', email: "bhatti@gmail.com" },
{ name: 'Alyan', phoneNumber: '91317368549', imageName: 'img6.avif', email: "bhatti@gmail.com" },
{ name: 'Abubakar', phoneNumber: '92317368549', imageName: 'img8.avif', email: "bhatti@gmail.com" },
{ name: 'Zainab', phoneNumber: '92317368549', imageName: 'img2.jpg', email: "bhatti@gmail.com" },
{ name: 'Arshad', phoneNumber: '92317368549', imageName: 'img4.avif', email: "bhatti@gmail.com" },
{ name: 'Iqra', phoneNumber: '99317368549', imageName: 'img2.jpg', email: "bhatti@gmail.com" },
{ name: 'Hussain', phoneNumber: '90317368549', imageName: 'img3.png', email: "bhatti@gmail.com" },
{ name: 'Sabir', phoneNumber: '89317368549', imageName: 'img4.avif', email: "bhatti@gmail.com" },
{ name: 'Abubakar', phoneNumber: '21317368549', imageName: 'img5.avif', email: "bhatti@gmail.com" },
]
const Contacts = () => {
  //state used get data of map array
  const [selectedUser, setSelectedUser] = useState(null);

  const [users, setUsers] = useState([]);
  console.log("usersdatachat:", users)
  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getAllUsers`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const handleUserClick = (userData) => {
    setSelectedUser(userData);
  };
  //State and module used for PopUp modules File
  const [showFilePopup, setShowFilePopup] = useState(false); // State for File popup
  const handleFileIconClick = () => {
    setShowFilePopup(true); // Set state to true to show File popup
  };
  //State and module used for PopUp modules Admin
  const [showAdminPopup, setShowAdminPopup] = useState(false); // State for Admin popup
  const handleAdminIconClick = () => {
    setShowAdminPopup(true); // Set state to true to show Admin popup
  };
  // State and module for Add Contact 
  const [showContact, setShowContact] = useState(false);
  const handleShowAdContact = () => {
    setShowContact(true)
  }
  //state used to change bg color
  const [white, black] = useState(true)
  //used for
  const [showDropdown, setShowDropdown] = useState(false);


  const handleCrossClick = () => {
    setSelectedUser(null);

  };
  //Search user method and with place of Array varaible backend variable
  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  //get axios values and filter these values for searching purpose

  const filteredUsers = Object.values(users).filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  //Display Modal
  return (
    <div className='container-fluid row   m-0 p-0 Theme' style={{ height: '100vh' }} >
      <div className={`p-4 column col-lg-3 border col-md-5 cursor-pointer col-sm-12 `}>
        <div className='roww pb-2'>
          <div>
            <h3 className='fw-bolder'>Dione Chatbot</h3>
          </div>
          <div className='fs-2 cursor-pointer'>
            {/* <RiAdminFill title='Add Admin' onClick={handleAdminIconClick} />*/}
          </div>
        </div>
        <div>
          <input
            type='search'
            placeholder='search user'
            className='w-100 p-2 rounded mt-1  mb-1 input aliceblue'
            onChange={handleSearchInputChange}
            value={searchQuery}
          />
        </div>
        <div className='fs-2  mt-3  mb-1 d-flex justify-content-between'>
          {/* <p title='change theme' onClick={()=>black(!white)} >{white ? <CiDark  /> :<CiLight />}</p>  */}
          <RiFileExcel2Fill title='Upload Excel File Only' onClick={handleFileIconClick} />
          {/* Call handleFileIconClick when icon is clicked */}
          <RiAdminFill title='Add Admin' onClick={handleAdminIconClick} />
          <ImUserPlus title='Add New Contact' onClick={handleShowAdContact} />
        </div>
        <div className={style.scroll}>
          <h3 className='mb-1.5'>Users</h3>
          {filteredUsers.map((val, index) => (
            <UserItem key={index} user={val} handleUserClick={handleUserClick} />
          ))}
        </div>
      </div>

      {
        selectedUser === null ? (
          <div className={`Theme3 col-lg-9 col-md-7 ${style.back} d-none d-md-block d-lg-block`}>
            <div className=' d-flex flex-column gap-3  justify-content-center align-items-center text-center h-100'>
              <GrContactInfo className='fs-1' />
              <div>
                <h4>Contact Detail</h4>
                <p>Click any user  then get their detail </p>
              </div>
            </div>
          </div>
        ) : (
          <div className={`col-lg-9 col-md-7 Theme3 ${style.back}`}>
            <p className='fs-4' >
              <div title='close page' onClick={() => setSelectedUser(null)} >
                <ImCross className=" cursor-pointer position-absolute top-0 end-0 m-3"></ImCross>
              </div>
            </p>
            <div className='d-flex flex-column gap-4 '>
              <div
                className="rounded-circle d-flex justify-content-center mt-1"
                style={{
                  backgroundImage: selectedUser.profilePic
                    ? `url(data:image/jpeg;base64,${selectedUser.profilePic})`
                    : "none",
                  backgroundColor: selectedUser.profilePic
                    ? "transparent"
                    : selectedUser.color_code, // Set background color if profilePic is null
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  width: "200px",
                  height: "200px",
                  margin: "auto",
                  border: `1px solid #FFF`,
                  boxShadow: `none`,
                }}
              >
                <div style={{ margin: "auto", textAlign: "center", color: "#FFF", fontSize: "60px" }}>
                  {selectedUser && selectedUser.profilePic
                    ? ""
                    : selectedUser.name && selectedUser.name.length > 0 && selectedUser.name.includes(" ")
                      ? selectedUser.name[0] + selectedUser.name.split(" ")[1][0]
                      : selectedUser.name[0][0]}
                </div>


              </div>
              <div className='justify-content-center mt-1'>
                <h3>{selectedUser.name}</h3>
                <h3>{selectedUser.email}</h3>
                <h3>{selectedUser.phone}</h3>
              </div>


            </div>
          </div>

        )
      }

      {/* Render File popup if showFilePopup is true */}
      {<ExcelUploadPopup isOpen={showFilePopup} onClose={setShowFilePopup} />}
      {<ContactFormModal isOpen={showContact} onClose={setShowContact} />}

      {showAdminPopup && <Admin setShowAdminPopup={setShowAdminPopup} className='textcolor' />}
      {/* Render Contact popup if showContactPopup is true */}


    </div>
  )
}
export default Contacts;
