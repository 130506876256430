import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { SiChatbot } from "react-icons/si";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import MdNotificationsNone from "../pages/components/images/Asset 71mdpi.png";
import Chat from "../pages/chat/Chat";
import BulkMessages from "./BulkMessages";
import Temp from "./App";
import App from "../Chatbot/App";
import DataTable from "./TemplateData";
import Contacts from "../Contact Detail/Contacts";
import Admin_profile from "../Admin/Admin_profile";
import logo1 from "../pages/components/assets/icons/EchobotWhite.png";
import logo2 from "../pages/components/images/aws_logo.png";
import Template from "../pages/components/assets/icons/Template.png";
import Newtemp from "../pages/components/assets/icons/Create_New.png";
import Viewtemp from "../pages/components/assets/icons/View_Template.png";
import Createborad from "../pages/components/images/cast.png";
import Contact from "../pages/components/assets/icons/Contact.png";
import Chatbot from "../pages/components/assets/icons/chatbot.png";
import Chating from "../pages/components/assets/icons/Chat.png";
import Setting from "../pages/components/assets/icons/Settings.png";
import VUser from "../pages/components//assets/icons/View_Profile.png";
import Lgout from "../pages/components/assets/icons/Logout.png";
import ViewAdmin from "../Admin/ViewAdmin";
import Temp_Build from "./Temp_Bulid";
import DashChart from "./DashChart";
import whiteContactIc from "../pages/components/assets/icons/New_User.png";
import ExcelG from '../pages/components/assets/icons/XLS_Sheet.png';
import ContactIc from '../pages/components/assets/icons/New_User.png';
import { useSelector, useDispatch } from "react-redux";
import { Rightscreen } from "../Redux Toolkit/slice";
import { useAuth } from "../pages/Auth/AuthContext";
import "../Chatbot/index.css";
import "reactflow/dist/style.css";
import '../Style/CSS.css';
import { ApiEndPoint, decodeToken } from "../pages/components/utils/utlis";
import axios from "axios";
import ContactFormModal6 from "../pages/ContactForm/ContactForm6";
import ContactFormModal2 from "../pages/ContactForm/ContactForm2";
import ContactFormModal from "../pages/ContactForm/ContactForm";
import { borderRadius, fontSize, height, margin, minHeight, minWidth, padding, positions, width } from "@mui/system";
import { fetchUsers } from "../Redux Toolkit/reduxmodels/BotData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import buildTemplate from "../../src/pages/components/images/Template.png"
import Modal from 'react-modal'; // You can use any modal library
import Messagetemplate from "./Messagetemplate";
import Broadcast from "./Broadcast";

Modal.setAppElement('#root');


const Dashboard = () => {


  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [setDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);


  const jwtToken = sessionStorage.getItem("jwtToken");
  const decod = decodeToken(jwtToken);
  const { decodedToken } = decod;
  const { userId } = decodedToken;
  const { logout, role } = useAuth();
  const [white, setWhite] = useState(true);
  const [Toggleleftscreen, setToggleleftscreen] = useState(true);
  const [messageTemplateArrow, setMessageTemplateArrow] = useState(false);
  const [messageTemplateFunctionality, setMessageTemplateFunctionality] =
    useState(false);
  const [adminData, setAdminData] = useState(false);
  const [roleChk, setRoleCHK] = useState(true);

  const [showModal, setShowModal] = useState(false); // State for the main modal
  const [showContactModal, setshowContactModal] = useState(false);
  const [showContactModal2, setshowContactModal2] = useState(false);
  const [sendMessageArrow, setSendMessageArrow] = useState(false);
  const [sendMessageFunctionality, setSendMessageFunctionality] =
    useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isContactIc, setContactiIc] = useState(false)

  const toggleMessageTemplate = () => {
    setMessageTemplateArrow(!messageTemplateArrow);
    setMessageTemplateFunctionality(!messageTemplateFunctionality);
  };
  const [broadcast, setbroadcast] = useState(false);
  const [broadcastFunctionality, setbroadcastFunctionality] = useState(false);
  const rightSCreen = useSelector((state) => state.Data.count ? state.Data.count : "0");
  const [screenName, setScreenName] = useState("Dashboard");
  const dispatch = useDispatch();

  const changeScreen = (value, name) => {
    dispatch(Rightscreen(value));
    localStorage.setItem('rightScreen', value);
    setScreenName(name);
    localStorage.setItem('screenName', name); // Save screenName to 
  };
  const filterRef = useRef(null);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        // setShowFilters(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);

    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);

  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);






  useEffect(() => {
    const savedScreen = localStorage.getItem('rightScreen');
    const savedScreenName = localStorage.getItem('screenName');

    if (savedScreen) {
      dispatch(Rightscreen(savedScreen)); // Update the state with the saved screen value
    }

    if (savedScreenName) {
      setScreenName(savedScreenName); // Update the state with the saved screen name
    }
  }, [dispatch]);


  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleLogoutClick = () => {
    setIsPopupOpen(true); // Open the popup
  };

  const toggleDropdownSheet = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleConfirmLogout = () => {
    setIsPopupOpen(false); // Close the popup
    logout(); // Call the logout function
  };

  const handleCancelLogout = () => {
    setIsPopupOpen(false); // Close the popup without logging out
  };
  useEffect(() => {
    if (role === "admin") {
      setRoleCHK(false);
      console.log(roleChk);
    } else {
      setRoleCHK(true);
    }
  }, [role, roleChk]);
  const togglebroadcast = () => {
    setbroadcast(!broadcast);
    setbroadcastFunctionality(!broadcastFunctionality);
  };
  //used to change right side page data
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const closeContactModal = () => {
    setshowContactModal(false);
  };
  const closeContactModal2 = () => {
    setshowContactModal2(false);
  };
  const openContactModal = () => {
    setshowContactModal(true);
  };
  const openContactModal2 = () => {
    setshowContactModal2(true);
  };

  const avatarStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '10px',
  };

  const notificationItemStyle = {
    padding: '10px',
    borderBottom: '1px solid #eee',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  };

  const notificationTextStyle = {
    fontSize: '14px',
  };

  const notificationTimeStyle = {
    fontSize: '12px',
    color: '#888',
    marginTop: '5px',
  };





  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUse, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchUse = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/admins/${userId}`);
      setAdminData(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  var right = "0";
  // if (rightSCreen === "") {
  //   right = (
  //     <div>
  //       <div className="gap-3 m-0 text-center center d-flex flex-column align-items-center ">
  //         <SiChatbot className="fs-2 " style={{ fontWeight: "light" }} />
  //         <div>
  //           <h4>Welcome to AWS CRM</h4>
  //           <p>
  //             Your one stop solution to manage your WhatsApp Business Account
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
  if (rightSCreen === "0" || rightSCreen === null) {
    right = <DashChart />
  }
  if (rightSCreen === "1") {
    right = <Temp />;
  }
  if (rightSCreen === "2") {
    right = <BulkMessages />;
  }
  if (rightSCreen === "4") {
    right = <DataTable />;
  }
  if (rightSCreen === "5") {
    right = <Contacts />;
  }
  if (rightSCreen === "6") {
    right = <Chat isCollapsed={isCollapsed} />;
  }
  if (rightSCreen === "7") {
    right = <Admin_profile />;
  }
  if (rightSCreen === "8") {
    right = <ViewAdmin />;
  }
  if (rightSCreen === "9") {
    right = <Messagetemplate />;
  }
  if (rightSCreen === "10") {
    right = <App />
  }

  // Function to toggle the background color
  const toggleBackgroundColor = () => {
    setWhite(!white);
  };
  useEffect(() => {
    if (role === "admin") {
      setRoleCHK(false);
    } else {
      setRoleCHK(true);
    }
  }, [role, roleChk]);

  useEffect(() => {
    // Replace with the actual user ID

    // Initial fetch
    dispatch(fetchUsers(userId));

    // Interval to fetch users every 10 seconds
    const intervalId = setInterval(() => {
      dispatch(fetchUsers(userId));
    }, 60000);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [dispatch]);

  const toggleSendMessage = () => {
    setSendMessageArrow(!sendMessageArrow);
    setSendMessageFunctionality(!sendMessageFunctionality);
  };
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [isSettingOpen, setIsSettingOpen] = useState(false);

  const toggleTemplate = () => {
    setIsTemplateOpen(!isTemplateOpen);
    setIsSettingOpen(false);

  };
  const toggleSetting = () => {
    setIsSettingOpen(!isSettingOpen);
    setIsTemplateOpen(false);

  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="dashboard-container">
      <div
        className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}
        style={{
          width: isCollapsed ? '60px' : '250px',
          boxShadow: '2px 0 5px rgba(0, 0, 0, 0.2)',
          height: '98vh',
          maxWidth: "200px",
          minWidth: !isCollapsed ? "200px" : "60px"
        }}

      >
        <div className="collapse-toggle" onClick={toggleCollapse}>
          {isCollapsed ? (
            <FaChevronRight
              style={{
                color: 'white',
                fontSize: '10px', // Updated font size
                marginLeft: "1px",

              }}
            />
          ) : (
            <FaChevronLeft
              style={{
                color: 'white',
                fontSize: '10px', // Updated font size
                marginLeft: "1px",
              }}
            />
          )}
        </div>

        <div
          style={{ marginTop: "2px", marginLeft: "7px", cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          onClick={() => {
            setScreenName('Dashboard');
            changeScreen('0', "Dashboard");
            setIsTemplateOpen(false);
            setIsSettingOpen(false);
          }}
        >
          {white ? (
            <img src={logo1} alt="error" height='26px' width='26px' /> // Updated size
          ) : (
            <img
              src={logo2}
              alt="error"
              height='32px' width='32px'// Updated size
              style={{ marginTop: 2 }}
            />
          )}
          {!isCollapsed && (
            <span style={{ marginLeft: "10px", fontSize: "16px", fontWeight: "bold", color: white ? 'white' : 'black' }}>
              Ecco Bot
            </span>
          )}


        </div>

        <div className="menu">
          <div
            className="menu-item"
            onClick={() => {
              setScreenName('Dashboard');
              changeScreen('0', "Dashboard");
              setIsTemplateOpen(false);
              setIsSettingOpen(false);
              setIsCollapsed(true)
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '9px',
              borderRadius: '5px',
              backgroundColor: screenName === "Dashboard" ? "#FF7640" : "transparent"
            }}
            onMouseEnter={(e) => {
              if (screenName !== "Dashboard") {
                e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
              }
            }}
            onMouseLeave={(e) => {
              if (screenName !== "Dashboard") {
                e.currentTarget.style.backgroundColor = 'transparent';
              }
            }}
          >
            <div className="menu-icon-text" style={{ display: 'flex', alignItems: 'center' }}

            >
              <FontAwesomeIcon
                icon={faHome}
                color="white"
                style={{ fontSize: "20px", transition: "font-size 0.2s ease-in-out" }} // Add smooth transition
                onMouseEnter={(e) => {

                  if (screenName !== "Dashboard") {
                    e.currentTarget.style.fontSize = '22px'; // Increase size on hover

                  }
                  
                }}
                onMouseLeave={(e) => {
                  if (screenName !== "Dashboard") {
                    e.currentTarget.style.fontSize = '20px'; // Reset size on leave
                    ;
                  }
                }}
              />
              {!isCollapsed && (
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: 'white',
                    marginLeft: '20px',
                  }}
                >
                  Dashboard
                </span>
              )}
            </div>
          </div>
          {/* Build Template */}
          {/* <div
            className="menu-item"
            onClick={() => {
              setScreenName('Build Template');
              changeScreen('9', "Build Template");
              setIsCollapsed(true)
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: "8px",
              borderRadius: '8px',
              backgroundColor: screenName === "Build Template" ? "#FF7640" : "transparent"
            }}
            onMouseEnter={(e) => {
              if (screenName !== "Build Template") {
                e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
              }

            }}

            onMouseLeave={(e) => {
              if (screenName !== "Build Template") { e.currentTarget.style.backgroundColor = 'transparent'; }

            }}
          ><div className="menu-icon-text">
              <img src={buildTemplate} height="20px" width="20px" /> 
              {!isCollapsed && (
                <span style={{
                  fontSize: '14px',
                  fontWeight: 'bold', color: 'white',
                }}>
                  Build Template
                </span>
              )}
            </div>

          </div> */}
          {/* View Templates */}
          <div
            className="menu-item"
            onClick={() => {
              setScreenName('View Templates');
              changeScreen('4', "View Templates");
              setIsCollapsed(true)
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: "8px",
              borderRadius: '5px',
              backgroundColor: screenName === "View Templates" ? "#FF7640" : "transparent"
            }}
            onMouseEnter={(e) => {
              if (screenName !== "View Templates") {
                e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
              }

            }}

            onMouseLeave={(e) => {
              if (screenName !== "View Templates") { e.currentTarget.style.backgroundColor = 'transparent'; }

            }}
          ><div className="menu-icon-text">
              <img src={Template} height="20px" width="20px"
                onMouseEnter={(e) => {
                  if (screenName !== "View Templates") {
                    e.currentTarget.style.width = '22px'; // Increase size on hover

                  }
                }}
                onMouseLeave={(e) => {
                  if (screenName !== "View Templates") {
                    e.currentTarget.style.width = '20px'; // Reset size on leave
                    ;
                  }
                }}
              /> {/* Standardized size */}

              {!isCollapsed && (
                <span style={{
                  fontSize: '14px',
                  fontWeight: 'bold', color: 'white',

                }}>
                  View Templates
                </span>
              )}
            </div>

          </div>



          <div
            className="menu-item"
            onClick={() => {
              setScreenName('Create Broadcast');
              changeScreen('2', 'Create Broadcast');
              setIsTemplateOpen(false);
              setIsSettingOpen(false);
              setIsCollapsed(true)
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
              borderRadius: '5px',
              backgroundColor: screenName === "Create Broadcast" ? "#FF7640" : "transparent"
            }}
            onMouseEnter={(e) => {
              if (screenName !== "Create Broadcast") {
                e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
              }

            }}

            onMouseLeave={(e) => {
              if (screenName !== "Create Broadcast") { e.currentTarget.style.backgroundColor = 'transparent'; }

            }}
          >
            <div className="menu-icon-text" style={{ display: 'flex', alignItems: 'center' }}>
              <img src={Createborad} height="20px" width="20px"
                onMouseEnter={(e) => {
                  if (screenName !== "Create Broadcast") {
                    e.currentTarget.style.width = '22px'; // Increase size on hover

                  }
                }}
                onMouseLeave={(e) => {
                  if (screenName !== "Create Broadcast") {
                    e.currentTarget.style.width = '20px'; // Reset size on leave
                    ;
                  }
                }}
              /> {/* Standardized size */}
              {!isCollapsed && (
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: 'white',
                    marginLeft: '10px',
                  }}
                >
                  Create Broadcast
                </span>
              )}
            </div>
          </div>

          <div
            className="menu-item"
            onClick={() => {
              setScreenName("Client List");
              changeScreen('5', "Client List");
              setIsTemplateOpen(false);
              setIsSettingOpen(false);
              setIsCollapsed(true)
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
              borderRadius: '5px',

              backgroundColor: screenName === "Client List" ? "#FF7640" : "transparent"
            }}
            onMouseEnter={(e) => {
              if (screenName !== "Client List") {
                e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
              }

            }}

            onMouseLeave={(e) => {
              if (screenName !== "Client List") { e.currentTarget.style.backgroundColor = 'transparent'; }

            }}
          >
            <div className="menu-icon-text" style={{ display: 'flex', alignItems: 'center' }}>
              <img src={Contact} height={20} width={20}
                onMouseEnter={(e) => {
                  if (screenName !== "Client List") {
                    e.currentTarget.style.width = '22px'; // Increase size on hover

                  }
                }}
                onMouseLeave={(e) => {
                  if (screenName !== "Client List") {
                    e.currentTarget.style.width = '20px'; // Reset size on leave
                    ;
                  }
                }}
              />
              {!isCollapsed && (
                <span
                  style={{
                    fontSize: 14, // Reduced font size
                    fontWeight: 'bold',
                    color: 'white',
                    marginLeft: '10px',
                  }}
                >
                  Client List
                </span>
              )}
            </div>
          </div>

          <div
            className="menu-item"
            onClick={() => {
              setScreenName('Chatbot Builder');
              changeScreen('10', "Chatbot Builder");
              setIsTemplateOpen(false);
              setIsSettingOpen(false);
              setIsCollapsed(true)
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
              borderRadius: '5px',
              backgroundColor: screenName === "Chatbot Builder" ? "#FF7640" : "transparent"
            }}
            onMouseEnter={(e) => {
              if (screenName !== "Chatbot Builder") {
                e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
              }

            }}

            onMouseLeave={(e) => {
              if (screenName !== "Chatbot Builder") { e.currentTarget.style.backgroundColor = 'transparent'; }

            }}
          >
            <div className="menu-icon-text" style={{ display: 'flex', alignItems: 'center' }}>
              <img src={Chatbot} height={20} width={20}
                onMouseEnter={(e) => {
                  if (screenName !== "Chatbot Builder") {
                    e.currentTarget.style.width = '22px'; // Increase size on hover

                  }
                }}
                onMouseLeave={(e) => {
                  if (screenName !== "Chatbot Builder") {
                    e.currentTarget.style.width = '20px'; // Reset size on leave
                    ;
                  }
                }}
              />
              {!isCollapsed && (
                <span
                  style={{
                    fontSize: 14, // Reduced font size
                    fontWeight: 'bold',
                    color: 'white',
                    marginLeft: '10px',
                  }}
                >
                  Chatbot Builder
                </span>
              )}
            </div>
          </div>
          <div
            className="menu-item"
            onClick={() => {
              setScreenName('Chat');
              changeScreen('6', "Chat");
              setIsTemplateOpen(false);
              setIsSettingOpen(false);
              setIsCollapsed(true)
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
              borderRadius: '5px',

              backgroundColor: screenName === "Chat" ? "#FF7640" : "transparent"
            }}
            onMouseEnter={(e) => {
              if (screenName !== "Chat") {
                e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
              }

            }}

            onMouseLeave={(e) => {
              if (screenName !== "Chat") { e.currentTarget.style.backgroundColor = 'transparent'; }

            }}>

            <div className="menu-icon-text" style={{ display: 'flex', alignItems: 'center' }}>
              <img src={Chating} height={20} width={20}
                onMouseEnter={(e) => {
                  if (screenName !== "Chat") {
                    e.currentTarget.style.width = '22px'; // Increase size on hover

                  }
                }}
                onMouseLeave={(e) => {
                  if (screenName !== "Chat") {
                    e.currentTarget.style.width = '20px'; // Reset size on leave
                    ;
                  }
                }}
              />
              {!isCollapsed && (
                <span
                  style={{
                    fontSize: 14, // Reduced font size
                    fontWeight: 'bold',
                    color: 'white',
                    marginLeft: '10px',
                  }}
                >
                  Chat
                </span>
              )}
            </div>
          </div>
          <div
            className="menu-item"
            onClick={toggleSetting}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
              borderRadius: '5px',


            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
            }}

            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = 'transparent';
            }}
          >
            <div className="menu-icon-text" style={{ display: 'flex', alignItems: 'center' }}>
              <img src={Setting} height={20} width={20}
                onMouseEnter={(e) => {
                  if (screenName !== "Setting") {
                    e.currentTarget.style.width = '22px'; // Increase size on hover

                  }
                }}
                onMouseLeave={(e) => {
                  if (screenName !== "Setting") {
                    e.currentTarget.style.width = '20px'; // Reset size on leave
                    ;
                  }
                }}
              />
              {!isCollapsed && (
                <span
                  style={{
                    fontSize: 14, // Reduced font size
                    fontWeight: 'bold',
                    color: 'white',
                    marginLeft: '10px',
                  }}
                >
                  Settings
                </span>
              )}
            </div>
            {!isCollapsed &&
              (isSettingOpen ? (
                <FaChevronDown
                  style={{
                    color: 'white',
                    fontSize: '14px',

                  }}
                />
              ) : (
                <FaChevronRight
                  style={{
                    color: 'white',
                    fontSize: '14px',

                  }}
                />
              ))}
          </div>

          <div className={`submenu ${isSettingOpen ? '' : 'collapsed'}`}>
            {roleChk && (
              <div
                className="submenu-item"
                onClick={() => {
                  setScreenName('View User');
                  changeScreen('8', "View User");
                  setIsCollapsed(true)
                }}
                // style={{
                //   display: 'flex',
                //   alignItems: 'center',
                //   // padding: "1px",                 
                //   height: "35px",
                //   minheight: "30px",
                //   borderRadius: '8px',
                //   width: '40px',
                //   backgroundColor: screenName === "View User" ? "#FF7640" : "transparent",

                // }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px',
                  borderRadius: '5px',
                  backgroundColor: screenName === "View User" ? "#FF7640" : "transparent"
                }}
                onMouseEnter={(e) => {
                  if (screenName !== "View User") {
                    e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
                  }
                }}

                onMouseLeave={(e) => {
                  if (screenName !== "View User") { e.currentTarget.style.backgroundColor = 'transparent'; }
                }}
              >
                <img src={VUser} height={20} width={20} style={{ marginLeft: '2px' }}
                  onMouseEnter={(e) => {
                    if (screenName !== "View User") {
                      e.currentTarget.style.width = '22px'; // Increase size on hover

                    }
                  }}
                  onMouseLeave={(e) => {
                    if (screenName !== "View User") {
                      e.currentTarget.style.width = '20px'; // Reset size on leave
                      ;
                    }
                  }}
                />
                {!isCollapsed && (
                  <span
                    style={{ fontSize: 12, color: 'white', marginLeft: '20px', width: '90px' }}> {/* Reduced font size */}
                    View User
                  </span>

                )}
              </div>
            )}

            <div
              className="submenu-item"
              onClick={handleLogoutClick}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: "1px",
                marginTop: "8px",
                borderRadius: '5px',

              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
              }}
            >
              <img src={Lgout} height={20} width={20} style={{ marginLeft: '10px' }}
                onMouseEnter={(e) => {
                  if (screenName !== "Lgout") {
                    e.currentTarget.style.width = '22px'; // Increase size on hover

                  }
                }}
                onMouseLeave={(e) => {
                  if (screenName !== "Lgout") {
                    e.currentTarget.style.width = '20px'; // Reset size on leave
                    ;
                  }
                }}
              />
              {!isCollapsed && (
                <span style={{ fontSize: 12, color: 'white', marginLeft: '20px' }}> {/* Reduced font size */}
                  Logout
                </span>
              )}
            </div>
          </div>
        </div>
      </div>


      <div className="main-content">
        <div className="header">
          <div className="left-section">
            <span className="screen-name">{screenName}</span>
            {screenName === "Client List" ? (
              <div className="icon-group w-15 h-15">
                {/* <img src={ExcelG} height={20} width={20}  onClick={handleShowModal} /> */}
                <img

                  src={isContactIc ? whiteContactIc : ContactIc}
                  height={15}
                  width={15}
                  onMouseEnter={(e) => {
                    setContactiIc(true)
                    e.currentTarget.style.backgroundColor = "#FF7640";
                  }}
                  onMouseLeave={(e) => {
                    setContactiIc(false)
                    e.currentTarget.style.backgroundColor = "transparent";
                  }}
                  onClick={openContactModal} />
              </div>
            ) : screenName === "View User" && roleChk? (
              <div className="icon-group">
                <img  src={ContactIc} height={15} width={15} alt="Add" onClick={openContactModal2} />
              </div>
            ) : (
              ""
            )}
          </div>


          <div className="profile-section" style={{ position: 'relative' }} ref={filterRef}>
            {/* Notification Icon */}
            <img
              src={MdNotificationsNone}
              height={15}
              width={15}
              className="notification-icon"
              onClick={toggleDropdownSheet}
              style={{ cursor: 'pointer' }}

            />


            {isDropdownOpen && (
              <div style={{ position: 'relative' }}>
                {/* Triangle at the top */}
                <div
                  style={{
                    position: 'absolute',
                    top: '15px', // Adjust this to position just above the dropdown
                    right: '7px', // Adjust this to align with the notification icon
                    width: '0',
                    height: '0',
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderBottom: '10px solid white', // Triangle pointing down
                    zIndex: '1001', // Ensure it's above the dropdown
                  }}
                />

                {/* The Dropdown */}
                <div
                  className="position-absolute bg-white shadow-lg p-3"
                  style={{
                    top: '25px', // Position below the icon
                    right: '6px',  // Align to the right side of the container
                    width: '40vh',
                    borderRadius: '12px 0px 12px 12px',
                    zIndex: '1000',
                    maxHeight: '60vh', // Limit the height of the dropdown
                    overflowY: 'auto', // Enable vertical scrolling
                    position: 'relative',
                  }}
                >
                  {/* Dropdown Content */}
                  <div className="filter-option cursor-pointer p-2 d-flex align-items-center" style={notificationItemStyle}>
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar1.png" // Sample avatar image
                      alt="avatar"
                      style={avatarStyle}
                    />
                    <div style={notificationTextStyle}>
                      <strong>Sabir</strong> sent you a new message
                      <div style={notificationTimeStyle}>2 mins ago</div>
                    </div>
                  </div>

                  <div className="filter-option cursor-pointer p-2 d-flex align-items-center" style={notificationItemStyle}>
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar2.png" // Sample avatar image
                      alt="avatar"
                      style={avatarStyle}
                    />
                    <div style={notificationTextStyle}>
                      <strong>Ayesha</strong> sent a new voice message
                      <div style={notificationTimeStyle}>5 mins ago</div>
                    </div>
                  </div>

                  {/* More notifications can be added below */}
                  <div className="filter-option cursor-pointer p-2 d-flex align-items-center" style={notificationItemStyle}>
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar1.png"
                      alt="avatar"
                      style={avatarStyle}
                    />
                    <div style={notificationTextStyle}>
                      <strong>Sabir</strong> sent you a new message
                      <div style={notificationTimeStyle}>10 mins ago</div>
                    </div>
                  </div>

                  <div className="filter-option cursor-pointer p-2 d-flex align-items-center" style={notificationItemStyle}>
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar1.png"
                      alt="avatar"
                      style={avatarStyle}
                    />
                    <div style={notificationTextStyle}>
                      <strong>Sabir</strong> sent you a new message
                      <div style={notificationTimeStyle}>15 mins ago</div>
                    </div>
                  </div>

                  <div className="filter-option cursor-pointer p-2 d-flex align-items-center" style={notificationItemStyle}>
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar1.png"
                      alt="avatar"
                      style={avatarStyle}
                    />
                    <div style={notificationTextStyle}>
                      <strong>Sabir</strong> sent you a new message
                      <div style={notificationTimeStyle}>15 mins ago</div>
                    </div>
                  </div>

                  <div className="filter-option cursor-pointer p-2 d-flex align-items-center" style={notificationItemStyle}>
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar1.png"
                      alt="avatar"
                      style={avatarStyle}
                    />
                    <div style={notificationTextStyle}>
                      <strong>Sabir</strong> sent you a new message
                      <div style={notificationTimeStyle}>15 mins ago</div>
                    </div>
                  </div>

                  <div className="filter-option cursor-pointer p-2 d-flex align-items-center" style={notificationItemStyle}>
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar1.png"
                      alt="avatar"
                      style={avatarStyle}
                    />
                    <div style={notificationTextStyle}>
                      <strong>Sabir</strong> sent you a new message
                      <div style={notificationTimeStyle}>15 mins ago</div>
                    </div>
                  </div>

                  <div className="filter-option cursor-pointer p-2 d-flex align-items-center" style={notificationItemStyle}>
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar1.png"
                      alt="avatar"
                      style={avatarStyle}
                    />
                    <div style={notificationTextStyle}>
                      <strong>Sabir</strong> sent you a new message
                      <div style={notificationTimeStyle}>15 mins ago</div>
                    </div>
                  </div>

                  <div className="filter-option cursor-pointer p-2 d-flex align-items-center" style={notificationItemStyle}>
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar1.png"
                      alt="avatar"
                      style={avatarStyle}
                    />
                    <div style={notificationTextStyle}>
                      <strong>Sabir</strong> sent you a new message
                      <div style={notificationTimeStyle}>15 mins ago</div>
                    </div>
                  </div>

                  <div className="filter-option cursor-pointer p-2 d-flex align-items-center" style={notificationItemStyle}>
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar1.png"
                      alt="avatar"
                      style={avatarStyle}
                    />
                    <div style={notificationTextStyle}>
                      <strong>Sabir</strong> sent you a new message
                      <div style={notificationTimeStyle}>15 mins ago</div>
                    </div>
                  </div>
                </div>

              </div>
            )}


            {/* Profile Info */}
            <div className="profile-info">
              <img
                src={
                  adminData.profilePicture
                    ? `data:image/jpeg;base64,${adminData.profilePicture}`
                    : 'https://bootdey.com/img/Content/avatar/avatar1.png'
                }
                style={{
                  cursor: 'pointer',
                  borderColor: '#24D366',
                  borderWidth: 1,
                  borderRadius: '50%',
                }}
                height={30}
                width={30}
                onClick={() => {
                  setScreenName('Profile');
                  changeScreen('7', 'Profile');
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="right-screen"
        >
          <p>{right}</p>
        </div>
      </div>
      <ContactFormModal6 isOpen={showModal} onClose={handleCloseModal} />
      <ContactFormModal2 isOpen={showContactModal2} onClose={closeContactModal2} />
      <ContactFormModal isOpen={showContactModal} onClose={closeContactModal} />
      <Modal
        isOpen={isPopupOpen}
        onRequestClose={handleCancelLogout}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
            backgroundColor: '#F5F7FA', // Light background
            color: '#2E3A59', // Dark text color
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
            textAlign: 'center',
            maxWidth: '350px', // Limiting width for better design
            width: '100%',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)', // Lighter overlay
          },
        }}
      >
        <h2 style={{ fontSize: '18px', marginBottom: '15px', fontWeight: '500', color: '#2E3A59' }}>
          Confirm logout
        </h2>
        <p style={{ fontSize: '14px', color: '#2E3A59' }}>
          Are you sure you want to log out?
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button
            onClick={handleCancelLogout}
            style={{
              padding: '10px 20px',
              marginRight: '10px',
              borderRadius: '20px',
              backgroundColor: '#ffffff', // White background for cancel button
              color: '#2E3A59', // Dark text color for cancel button
              border: '1px solid #E0E4EB', // Border for cancel button
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: '500',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f2f5'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#ffffff'}
          >
            Cancel
          </button>
          <button
            onClick={handleConfirmLogout}
            style={{
              padding: '10px 20px',
              borderRadius: '20px', // Rounded buttons for a modern look
              backgroundColor: '#A66CFF', // Blue color for OK button
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: '500',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#4553b5'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#A66CFF'}
          >
            OK
          </button>
        </div>
      </Modal>


    </div>
  );
};



export default Dashboard;
