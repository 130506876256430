// flowContext.js
import React, { createContext, useContext, useState } from 'react';

const FlowContext = createContext();

export const FlowProvider = ({ children }) => {
  const [flow, setFlow] = useState(null);
  const [onSelectionChange, setOnSelectionChange] = useState(null);

  return (
    <FlowContext.Provider value={{ flow, setFlow ,onSelectionChange, setOnSelectionChange}}>
      {children}
    </FlowContext.Provider>
  );
};

export const useFlow = () => useContext(FlowContext);
