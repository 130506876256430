import React, { useEffect, useState } from "react";
import styles from "../Style/tableSytle.module.css";
import { FaTrash } from "react-icons/fa";
import { BsX } from "react-icons/bs";
import { ApiEndPoint } from "../pages/components/utils/utlis";
import { FadeLoader } from 'react-spinners';
import axios from "axios";







const DataTable = () => {
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [errortemplate, setErrorTemplate] = useState('');
  const [errortempuppercase, setErrorTempUppercase] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorbody, setErrorBody] = useState("");
  const [errorfooter, setErrorFooter] = useState("");
  const [headerInputtemp, setHeaderInputTemp] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [templateData, setTemplateData] = useState({
    template_name: "",
    body: "",
    footer: "",
    buttonsData: [], // Initialize as an empty array
    selectedButtonType: "QUICK_REPLY",
  });
  


  const handleRejectedClick = (item) => {
    setModalData(item); // Set data for modal view
  };

  const closeModal = () => {
    setModalData(null); // Close the modal
  };

  const handleDelete = async (val) => {
    console.log("Deleted Delete:", val);
    try {
      const remaining = messageTemplates.filter((value) => value.id !== val.id);
      setMessageTemplates(remaining); // Assuming you want to log the response data
      const response = await axios.delete(`${ApiEndPoint}/message-templates/`, {
        data: {
          id: val.id,
          name: val.name,
        },
      });
      console.log("Response:", response.data);
    } catch (error) {
      console.log("ERROR:", error);
    }
  };
  const changeStatus = (status) => {
    if (status === "APPROVED") {
      return <td className="text-success fw-bold">{status}</td>;
    }
    if (status === "REJECTED") {
      return <td className="text-danger fw-bold">{status}</td>;
    }
    if (status === "WARNING") {
      return <td className="text-danger fw-bold">{status}</td>;
    }
  };
  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getTemplate`);
      setMessageTemplates(response.data.data);
      console.log("Template Messages:", response.data);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };



  const handleSubmit = (event) => {
    event.preventDefault();
    // Your form submission logic here
  };



  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setTemplateData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };


  const validateTemplateName = () => {
    if (templateData.template_name.length === 0) {
      setErrorTemplate('Template name is required.');
    } else {
      setErrorTemplate('');
    }
    if (/[A-Z]/.test(templateData.template_name)) {
      setErrorTempUppercase('Uppercase letters are not allowed.');
    } else {
      setErrorTempUppercase('');
    }
  };


  const handleNumberOfButtonsChange = (e) => {
    const numberOfButtons = parseInt(e.target.value, 10);
    setTemplateData((prevData) => ({
      ...prevData,
      buttonsData: Array.from({ length: numberOfButtons }, (_, i) => ({
        text: prevData.buttonsData[i]?.text || "",
        url: prevData.buttonsData[i]?.url || "",
      })),
    }));
  };


  const handleButtonTypeChange = (e) => {
    setTemplateData((prevData) => ({
      ...prevData,
      selectedButtonType: e.target.value,
      buttonsData: prevData.buttonsData.map((button) => ({
        ...button,
        url: e.target.value === "URL" ? button.url || "http://" : "",
      })),
    }));
  };

  const handleButtonChange = (index, e) => {
    const { name, value } = e.target;
    setTemplateData((prevData) => {
      const buttonsData = [...prevData.buttonsData];
      buttonsData[index] = { ...buttonsData[index], [name]: value };
      return { ...prevData, buttonsData };
    });
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0]; // Get the selected file

    if (file) {
      // Check the file type
      if (file.type === "image/png" || file.type === "image/jpeg") {
        // File is valid
        const reader = new FileReader();

        reader.onload = () => {
          setTemplateData((prevData) => ({
            ...prevData,
            header_image: {
              file: file, // Store the actual file for backend upload
              preview: reader.result, // Store a preview URL for frontend display
            },
          }));
        };

        reader.readAsDataURL(file); // Convert file to a base64 string for preview
      } else {
        // Invalid file type
        alert("Only PNG and JPG files are allowed.");
      }
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "body" && value.length > 1024) {
      setErrorBody("Body text exceeds the limit of 1024 characters.");
    } else {
      setErrorBody("");
    }
    if (name === "footer" && value.length > 60) {
      setErrorFooter("Footer text exceeds the limit of 60 characters.");
    } else {
      setErrorFooter("");
    }
    setTemplateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div
      style={{
        maxHeight: "82vh",
        width: "auto",
        overflowY: "auto",
        height: "84vh",
        borderRadius: "0",
        backgroundColor: "#ffffff",
        margin: "0",
      }}
    >
      <table className="table table-borderless mb-0">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Status</th>
            <th scope="col">Language</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {!Array.isArray(messageTemplates) || messageTemplates.length === 0 ? (
            <tr>
              <td colSpan="4">No templates available.</td>
            </tr>
          ) : (
            messageTemplates.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{
                  item.status === "PENDING" ? (<button
                    className="btn btn-light text-dark"
                    style={{ backgroundColor: "lightyellow" }}
                    disabled
                  >
                    Pending
                  </button>) :  item.status === "REJECTED" ? (<button
                    className="btn btn-danger mx-2"
                    onClick={() => handleRejectedClick(item)}
                  >
                    Rejected
                  </button>) :

                    (<button className="btn btn-success">Approved</button>)
                }
                </td>
                <td>{item.language}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm px-3"
                    disabled={item.status==="APPROVED"||item.status==="REJECTED"?false:true}
                    onClick={() => handleDelete(item)}
                  >
                    <i className="fa fa-times">
                      <BsX className="fs-5" />
                    </i>
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Modal for Rejected Button */}
      {modalData && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "110%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: '1000',
          }}
        >


          <div className="container-fluid d-flex" style={{ height: "100vh" }}>
            {/* Left Side: Template Editor */}
            <div className="col-8" style={{ overflowY: "auto", padding: "20px" }}>
              <form
                className="Theme3 col-12 pb-0 position-relative d-flex flex-column gap-3 rounded p-4"
                style={{
                  height: "100%",
                  backgroundColor: "#f8f9fa",
                  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                }}
                onSubmit={handleSubmit}
              >
                <div style={{ maxHeight: "calc(100vh - 140px)", overflowY: "auto" }}>
                  <p className="fs-4 fw-bolder">Edit Message Template</p>
                  <div className="pt-4">
                    <div className="d-flex justify-content-between">
                      <p className="fw-bold" style={{ marginBottom: "10px" }}>Template Name</p>
                      <p>{templateData.template_name.length}/60</p>
                    </div>
                    <input
                      type="text"
                      name="template_name"
                      className="p-2 messagetemplate inputtemp w-100"
                      placeholder="template_name"
                      onChange={handleChange}
                      required
                      style={{ marginTop: "-0.6rem", marginBottom: "-1px" }}
                    />
                    <label style={{ color: "goldenrod" }}>{errortemplate}</label>
                    <label style={{ color: "goldenrod" }}>{errortempuppercase}</label>
                  </div>
                  <label className="mt-3 fw-bold" style={{ marginBottom: "3px", marginTop: "10px" }}>Language</label>

                  <div style={{ marginTop: "-0.2rem" }}>
                    <select
                      class="form-select"
                      className="p-2 messagetemplate inputtemp "
                      onChange={handleChange}
                      name="language"
                      style={{ marginBottom: "10px" }}
                      required
                    >
                      <option selected>Select Language</option>
                      <option value="en_US">English-US</option>
                    </select>
                  </div>

                  <div>
                    <div>
                      <span className="fw-bold" style={{ marginBottom: "10px" }}>Header</span> (Optional)
                      <p style={{ marginBottom: "10px" }}>Add title that you want to show in message header</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="fw-bold" style={{ marginBottom: "10px" }}>Header Type</p>
                        <div style={{ marginTop: "-0.6rem" }}>
                          <select
                            className="p-2 messagetemplate inputtemp"
                            name="header_format"
                            style={{ width: "20rem" }}
                            onChange={handleChange}
                          >
                            <option value="">None</option>
                            <option value="text">Text</option>
                            <option value="image">Image</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {templateData.header_format === "text" && (
                      <div style={{ marginTop: "1rem" }}>
                        <label className="fw-bold">Header Text</label>
                        <input
                          type="text"
                          className="p-2 messagetemplate inputtemp w-100"
                          name="header_text"
                          onChange={handleChange}
                          placeholder="Enter header text"
                          value={templateData.header_text || ""}
                        />
                      </div>
                    )}

                    {templateData.header_format === "image" && (
                      <div style={{ marginTop: "1rem" }}>
                        <label className="fw-bold" style={{ marginBottom: "2px" }}>Upload Header Image</label>
                        <input
                          type="file"
                          accept=".png, .jpg"
                          className="form-control"
                          onChange={handleImageUpload}
                        />
                        {imagePreview && (
                          <div style={{ marginTop: "1rem" }}>
                            <p className="fw-bold">Preview:</p>
                            <img
                              src={imagePreview}
                              alt="Header Preview"
                              style={{ width: "100%", maxWidth: "200px", border: "1px solid #ccc" }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div>
                    <label className="fw-bold" style={{ marginBottom: "5px", marginTop: "10px" }}>Body</label>
                    <p style={{ marginBottom: "5px" }}>Enter the text for your message</p>
                    <div className="d-flex justify-content-between">
                      <p className="fw-bold" style={{ marginBottom: "10px", marginTop: "5px" }}>Body Content</p>
                      <p>{templateData.body.length}/1024</p>
                    </div>
                    <div style={{ marginTop: "-0.6rem" }}>
                      <textarea
                        placeholder="Enter text here"
                        rows="5"
                        name="body"
                        onChange={handleChange}
                        className="p-2 rounded messagetemplate inputtemp w-100"
                        required
                      />
                      <label style={{ color: "goldenrod" }}>{errorbody}</label>
                    </div>
                  </div>

                  <div>
                    <label>{templateData.buttonsData.text}</label>

                    <label>
                      <span className="fw-bold">Footer</span> (Optional)
                    </label>
                    <p style={{ marginBottom: "10px" }}>
                      Add short type of text that shown bottom of your message template
                    </p>
                    <div className=" d-flex justify-content-between">
                      <p className="fw-bold" style={{ marginBottom: "10px" }}>Footer Text</p>
                      <p>{templateData.footer.length}/60</p>
                    </div>
                    <div style={{ marginTop: "-0.6rem", marginBottom: "10px" }}>
                      <input
                        type="text"
                        className="p-2 messagetemplate inputtemp w-100"
                        name="footer"
                        onChange={handleChange}
                        placeholder="Sent via AWS CRM.com"
                        value={templateData.footer}
                      />
                      <label style={{ color: "goldenrod" }}>{errorfooter}</label>
                    </div>
                  </div>

                  {/* Button */}
                  <div style={{ marginBottom: "10px" }}>
                    <span className="fw-bold">Button</span> (Optional)
                    <p>
                      Create upto 2 buttons that let customer respond to your message or
                      take action
                    </p>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{ width: "56%" }}
                  >
                    <div className="mt-0">
                      {/* Radio button */}
                      {(templateData.selectedButtonType === "QUICK_REPLY" ||
                        templateData.selectedButtonType === "URL") && (
                          <div className="" >
                            <p className="fw-bold" style={{ marginBottom: "10px" }}>Number of Buttons</p>
                            <div
                              className="gap-4 d-flex "
                              style={{ marginTop: "-0.6rem" }}
                            >
                              <div>
                                <input
                                  id="1"
                                  type="radio"
                                  name="numberOfButtons"
                                  value={1}
                                  onChange={handleNumberOfButtonsChange}
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginTop: "0.3rem",
                                    marginRight: "0.2rem",
                                  }}
                                />
                                <label> One</label>
                              </div>
                              <div>
                                <input
                                  id="2"
                                  type="radio"
                                  name="numberOfButtons"
                                  value={2}
                                  onChange={handleNumberOfButtonsChange}
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginTop: "0.3rem",
                                    marginRight: "0.2rem",
                                  }}
                                />
                                <label>Two</label>
                              </div>
                              <div>
                                <input
                                  id="3"
                                  type="radio"
                                  name="numberOfButtons"
                                  value={3}
                                  onChange={handleNumberOfButtonsChange}
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginTop: "0.3rem",
                                    marginRight: "0.2rem",
                                  }}
                                />
                                <label>Three</label>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                    <div>
                      <div>
                        <label>
                          <p className="fw-bold" style={{ marginBottom: "10px" }}>Button Type</p>

                          <select
                            onChange={handleButtonTypeChange}
                            className="p-2 messagetemplate inputtemp"
                            style={{ width: "20rem", marginTop: "-0.6rem" }}
                          >
                            <option value="QUICK_REPLY">Quick Reply</option>
                            <option value="URL">URL</option>
                          </select>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* Inputtemp fields for buttons */}
                  {templateData.buttonsData.map((button, index) => (
                    <div key={index} className="d-flex gap-5">
                      <label>
                        <p className="fw-bold" style={{ marginBottom: "10px", marginTop: "5px" }}> Button {index + 1} Text:</p>
                        <div style={{ marginTop: "-0.6rem" }}>
                          <input
                            id={index}
                            type="text"
                            value={button.text}
                            onChange={(e) => handleButtonChange(index, e)} // Pass the index along with the event
                            className="p-2 messagetemplate inputtemp"
                            style={{ width: "20rem" }}
                            name="text" // Specify the name of the inputtemp field as "text"
                          />
                        </div>
                      </label>
                      {templateData.selectedButtonType === "URL" && (
                        <label style={{ marginTop: "10px" }}>
                          <p className="fw-bold" style={{ marginBottom: "10px", marginLeft: "35px" }}>Button {index + 1} URL:</p>
                          <div style={{ marginTop: "-0.6rem", marginLeft: "35px" }}>
                            <input
                              type="text"
                              value={button.url}
                              placeholder="http://"
                              style={{ width: "20rem" }}
                              onChange={(e) => handleButtonChange(index, e)} // Pass the index along with the event
                              className="p-2 messagetemplate inputtemp"
                              name="url" // Specify the name of the inputtemp field as "url"
                            />
                          </div>
                        </label>
                      )}
                    </div>


                  ))}

                  <button
                    type="submit"
                    className="p-2 inputtemp fw-bold"
                    style={{
                      background: "goldenrod",
                      color: "aliceblue",
                      border: "none",
                      width: "100px",
                      marginLeft: "45%",
                      marginTop: "20px",
                    }}
                  >
                    Next
                  </button>
                  <span
                    className="text-center"
                    style={{ position: "relative", top: "-3.5rem" }}
                  >
                    <FadeLoader color="white" loading={loading} />
                  </span>

                  {/* Add the rest of the form elements here from Part 1 */}
                </div>

                {/* <button
                  type="submit"
                  className="p-2 inputtemp fw-bold"
                  style={{
                    background: "goldenrod",
                    color: "aliceblue",
                    border: "none",
                    width:"100px",
                    marginRight:"30px",
                  }}
                >
                  Next
                </button>
                <span
                  className="text-center"
                  style={{ position: "relative", top: "-3.5rem" }}
                >
                  <FadeLoader color="white" loading={loading} />
                </span> */}
              </form>
            </div>

            {/* Right Side: Mobile Template View */}

            <div
              className="col-4 d-flex align-items-center justify-content-center"
              style={{
                height: "95.5vh",
                backgroundColor: "#f0f0f0",
                padding: "20px",
                marginTop: "21px",
                borderRadius: "5px",
                width: "53vh",
              }}
            >
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "12px",
                  width: "50%",
                  maxWidth: "300px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #ddd",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#000",
                    borderRadius: "12px 12px 0 0",
                    height: "40px",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "60px",
                      height: "5px",
                      backgroundColor: "#ccc",
                      borderRadius: "5px",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px 10px",
                  }}
                >
                  <h4 style={{ marginBottom: "16px", fontSize: "18px" }}>Template Preview</h4>
                  {templateData.header_format === "text" && (

                    <p style={{ marginBottom: "16px" }}>
                      <strong>Header Text:</strong> {templateData.header_text}
                    </p>

                  )}
                  {templateData.header_format === "image" &&  (
                    <div>
                      <strong>Header Image:</strong>
                      <img
                        src={'.../public/Logo.png'}
                        //alt="Header Preview"
                        style={{
                          marginTop: "10px",
                          maxWidth: "100%",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  )}
                  <button
                    className="btn btn-secondary"
                    style={{
                      padding: "10px 20px",
                      fontSize: "16px",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "none",
                      borderRadius: "8px",
                    }}
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
                <div
                  style={{
                    backgroundColor: "#000",
                    borderRadius: "0 0 12px 12px",
                    height: "30px",
                    width: "100%",
                  }}
                ></div>
              </div>
            </div>
          </div>





          {/* <div
              className="col-4 d-flex align-items-center justify-content-center"
              style={{
                height: "95.5vh",
                backgroundColor: "#f0f0f0",
                padding: "20px",
                marginTop: "21px",
                borderRadius: "5px",
                width: "53vh",
              }}
            >
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "12px",
                  width: "50%",
                  maxWidth: "300px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #ddd",

                }}
              >
                <div
                  style={{
                    backgroundColor: "#000",
                    borderRadius: "12px 12px 0 0",
                    height: "40px",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "60px",
                      height: "5px",
                      backgroundColor: "#ccc",
                      borderRadius: "5px",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px 10px",
                  }}
                >
                  <h4 style={{ marginBottom: "16px", fontSize: "18px" }}>Template Details</h4>
                  <p style={{ marginBottom: "8px" }}>
                    <strong>ID:</strong> {modalData.id}
                  </p>
                  <p style={{ marginBottom: "8px" }}>
                    <strong>Language:</strong> {modalData.language}
                  </p>
                  <p style={{ marginBottom: "16px" }}>
                    <strong>Message:</strong> {modalData.message}
                  </p>
                  <button
                    className="btn btn-secondary"
                    style={{
                      padding: "10px 20px",
                      fontSize: "16px",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "none",
                      borderRadius: "8px",
                    }}
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
                <div
                  style={{
                    backgroundColor: "#000",
                    borderRadius: "0 0 12px 12px",
                    height: "30px",
                    width: "100%",
                  }}
                ></div>
              </div>
            </div> */}
        </div>



      )
      }
    </div >
  );
}


export default DataTable;
