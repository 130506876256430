import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import style from '../Style/CSS.module.css';
import loginStyle from "../pages/ContactForm/contact.module.css";

import { MdEmail } from 'react-icons/md';
import { RiLockPasswordFill } from 'react-icons/ri';
import defaultProfilePic from "../../src/pages/components/assets/icons/person.png";
import { FaUser } from "react-icons/fa";
import { BsMailbox, BsPerson } from 'react-icons/bs';
const Admin = ({ isOpen, onClose }) => {
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [profilePicBase64, setProfilePicBase64] = useState("");
  
  // Function to handle changes in form inputs
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Extract base64 string without data URL prefix
        setProfilePicBase64(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  
  // Function to handle form submission

  // Function to handle modal closure
  const handleClose = () => {
    //setShowAdminPopup(false);
  };
const handleSubmit=()=>{

}
  return (
    <div>
      {/* Modal for admin form */}
      <Modal show={isOpen}
      onHide={onClose} centered>
        <Modal.Header closeButton  ></Modal.Header>
        <Modal.Body >
          {/* Form for adding admin */}
          <form onSubmit={handleSubmit} 
           className={` Textcolor`}>
            {/* Profile Picture Section */}
<div className='text-center d-flex flex-column justify-content-center'> {/* Centering the content */}
  {/*Profile section */}
  <div className="mb-1 text-center avatar-container">
          <label htmlFor="profilePicInput">
            <img
              src={
                profilePicBase64
                  ? `data:image/jpeg;base64,${profilePicBase64}`
                  : defaultProfilePic
              }
              alt="Profile"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
                minWidth: "120px",
                minHeight: "120px",
                borderRadius: "50%",
                border: "1px solid goldenrod",
                boxShadow: "goldenrod 0px 2px 5px",
              }}
              className="avatar-img"
            />
          </label>
          <input
            type="file"
            accept="image/*"
            id="profilePicInput"
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </div>
        <div className="text-center">
          <label className="form-label font-weight-bold">
            <h6>Profile Photo</h6>
          </label>
        </div>
        
  </div>
{/* Email Input */}
<div className={" text-start"}>
          <label className={"form-label font-weight-bold"}>
            <h6>Username</h6>
          </label>
          <div className={"input-group  mb-3 bg-soft-light rounded-2"}>
            <span className={loginStyle.icons + " input-group-text"}>
              <BsPerson />
            </span>
            <input
              className={
                loginStyle["form-control-1"] + " form-control-md form-control"
              }
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              id="Name"
              name="username"
              placeholder="Enter Client Name"
              type="text"
              title="Please Enter Client Name"
              required
            />
          </div>
        </div>
        <div className={"mb-1 text-start"}>
          <label className={"form-label font-weight-bold"}>
            <h6>Email</h6>
          </label>
          <div className={"input-group  mb-3 bg-soft-light rounded-2"}>
            <span className={loginStyle.icons + " input-group-text"}>
              <BsMailbox />
            </span>
            <input
              className={
                loginStyle["form-control-1"] + " form-control-md form-control"
              }
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="Email"
              name="email"
              placeholder="Enter Email"
              type="email"
              pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
              title="Enter a valid email address"
              required
            />
          </div>
        </div>
               {/* Password Input */}
               <div className={"mb-1 text-start"}>
          <label className={"form-label font-weight-bold"}>
            <h6>Password</h6>
          </label>
          <div className={"input-group  mb-3 bg-soft-light rounded-2"}>
            <span className={loginStyle.icons + " input-group-text"}>
              <RiLockPasswordFill />
            </span>
            <input
              className={
                loginStyle["form-control-1"] + " form-control-md form-control"
              }
              value={password}
              onChange={(e) => setpassword(e.target.value)}
              id="Password"
              name="password"
              placeholder="Enter Password"
              type="password"
              required
            />
          </div>
        </div>
       {/* Role Selection */}
               
              {/* Submit Button */}
              <button
            className={
              loginStyle["btn-color"] +
              " btn btn-primary d-block w-100 mb-1 mt-3"
            }
            type="submit"
          >
            Add User
          </button>
        
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Admin;
