// Example link in a footer or a menu

import React from "react";
import { Link } from "react-router-dom";

const Footer1 = () => {
  return (
    <footer>
      <p>
      <Link to="/PolicyPage" className="terms-link">
        Privacy Policy</Link>
      </p>
    </footer>
  );
};

export default Footer1;
