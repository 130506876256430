import React, { useState, useEffect, useRef } from "react";
import { BsPersonBadge, BsPersonDash } from "react-icons/bs";
import { FaPerson } from "react-icons/fa6";
import { Handle, Position, useReactFlow } from "reactflow";
import agent from '../../pages/components/assets/icons/agent (2).png'

function SwitchToAgent({ data, id, selected }) {
  const [input, setInput] = useState(data.label || "Switch to Agent");
  const { setNodes } = useReactFlow();
  const textareaRef = useRef(null);
  
  

  useEffect(() => {
    setInput(data.label || "Switch to Agent");
    
    
  }, [data]);

  useEffect(() => {
    autoResizeTextarea();
  }, [input]);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .custom-scrollbar::-webkit-scrollbar {
        width: 2px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);



 

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: { ...node.data, label: input },
          };
        }
        return node;
      })
    );
  }, [ input, setNodes, id]);

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 400)}px`;
    }
  };

  return (
   
    <div
  style={{
    background: "#075E54",
    display: "flex",
  

    border: "1px solid #075E54",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0.375rem",
   
    overflow: "hidden",
  }}
>
  {/* Replace with your SVG image */}
  <img
      src={agent}
      alt="Switch to Agent"
      style={{
        width: "120px",
        height: "120px",
        objectFit: "fit", // Ensures the image fits within the div without distortion
      }}
    />

  <Handle
        id="a"
        type="target"
        position={Position.Left}
        className="w-1 rounded-full bg-slate-500"
      />
</div>
   
);


     
     

}

export default SwitchToAgent;
