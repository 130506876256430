import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReactFlowInstance } from '../slice'; // Adjust the import path to your slice
import { ApiEndPoint } from '../../pages/components/utils/utlis';
import axios from 'axios';

export const fetchBotData = createAsyncThunk(
  'bot/fetchBotData',
  async (botId, thunkAPI) => {
    try {
      const response = await fetch(`${ApiEndPoint}/getBotById/${botId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      thunkAPI.dispatch(ReactFlowInstance(data));
      return data;
    } catch (error) {
      console.error('Error fetching bot data:', error);
      return thunkAPI.rejectWithValue({ error: 'Error fetching bot data' });
    }
  }
);

export const fetchGeneralData = createAsyncThunk(
  'general/fetchGeneralData',
  async (_, thunkAPI) => {
    try {
      const response = await fetch(`${ApiEndPoint}/getBotData`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching general data:', error);
      return thunkAPI.rejectWithValue({ error: 'Error fetching general data' });
    }
  }
);

export const fetchUsers = createAsyncThunk(
  'admin/data',
  async (userId, thunkAPI) => {
    try {
      const response = await axios.get(`${ApiEndPoint}/admins/${userId}`);
      return response.data; // axios returns `data` directly
    } catch (error) {
      console.error('Error fetching users data:', error);
      return thunkAPI.rejectWithValue({ error: 'Error fetching users data' });
    }
  }
);

export default { fetchBotData, fetchGeneralData, fetchUsers };
