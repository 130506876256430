import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { BsPersonFillAdd } from "react-icons/bs";
import { MdGroupAdd } from "react-icons/md";
import ExcelUploadPopup from "../ContactForm/dropzonComponent"; 
import Excel from '../components/images/simple excel sheet.png';
import ExcelB from '../components/images/BROAD CASTING EXCEL SHEET.png';
import ExcelUploadPopup2 from "../ContactForm/dropzonComponent2"; 
const ContactForm6 = ({ onClose }) => {
  const [showContactModal, setshowContactModal] = useState(false);
  const [group, setGroup] = useState(false);

  const closeContactModal = () => {
    setshowContactModal(false);
  };
  const openContactModal = () => {
    setshowContactModal(true);
    setGroup(false);
  };

  const closeContactModal5 = () => {
    setGroup(false);
  };
  const openContactModal5 = () => {
    setGroup(true);
    setshowContactModal(false);
  };
 
 
  return (
    <div>
      <form className="Theme3">
        <div className={" text-start d-flex flex-column gap-4"}>
          
          <div
            style={{ width: "36%" }}
            className="d-flex cursor-pointer justify-content-between "
            onClick={openContactModal}
          >
            <img
            src={Excel}
            height={25}
            width={25}
            />
            <label style={{ fontSize: 20, fontWeight: 400,cursor:'pointer' }}>New Contacts</label>
          </div>
          <div
           onClick={openContactModal5}
          style={{ width: "38%"}} className="d-flex cursor-pointer justify-content-between">
            <img
            src={ExcelB}
            height={25}
            width={25}
            />
            
            <label style={{ fontSize: 20, fontWeight: 400 ,cursor:'pointer' }}>New Broadcast</label>
          </div>
          
        </div>
      </form>
      
      <ExcelUploadPopup isOpen={showContactModal} onClose={closeContactModal} />
      <ExcelUploadPopup2 isOpen={group} onClose={closeContactModal5} />
    </div>
  );
};


const ContactFormModal6 = ({ isOpen, onClose }) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered
    >
      {" "}
      <Modal.Header closeButton>
        <Modal.Title>Bulk Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContactForm6 onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
};

export default ContactFormModal6;
export { ContactForm6 };
