import React, { useEffect} from "react";

import { Navigate, useNavigate } from "react-router-dom";
// import Home from "./pages/Home/Home";



import { AuthProvider, useAuth } from "./pages/Auth/AuthContext";


import Routee from "./Routes/Routee.jsx";
import { createFaviconWithBadge } from "./pages/chat/widgets/counterBadgeIcon.jsx";
import axios from "axios";
import { ApiEndPoint, decodeToken } from "./pages/components/utils/utlis.js";

const PrivateRoute = ({ element, ...rest }) => {
  const { token } = useAuth();
  return token ? element : <Navigate to="/" />;
};
const App = () => {
  const navigate = useNavigate();


  const fetchPendingMessagesCount = async () => {
    try {
      const jwtToken = sessionStorage.getItem("jwtToken");
      // Check if the token is expired
      if (jwtToken) {
        // console.log("TOKEN Type:",typeof jwtToken);
        const { isExpired } = decodeToken(jwtToken);
        if (isExpired) {
          sessionStorage.removeItem("jwtToken"); // Remove token from session storage
          navigate("/");
          createFaviconWithBadge(0); // Optionally, redirect the user to the login page or wherever appropriate
        } else {
          const response = await axios.get(`${ApiEndPoint}/pending-messages/count`); // Call the API endpoint
          if (response.data.success) {
            createFaviconWithBadge(response.data.totalPending>0?response.data.totalPending :0); // Ensure counter is 0 if no pending messages
          } else {
            createFaviconWithBadge(0); // Default to 0 if API call is not successful
          }
        }
      } else {
        createFaviconWithBadge(0); // Default to 0 if no JWT token
      }
    } catch (error) {
      console.error("Error fetching pending messages count:", error);
      createFaviconWithBadge(0); // Default to 0 in case of an error
    }
  };
  

  useEffect(() => {
    fetchPendingMessagesCount(); // Fetch count on component mount

    const intervalId = setInterval(() => {
      fetchPendingMessagesCount(); // Fetch count every 10 seconds
    }, 3000); // 10000 ms = 10 seconds

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  return (
    <div>
      <AuthProvider>
        <Routee />
      </AuthProvider>
    </div>
  );
};

export default App;

