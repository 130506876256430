import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Admin from "../Admin/Admin";
import Dashboard from "../Main page/Dashboard";
import Dashboard2 from "../Main page/Dashboard2";
import Sendmessage from "../Main page/Sendmessage";
import Messagetemplate from "../Main page/Messagetemplate";
import Broadcast from "../Main page/Broadcast";
import Temp_Build from "../Main page/Temp_Bulid";
import ChatUI from "../ChatUI/Chat";
import Chat from "../pages/chat/Chat";
import Login from "../pages/Auth/Loging";
import App from "../Chatbot/App";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import TermAndservics from "../pages/Term And Services/TermAndSevics";
import PolicyPage from "../pages/Term And Services/PolicyPage";
import { useAuth } from "../pages/Auth/AuthContext";
import { decodeToken } from "../pages/components/utils/utlis";



const PrivateRoute = ({ element }) => {
  const { token: authToken } = useAuth();
  const jwtToken = sessionStorage.getItem("jwtToken");
  
  let decodedToken = null;
  let isExpired = true; // Default to true, assuming token is expired if not decoded

  // Check if the JWT token exists
  if (jwtToken) {
    const decoded = decodeToken(jwtToken);
    decodedToken = decoded.decodedToken || null;
    isExpired = decoded.isExpired; // Directly use the isExpired value from decodeToken
  }

  // Determine the final token value, prioritizing the auth token from context
  const token = authToken || decodedToken;

  // Return the element if token is valid and not expired; otherwise, redirect to login
  return token && !isExpired ? element : <Navigate to="/" />;
};





const Routee = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/ForgetPassword" element={<ForgetPassword />} />
      <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
      <Route path="/dashboard2" element={<PrivateRoute element={<Dashboard2 />} />} />
      <Route path="/contacts" element={<PrivateRoute element={<ChatUI />} />} />
      <Route path="/chatbot" element={<PrivateRoute element={<App />} />} />
      <Route path="/admin" element={<PrivateRoute element={<Admin />} />} />
      <Route path="/sendmessage" element={<PrivateRoute element={<Sendmessage />} />} />
      <Route path="/messagetemplate" element={<PrivateRoute element={<Messagetemplate />} />} />
      <Route path="/chat" element={<PrivateRoute element={<Chat />} />} />
      <Route path="/broadcast" element={<PrivateRoute element={<Broadcast />} />} />
      <Route path="/Perview" element={<PrivateRoute element={<Temp_Build />} />} />
      <Route path="/TermAndservics" element={<TermAndservics />} />
      <Route path="/PolicyPage" element={<PolicyPage />} />
    </Routes>
  );
};

export default Routee;
