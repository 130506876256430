import React, { createContext, useContext, useState } from "react";

// Create a new context for authentication
const AuthContext = createContext();

// AuthProvider component to manage authentication state
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [role, setRole] = useState(null);

  const login = (newToken, newUserId, userName,role) => {
    setToken(newToken);
    sessionStorage.setItem("jwtToken", newToken);
    setUserId(newUserId);
    setUserName(userName);
    setRole(role);
  };

  const logout = () => {
    setToken(null);
    sessionStorage.removeItem("jwtToken");
    setUserId(null);
    setUserName(null);
    setRole(null);
  };

  return (
    <AuthContext.Provider value={{ token, userId, userName,role, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access authentication context
export const useAuth = () => useContext(AuthContext);
