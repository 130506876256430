import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <p>
      <Link to="/TermAndservics" className="terms-link">
      Terms of Service
    </Link>
      </p>
    </footer>
  );
};

export default Footer;
