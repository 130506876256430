import React, { useCallback, useEffect, useState } from "react";
import "../Chat.module.css";
import Video from '../../components/images/video call.png';
import Voice from '../../components/images/dialer.png';
import OPT from '../../components/images/options.png';
import { ApiEndPoint } from "../../components/utils/utlis";
import axios from "axios";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";import { ChromePicker } from 'react-color';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

const dropdownData = {
  firstStage: ["Option 1", "Option 2", "Option 3"],
  secondStage: ["Option A", "Option B", "Option C"]
};


function SheetDropdown({ stageId, stageTitle, dropdownLabels, clientId,triggerUpdate }) {
  console.log("CLIENT ID CHECK:",clientId);
  const [showSheet, setShowSheet] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(`Select ${stageTitle}`);
  const [selectedColor, setSelectedColor] = useState('#000000');
  const [labels, setLabels] = useState(dropdownLabels);

  const [selectedLabels, setSelectedLabels] = useState({});
  const [editingLabel, setEditingLabel] = useState(null);
  const [newLabelText, setNewLabelText] = useState('');
  const [newLabelColor, setNewLabelColor] = useState('#000000');
  const [isAdding, setIsAdding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

// Function to assign a label to a client
const assignLabelToClient = async (clientId, stageId, labelId) => {

  try {
    const labelAssignmentData = {
      stageId: stageId,
      labelId: labelId
    };
    const response = await axios.post(`${ApiEndPoint}/client/${clientId}/label`, labelAssignmentData);
    // Handle response if necessary
    console.log('Label assigned to client:', response.data);
    triggerUpdate();
  } catch (error) {
    console.error("Error assigning label to client:", error);
  }
};
// Handle label selection for a specific stage
const handleLabelSelect = async (labelId, labelText, labelColor) => {
  setSelectedColor(labelColor);
  setSelectedLabels((prevLabels) => ({
    ...prevLabels,
    [stageId]: { labelText, labelColor },
  }));

  setShowSheet(false);

  try {
    await assignLabelToClient(clientId, stageId, labelId);

    // Trigger the parent update
    triggerUpdate();
  } catch (error) {
    console.error('Error assigning label:', error);
  }
};


useEffect(() => {
  const fetchAssignedLabel = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${ApiEndPoint}/client/${clientId}/labels`
      );

      // Find the label corresponding to the current stageId
      const assignedLabel = response.data.find(
        (label) => label.stage_id === stageId // use stage_id from API response
      );
      console.log("assignLabels:", assignedLabel);

      // If the assigned label exists, update the state
      if (assignedLabel) {
        setSelectedLabels((prevLabels) => ({
          ...prevLabels,
          [stageId]: {
            labelText: assignedLabel.label_text,  // match label_text
            labelColor: assignedLabel.label_color, // match label_color
          },
        }));
        setSelectedLabel(assignedLabel.label_text);
        setSelectedColor(assignedLabel.label_color);
        
      } else {
        // If no label found for the stage, set the default label for that client and stage
        setSelectedLabel("Default Label");
        setSelectedColor("#0000FF"); // Default color (blue)
        setSelectedLabels((prevLabels) => ({
          ...prevLabels,
          [stageId]: {
            labelText: 'Default Label', // Default label text
            labelColor: '#818588', // Default label color (blue, for example)
          },
        }));

       
      }
    } catch (err) {
      setError("Failed to fetch assigned label");
    } finally {
      setLoading(false);
    }
  };

  fetchAssignedLabel();
}, [clientId, stageId,selectedColor,newLabelColor,]);




  const handleEditLabel = (labelId, labelText, labelColor) => {
    setEditingLabel({ id: labelId, label: labelText, color: labelColor });

  };

  const handleSaveEdit = async () => {
    if (!editingLabel) return;
  console.log("stage ID:",stageId);
    try {
      const response = await axios.put(`${ApiEndPoint}/label/${editingLabel.id}`, {
        labelText: editingLabel.label,
        labelColor: editingLabel.color,
        stageId: stageId, // Pass stageId dynamically for backend use
      });

      if (response.status === 200) {
        setLabels((prevLabels) =>
          prevLabels.map((item) =>
            item.labelId === editingLabel.id
              ? { ...item, labelText: editingLabel.label, labelColor: editingLabel.color }
              : item
          )
        );
       
        setSelectedLabel(editingLabel.label);
        setSelectedColor(editingLabel.color,); // Default color (blue)
        setEditingLabel(null);
        triggerUpdate();
      }
     
    } catch (error) {
      console.error('Error updating label:', error);
    }
  };

  const handleAddLabel = async () => {
    setIsAdding(false);
    if (!newLabelText.trim()) {
      alert('Label text cannot be empty');
      return;
    }

    try {
      const response = await axios.post(`${ApiEndPoint}/label`, {
        stageId: stageId, // Pass stageId dynamically for new label creation
        labelText: newLabelText,
        labelColor: newLabelColor,
      });

      if (response.status === 201) {
        const newLabel = {
          labelId: response.data.labelId, // Assuming the API returns the new label's ID
          labelText: newLabelText,
          labelColor: newLabelColor,
        };
        setLabels((prevLabels) => [...prevLabels, newLabel]);
        setNewLabelText('');
        setNewLabelColor('#000000');
        setIsAdding(false);
      }
      triggerUpdate();
    } catch (error) {
      console.error('Error adding label:', error);
    }
  };
  const handleDeleteLabel = async (labelId) => {
    try {
      await axios.delete(`${ApiEndPoint}/label/${labelId}`);
      setLabels(prev => {
        const updatedLabels = { ...prev.selectedLabels };
        delete updatedLabels[stageId];
        return {
          ...prev,
          selectedLabels: updatedLabels,
        };
      });
      triggerUpdate();
    } catch (error) {
      console.error('Error deleting label:', error);
    }
  };
  const maxRows = 6; // Maximum number of rows
  const labelsPerRow = 4; 

  return (
    <div>
      {/* Button to Open the Sheet */}
      <Button
        variant="secondary"
        className="m-1 fs-12 justify-content-start"
        onClick={() => setShowSheet(true)}
        style={{
          minWidth: '100px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 'calc(20% - 8px)',
          backgroundColor: selectedColor,
          borderColor: selectedColor,
          color: '#fff',
        }}
      >
        {selectedLabel||"Select Label"}
      </Button>

      {/* Modal Sheet */}
      <Modal
        show={showSheet}
        onHide={() => setShowSheet(false)}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '16px' }}>{stageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="d-flex flex-wrap"
            style={{
              overflowY: 'auto',
              overflowX: 'auto',
              maxHeight: `${maxRows * 60}px`,
              whiteSpace: 'nowrap',
            }}
          >
            {dropdownLabels.map((label) => (
              <div
                key={label.labelId}
                className="position-relative m-1"
                style={{
                  flex: `0 0 ${100 / labelsPerRow}%`,
                  minWidth: '150px',
                  maxWidth: 'calc(25% - 8px)',
                }}
              >
                <Button
                  variant="outline-primary"
                  className="fs-12 justify-content-start"
                  style={{
                    width: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    backgroundColor: label.labelColor,
                    borderColor: label.labelColor,
                    color: '#fff',
                    fontSize: '12px',
                    textAlign: 'center',
                    height:"50px",
                    maxHeight:"50px",
                    minHeight:"50px"
                  }}
                  onClick={() =>
                    handleLabelSelect(label.labelId, label.labelText, label.labelColor)
                  }
                >
                  {label.labelText}
                
                </Button>
                <Button
                  variant="link"
                  className="position-absolute top-0 end-0 p-0"
                  style={{ fontSize: '14px', color: '#000' }}
                  onClick={() =>
                    handleEditLabel(label.labelId, label.labelText, label.labelColor)
                  }
                >
                   <FontAwesomeIcon icon={faPen}  color="white" style={{
                    marginRight:"5px"
                   }}/>
                </Button>
                <Button
                  variant="link"
                  className="position-absolute bottom-0 end-0 p-0"
                  style={{ fontSize: '14px', color: 'red' }}
                  onClick={() => handleDeleteLabel(label.labelId)}
                >
                  <FontAwesomeIcon icon={faTrash}  color="white" style={{
                    marginRight:"5px"
                   }}/>
                </Button>
              </div>
            ))}
          </div>
          <div className="mt-3 d-flex justify-content-center align-items-center">
            <Button variant="success" onClick={() => setIsAdding(true)}
               style={{ display: 'flex', alignItems: 'center',
                backgroundColor: '#A66CFF',
                transition: 'background-color 0.3s ease', // Smooth transition
                outline: 'none', // Remove outline
                border: 'none', // Remove border
                }}
                onMouseEnter={(e) => e.target.style.backgroundColor = '#FF7640'} // On hover change background to orange
                onMouseLeave={(e) => e.target.style.backgroundColor = '#A66CFF'} // On leave, reset to original color // Align text and icon
              >
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '8px' }} /> {/* Add icon */}   Add Label
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Editing Modal */}
      {editingLabel && (
        <Modal show={true} onHide={() => setEditingLabel(null)} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Label</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editLabelText">
                <Form.Label>Label Text</Form.Label>
                <Form.Control
                  type="text"
                  value={editingLabel.label}
                  onChange={(e) =>
                    setEditingLabel((prev) => ({ ...prev, label: e.target.value }))
                  }
                />
              </Form.Group>
              <Form.Group controlId="editLabelColor" className="mt-3">
                <Form.Label>Label Color</Form.Label>
                <div className="color-picker-wrapper" style={{ display: "flex", justifyContent: "center" }}>
                <ChromePicker
                  color={editingLabel.color}
                  onChangeComplete={(color) =>
                    setEditingLabel((prev) => ({ ...prev, color: color.hex }))
                  }
                />
              </div>
              </Form.Group>
              <div className="mt-3 d-flex justify-content-center align-items-center">
              <Button variant="primary" className="mt-3" onClick={handleSaveEdit}  style={{
      display: 'flex', 
      alignItems: 'center',
      backgroundColor: '#A66CFF',
      border: 'none', // Remove border
      transition: 'background-color 0.3s ease', // Smooth transition
      outline: 'none', // Remove outline
    }}
    onMouseEnter={(e) => e.target.style.backgroundColor = '#FF7640'} // On hover change background to orange
    onMouseLeave={(e) => e.target.style.backgroundColor = '#A66CFF'} // On leave, reset to original color
  >
                Save
              </Button>
              </div>
              
            </Form>
          </Modal.Body>
        </Modal>
      )}

      {/* Adding Modal */}
      {isAdding && (
  <Modal show={true} onHide={() => setIsAdding(false)} backdrop="static" centered>
    <Modal.Header closeButton>
      <Modal.Title>Add Label</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        {/* Label Text Input */}
        <Form.Group controlId="newLabelText">
          <Form.Label>Label Text</Form.Label>
          <Form.Control
            type="text"
            value={newLabelText}
            onChange={(e) => setNewLabelText(e.target.value)}
          />
        </Form.Group>

        {/* Color Picker */}
        <Form.Group controlId="newLabelColor" className="mt-3">
          <Form.Label>Label Color</Form.Label>
          <div className="color-picker-wrapper" style={{ display: "flex", justifyContent: "center" }}>
            <ChromePicker
              color={newLabelColor}
              onChangeComplete={(color) => setNewLabelColor(color.hex)}
            
            />
          </div>
        </Form.Group>

        {/* Add Button */}
        <div className="mt-3 d-flex justify-content-center align-items-center">
          <Button
            variant="success"
            className="mt-3"
            onClick={handleAddLabel}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#A66CFF",
              border: "none",
              transition: "background-color 0.3s ease",
              outline: "none",
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#FF7640")} // Hover background color
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#A66CFF")} // Reset on mouse leave
          >
            Add
          </Button>
        </div>
      </Form>
    </Modal.Body>
  </Modal>
)}

    </div>
  );
}






export default function ChatHeader({clientId, name, phone, profilePic, color_code,triggerEffect }) {
  const [isHumanActive, setIsHumanActive] = useState(false);
  const [isClientSessionExpired, setClientSession]= useState(false);
  const [selectedFirstStage, setSelectedFirstStage] = useState('Select First Stage');
  const [selectedSecondStage, setSelectedSecondStage] = useState('Select Second Stage');
  const firstStageLabels = [
    { label: '-7: Closed No Immediate Response', color: '#FF5733' },
    { label: '-6: Closed Duplicate', color: '#33FF57' },
    { label: '-5: Closed Free Advice', color: '#5733FF' },
    { label: '-4: Closed No Response', color: '#FF33A1' },
    { label: '-3: Closed Low Value', color: '#FFC300' },
    { label: '-2: Closed Irrelevant', color: '#6C3483' },
    { label: '-1: Closed Unsuccessful', color: '#FF6347' },
    { label: '0: Inquiry', color: '#2E8B57' },
    { label: '1: Follow up Client', color: '#20B2AA' },
    { label: '2: Follow up Lawyer', color: '#FF4500' },
    { label: '3: Free Consultation', color: '#8A2BE2' },
    { label: '3: Consultation Booked', color: '#D2691E' },
    { label: '4: Closed as Consultation', color: '#A52A2A' },
    { label: '5: Attestation', color: '#BDB76B' },
    { label: '6: Closed as Attested', color: '#8B0000' },
    { label: '7: Legal Service Booked', color: '#0000FF' },
    { label: '8: Closed as Legal Service', color: '#4682B4' },
    { label: '9: Business Setup', color: '#9ACD32' },
    { label: '10: Closed Business Setup', color: '#FF1493' },
    { label: '11: Case-Waiting for Closure', color: '#FF6347' },
    { label: '12: Converted to Client', color: '#FFD700' },
    { label: '13: Closed as Retainer', color: '#2F4F4F' }
  ];
  const [refreshKey, setRefreshKey] = useState(0);
  const secondStageLabels = [
    { label: 'Incorrect Number', color: '#5D4037' },
    { label: 'Email Follow Up', color: '#3F51B5' },
    { label: 'Office Meeting', color: '#FFCA28' },
    { label: 'Confirming Fees', color: '#FF5722' },
    { label: 'Sending LFP', color: '#7E57C2' },
    { label: 'Drafting a Letter', color: '#0288D1' },
    { label: 'Missed Call', color: '#E91E63' },
    { label: 'Send C Form', color: '#FF7043' },
    { label: 'Online Meeting', color: '#3949AB' },
    { label: 'Sent Case Strategy', color: '#80DEEA' },
    { label: 'Sending a Quotation', color: '#8BC34A' },
    { label: 'Awaiting Signature', color: '#3E2723' },
    // Add all other labels shown in your image
  ];
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // The empty dependency array ensures this only runs once on mount
 
  useEffect(() => {
    const getData = async()=>{
      setLoading(true);
      try {
        const response = await axios.get(`${ApiEndPoint}/stages`);
        const groupedStages = response.data.reduce((acc, item) => {
          if (!acc[item.stage_id]) {
            acc[item.stage_id] = {
              stageId: item.stage_id,
              stageName: item.stage_name,
              labels: [],
            };
          }
          acc[item.stage_id].labels.push({
            labelId: item.label_id,
            labelText: item.label_text,
            labelColor: item.label_color,
          });
          console.log("Check stages updates:",acc);
          return acc;
          
        }, {});
  
        setStages(Object.values(groupedStages));
      } catch (err) {
        setError("Failed to fetch stages and labels");
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, [refreshKey]);
  // Function to allow children to trigger updates
  const triggerUpdate = () => {
    setRefreshKey((prev) => prev + 1);
  };
  useEffect(() => {
    const fetchInitialValue = async () => {
      try {
        const response = await axios.post(`${ApiEndPoint}/getHandOver`, { phone });
        
        if (response.data.isHumanActive !== undefined) {
          setIsHumanActive(response.data.isHumanActive);
          // console.log("status code", response.data.isHumanActive);
        }
      } catch (error) {
        console.log('Failed to fetch initial value:', error);
        if (error.response && error.response.status === 404) {
          setIsHumanActive(false);
        }
      }
    };

    // Fetch the initial value immediately
    fetchInitialValue();

    // Set up the interval to fetch every 5 seconds
    const intervalId = setInterval(fetchInitialValue, 5000);

    // Clean up the interval on unmount or when dependencies change
    return () => clearInterval(intervalId);
  }, [phone, triggerEffect]);
  useEffect(() => {
    const checkSession = async () => {
        try {
            const response = await fetch(`${ApiEndPoint}/lastMessageTimestamp/${phone}`);
            
          
            if (!response.ok) {
                throw new Error('Failed to fetch timestamp');
            }
            
            const data = await response.json();
            const currentTime = new Date().getTime();
            const timestampTime = new Date(data.timestamp).getTime();
            const twentyFourHours = 24 * 60 * 60 * 1000;
             console.log("Check response data of expired:",timestampTime, "current Time:",currentTime);
            // Check if the timestamp is older than 24 hours
          // Calculate the time difference
          const timeDifference = Math.abs(currentTime - timestampTime);
        console.log("Time difference:", timeDifference, "Twenty-four hours in ms:", twentyFourHours);

        // Check if the timestamp is older than 24 hours
        if (timeDifference > twentyFourHours) {
            setClientSession(true); // Set as expired if over 24 hours
        } else {
            console.log("Session is not expired");
            setClientSession(false); // Not expired if within 24 hours
        }
        } catch (error) {
            console.error('Error fetching timestamp:', error);
        }
    };

    // Call the function
    checkSession();
}, [phone]); // Re-run the effect when the `phone` prop changes
  const toggleSwitch = async () => {
    const newIsHumanActive = !isHumanActive;
    setIsHumanActive(newIsHumanActive);

    
        const response = await axios.post(`${ApiEndPoint}/handOvertoHuman`, {
          phone,
          isHumanActive: newIsHumanActive,
        });
       
     
    
  };

  return (
    <div
      className="py-0 px-0 d-none d-lg-block mt-1 mb-1"
      style={{
        maxHeight: '9vh',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        className="d-flex align-items-center"
        style={{ height: '100%', marginLeft: '10px', marginRight: '10px' }}
      >
        <div
          className="position-relative"
          style={{ marginLeft: '5px', marginRight: '10px' }}
        >
          <div
            className="rounded-circle d-flex justify-content-center align-items-center"
            style={{
              backgroundImage: profilePic
                ? `url(data:image/jpeg;base64,${profilePic})`
                : 'none',
              backgroundColor: profilePic ? 'transparent' : color_code,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              width: '30px',
              height: '30px',
              border: '1px solid #FFF',
              boxShadow: 'none',
            }}
          >
            <div
              style={{ margin: 'auto', textAlign: 'center', color: '#FFF', fontSize:"10px"}}
            >
              {!profilePic && name && name.length > 0
                ? name
                    .split(' ')
                    .map((n) => n[0])
                    .join('')
                : ''}
            </div>
          </div>
        </div>
        {/* Name and phone */}
        <div className="flex-grow-1 d-flex flex-column align-items-start pl-2">
          <strong className="text-start w-100">{name || 'Name'}</strong>
          <div
            className="text-muted fs-30 text-start w-100"
            style={{ fontSize: '10px' }}
          >
            {phone ? `+${phone}` : ''}
            {isClientSessionExpired && (
              <div
                className="Session-Expired"
                style={{
                  color: 'red',
                }}
              >
                Session Expired
              </div>
            )}
          </div>
        </div>
        <d className="d-flex align-items-center">
         {/* Dropdowns for First Stage and Second Stage */}
         <div
  className="d-flex align-items-start" // Flexbox container for horizontal alignment
  style={{
    height: '100%',
    marginLeft: '5px',
    marginRight: '5px',
    justifyContent: 'space-between', // Distribute dropdowns horizontally
  }}
>
<div className="d-flex justify-content-between align-items-center">
{loading && <p>Loading stages...</p>}
      {error && <p>{error}</p>}
      {!loading && !error && stages.length === 0 && <p>No stages available.</p>}
      {stages.map((stage) => (
        <div key={stage.stageId}>
          <h3>{stage.stageName}</h3>
          <SheetDropdown
            key={stage.stageId}
            stageId={stage.stageId}
            stageTitle={stage.stageName}
            dropdownLabels={stage.labels}
            clientId={clientId}
            triggerUpdate={triggerUpdate} // Pass triggerUpdate to child
          />
        </div>
      ))}
    </div>
</div>


          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginRight: '15px' }}>
            <span style={{ color: isHumanActive ? '#ccc' : '#24D366' }}>Switch to Bot</span>
            <label style={{ position: 'relative', width: '40px', height: '20px' }}>
              <input
                type="checkbox"
                style={{ opacity: 0, width: 0, height: 0 }}
                checked={isHumanActive}
                onChange={toggleSwitch}
              />
              <span
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: isHumanActive ? '#24D366' : '#ccc',
                  transition: '.4s',
                  borderRadius: '15px',
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    content: '""',
                    height: '14px',
                    width: '14px',
                    left: '3px',
                    bottom: '3px',
                    backgroundColor: 'white',
                    transition: '.4s',
                    borderRadius: '50%',
                    transform: isHumanActive ? 'translateX(20px)' : 'translateX(0)',
                  }}
                ></span>
              </span>
            </label>
            <span style={{ color: isHumanActive ? '#24D366' : '#ccc' }}>Switch to Agent</span>
          </div>
          <button
            className="mr-1"
            style={{ marginRight: '10px', background: 'none', border: 'none', padding: 0 }}
          >
            <img src={Voice} width={25} height={25} alt="Voice" />
          </button>
          <button
            className="mr-1"
            style={{ marginRight: '10px', background: 'none', border: 'none', padding: 0 }}
          >
            <img src={Video} width={25} height={25} alt="Video" />
          </button>
          <button
            style={{ background: 'none', border: 'none', padding: 0 }}
          >
            <img src={OPT} width={25} height={25} alt="Options" />
          </button>
        </d>
    </div>
      </div>
  );
  
}
