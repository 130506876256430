// store.js
import { configureStore } from '@reduxjs/toolkit';
import reducer from './slice';

const store = configureStore({
  reducer: {
    Data: reducer,
  },
  // `getDefaultMiddleware` already includes `redux-thunk`
  // No need to add `thunk` explicitly
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
