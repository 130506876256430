import React, { useState, useEffect } from "react";
import "./Temp_Build.css";
import pic from "./iphone.png"; // Your image file path
import btn1 from "../pages/components/images/btn3.png";
import btn2 from "../pages/components/images/btn2.png";
import { useSelector, useDispatch } from "react-redux";
import { Rightscreen,IsTemplate } from "../Redux Toolkit/slice";
import { ApiEndPoint } from "../pages/components/utils/utlis";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

function Temp_Build() {
  const dispatch = useDispatch();
  const dt = useSelector((state) => state.Data.Messagetemplatedata);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prevs, setPrevious] = useState(false);

  console.log(dt);

  const clipText = (text, maxLength) => {
    if (typeof text !== "string") return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const changeScreen = (value) => {
    dispatch(Rightscreen(value)); // Dispatching the Rightscreen action with the provided value
  };

  const handleConfirm = () => {
    dispatch(IsTemplate(dt.template_name));
    dispatch(Rightscreen("2")); 

    setShowAlert(false); // Hide the confirmation dialog
  };

  const getTemplateStatus = async () => {
    try {
      const response = await axios.post(`${ApiEndPoint}/getTemplateStatus`, {
        template_name: dt.template_name,
        language: dt.language,
      });
      const template = response.data[0]; // Assuming you receive an array with a single template object

      if (template.status === "APPROVED") {
        handleConfirm();
      } else if (template.status === "REJECTED") {
        alert("Template is rejected");
        // Handle displaying the rejected template details on the screen
        setPrevious(false);
        console.log("Rejected Template:", template);
      } else if (template.status === "PENDING") {
        setTimeout(getTemplateStatus, 10000); // Poll every 10 seconds if status is pending
      }
    } catch (error) {
      console.error("Error getting template status:", error);
    }
  };

  const submitData = async (e) => {
    e.preventDefault();
    console.log("Submitting data...");
    setLoading(true);
    setPrevious(true);

    try {
      const formData = new FormData();
      formData.append("file", dt.imgrec);
      if (dt.imgrec) {
        const response2 = await fetch(`${ApiEndPoint}/uploadimg`, {
          method: "POST",
          body: formData,
        });
        if (response2.status === 200) {
          const response = await axios.post(
            `${ApiEndPoint}/create-template`,
            dt
          );
          console.log(response.data);

          console.log("Dispatching Rightscreen('2')..."); // Add log before dispatch

          console.log("Rightscreen dispatched!");
          setTimeout(getTemplateStatus, 10000);
        }
      } else {
        const response = await axios.post(`${ApiEndPoint}/create-template`, dt);
        console.log(response.data);

        console.log("Dispatching Rightscreen('2')..."); // Add log before dispatch

        console.log("Rightscreen dispatched!");
        setTimeout(getTemplateStatus, 10000);
      }
      // Poll every 10 seconds // Add log after dispatch
    } catch (error) {
      console.error("Error creating template:", error);
    }
  };

  function SMSBubble() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: 5,
          marginTop: 90,
        }}
      >
        <div className="bubble"></div>
        <div className="sms-bubble">
          <div className="message">
            <p>
              {dt.header_format === "text" ? (
                <span style={{ fontSize: 18, fontWeight: "bold" }}>
                  {dt.header}
                </span>
              ) : dt.header_format === "Image" ? (
                <img
                  style={{
                    height: 120,
                    width: "100%",
                    borderRadius: 8,
                    opacity: 0.6,
                  }}
                  src={dt.header_img}
                  alt="Header Image"
                />
              ) : (
                <span style={{ fontSize: 18, fontWeight: "bold" }}></span>
              )}
              <br />
              <span style={{ fontSize: 14, fontWeight: 500 }}>{dt.body}</span>
              <br />
              <span
                style={{ fontSize: 12, fontWeight: "semibold", color: "grey" }}
              >
                {dt.footer}
              </span>
            </p>
          </div>
          <div style={{ marginTop: 12, marginBottom: 10 }}>
  {dt?.buttonsData?.map((item, index) => (
    <React.Fragment key={index}>
      <hr
        style={{
          border: "none",
          borderTop: "1px solid #999",
          width: "100%",
          margin: "10px 0",
        }}
      />
      <div
        style={{
          color: "#0A89ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={item.type === "URL" ? btn2 : btn1}
          height={16}
          width={18}
          style={{ marginRight: 5, color: "#0A89ED" }}
          alt="Button Icon"
        />
        <p style={{ color: "#0A89ED", fontSize: 14 }}>
          {clipText(item.text, 10)}
        </p>
      </div>
    </React.Fragment>
  ))}
</div>

        </div>
      </div>
    );
  }
  

  return (
    <div className="App">
      <div className="top">
        <h4 className="heading">Template Preview</h4>
      </div>
      <img src={pic} className="iphone" alt="iPhone" />
      <div className="screen">
        <SMSBubble />
      </div>

      <div className="bottom">
        <button
          onClick={() => changeScreen("1")}
          disabled={prevs}
          className="home-button"
        >
          Previous
        </button>
        <div className="button-container">
          <button
            onClick={submitData}
            className="home-button"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} className="circular-progress" />
            ) : (
              "Next"
            )}
          </button>
        </div>
      </div>
      <span>
        {loading ? "Please wait while Meta approves the template" : ""}
      </span>
    </div>
  );
}

export default Temp_Build;
