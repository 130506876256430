import React from "react";
import rightChatstyle from "./Chat.module.css";
import { formatTimestamp, messageTime, splitSenderName } from "../components/utils/utlis";
// Make sure to import your CSS module
import styles from "./widgets/dynamicDocument.module.css";
import { FaCheck, FaCheckDouble, FaExclamationCircle, FaClock } from "react-icons/fa";
import RenderStatusIcon from "./widgets/renderMessageStatus";
export default function RightChatTextWidget({ Name, time, message, botImage, status, id, type, blockScroll, unblockScroll }) {
  const date = new Date(time);
  const timeDate = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const base64ToUrl = (base64String) => `data:image/jpeg;base64,${base64String}`;

  // Function to render status icon



  return (
    <div className={`${rightChatstyle["chat-message-right"]} pb-4 d-flex`}>
      <center>
        <div className={styles[`avatar-right p2`]}>
          <img
            alt="Admin"
            src={botImage ? botImage : "https://bootdey.com/img/Content/avatar/avatar1.png"}
            className="rounded-circle"
            width={30}
            height={30}
            title={Name}
          />
          <div className="text-center text-wrap sender-name">
            {Name}
            <br />
            {formatTimestamp(time)}
          </div>
        </div>
      </center>

      <div
        className="flex-shrink-1 bg-light rounded pt-3 px-2 position-relative"
        style={{
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
          maxWidth: "70%",
          minWidth: "20%",
          paddingTop: "8px",
          textAlign: "left"
        }}
      >
        {/* The actual message */}
        {message}

        {/* Status icon positioned at the bottom-right */}
        <div
          style={{
            marginBottom: "10px",
            marginLeft: "90%",
          }}
        >
          <RenderStatusIcon
            status={status}
            message={message}
            id={id}
            type={type}
            blockScroll={blockScroll}
            unblockScroll={unblockScroll}
          />

          {/* {RenderStatusIcon({
            status:status,
            message:message,
            id:id,
            type:type
          })} */}


        </div>
      </div>
    </div>
  );

}


