import React from "react";
import styles from "./Chat.module.css"; // Import the CSS module
import { formatTimestamp, messageTime, splitSenderName } from "../components/utils/utlis"; // Make sure to import your utils

export default function LeftChatTextWidget({ Name, time, message, profilePic }) {
  const date = new Date(time);
  const timeDate = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const base64ToUrl = (base64String) => `data:image/jpeg;base64,${base64String}`;

  return (
    <div className={styles["chat-message-left"] + " pb-4 d-flex"}>
      <div className={styles["avatar-left p2"]}>
        <img
          alt="User"
          src={profilePic ? base64ToUrl(profilePic) : "https://bootdey.com/img/Content/avatar/avatar1.png"}
          className="rounded-circle"
          width={30}
          height={30}
          title={Name}
        />
        <div className={`text-center ${styles["text-wrap"]} ${styles["sender-name"]}`}>
         
          <br />
          {formatTimestamp(time)}
        </div>
      </div>
      <div
        className="flex-shrink-1 bg-light rounded py-2 px-1"
        style={{
          wordWrap: "break-word",
          maxWidth: "70%",
        }}
      >
        {message}
        <br />
      </div>
    </div>
  );
}
