import React, { useState } from "react";
import "../Style/CSS.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Templatedata, Rightscreen } from "../Redux Toolkit/slice";
import { ApiEndPoint } from "../pages/components/utils/utlis";
import { CSSProperties } from "react";
import axios from "axios";
import FadeLoader from "react-spinners/ClipLoader";

import style from "../Style/CSS.module.css";
function Messagetemplate() {
  var dispatch = useDispatch();
  let [loading, setLoading] = useState(false);
  const [templateData, setTemplateData] = useState({
    template_name: "",
    body: "",
    language: "",
    header: "",
    category: "MARKETING",
    numberOfButtons: 1,
    selectedButtonType: "QUICK_REPLY",
    header_format: "none", // Default to text format
    buttonsData: [],
    footer: "Sent via AWS CRM",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "header_format" && value === "text") {
      // Clear the header value
      setTemplateData((prevData) => ({ ...prevData, header: "" }));
    }

    // Update the state with the new value
    setTemplateData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setTemplateData((prevData) => ({
          ...prevData,
          header: event.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  const handleButtonTypeChange = (e) => {
    const { value } = e.target;
    setTemplateData((prevData) => ({
      ...prevData,
      selectedButtonType: value,
      buttonsData: [], // Clear previous buttons when selecting a new type
    }));
  };

  const handleButtonChange = (index, e) => {
    const { name, value } = e.target;
    const updatedButtonsData = [...templateData.buttonsData];
    // If the name is "text" or "url", update the corresponding field in the buttons data
    if (name === "text" || name === "url") {
      updatedButtonsData[index][name] = value;
    }

    setTemplateData((prevData) => ({
      ...prevData,
      buttonsData: updatedButtonsData,
    }));
  };

  const handleNumberOfButtonsChange = (e) => {
    const { value } = e.target;
    const selectedButtonType = templateData.selectedButtonType;

    let newButtons = [];
    for (let i = 0; i < parseInt(value); i++) {
      if (selectedButtonType === "QUICK_REPLY") {
        newButtons.push({ type: "QUICK_REPLY", text: "", url: "" });
      } else if (selectedButtonType === "URL") {
        newButtons.push({ type: "URL", text: "", url: "" });
      }
    }

    setTemplateData((prevData) => ({
      ...prevData,
      buttonsData: newButtons,
      numberOfButtons: parseInt(value),
    }));
  };

  const addNewButton = (value) => {
    const { selectedButtonType, numberOfButtons, buttonsData } = templateData;

    let newButtons = [];
    for (let i = 0; i < parseInt(value); i++) {
      if (selectedButtonType === "QUICK_REPLY") {
        newButtons.push({ type: "QUICK_REPLY", text: "" });
      } else if (selectedButtonType === "URL") {
        newButtons.push({
          type: "",
          text: "",
          url: "",
        });
      }
    }

    // Add the new buttons based on the selected type and number
    setTemplateData((prevData) => ({
      ...prevData,
      buttonsData: [...buttonsData, ...newButtons],
    }));
  };
  const removeButton = (index) => {
    const updatedButtonsData = [...templateData.buttonsData];
    updatedButtonsData.splice(index, 1);
    setTemplateData((prevData) => ({
      ...prevData,
      buttonsData: updatedButtonsData,
    }));
  };
  var errorfooter = "";
  //inputtemp  value validation
  if (templateData.footer.length > 60) {
    errorfooter = "add digits less than 60";
  }
  var errorheader = "";
  if (templateData.header_format.length > 60) {
    errorheader = "text length less than 60";
  }
  var errortemplate = "";
  if (templateData.template_name.length > 60) {
    errortemplate = "add digits less than 60";
  }
  var errorbody = "";
  if (templateData.body.length > 1024) {
    errorbody = "add digits less than 1024";
  }

  // Regular expression to check for uppercase letter or empty space
  var errortempuppercase = "";
  if (
    /[A-Z]/.test(templateData.template_name) ||
    templateData.template_name.includes(" ")
  ) {
    // Display error message
    errortempuppercase =
      "Template Data should not contain uppercase letters or empty spaces!";
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (templateData.template_name === "") {
      alert("Give template Name");
    } else if (templateData.category === "") {
      alert("Select Category value");
    } else if (templateData.language === "") {
      alert("Select Language value");
    } else if (templateData.body === "") {
      alert("Write text in body content");
    } else {
      setLoading(true);
      console.log("Templted Data:", templateData);
      try {
        // Send the POST request to your API endpoint
        const response = await axios.post(
          `${ApiEndPoint}/create-template`,
          templateData
        );
        console.log(response.data); // Log the response from the server
      } catch (error) {
        console.error("Error creating template:", error);
      }
      dispatch(Templatedata(templateData));
      dispatch(Rightscreen("2"));
    }
  };
  let headerInputtemp = null;
  if (templateData.header_format === "text") {
    headerInputtemp = (
      <>
        <div className="d-flex justify-content-between">
          <p className="fw-bold">Header Text</p>
          <p>{templateData.header.length}/60</p>
        </div>
        <input
          type="text"
          placeholder="Header text"
          name="header"
          onChange={handleChange}
          className="p-2 messagetemplate inputtemp"
          style={{ width: "20rem" }}
        />
        <label style={{ color: "goldenrod" }}>{errorheader}</label>
      </>
    );
  } else if (templateData.header_format === "image") {
    headerInputtemp = (
      <>
        <div className="d-flex justify-content-between">
          <p className="fw-bold">Header Image</p>
        </div>
        {/* Add inputtemp field for header image */}
        <input
          type="file"
          accept="image/*"
          name="header"
          onChange={handleFileChange}
          className="p-2 messagetemplate inputtemp"
          style={{ width: "20rem" }}
        />
      </>
    );
  }
  return (
    <div className={` container-fluid col-12 d-flex `}>
      {/*Header part */}

      <form
        className={` Theme3  col-12 pb-0  position-fixed d-flex flex-column gap-3   rounded p-4`}
        style={{ width: "75%" }}
        onSubmit={handleSubmit}
      >
        <div style={{ maxHeight: "calc(100vh - 140px)", overflowY: "auto" }}>
          <p className="fs-4 fw-bolder">Edit Message Template</p>
          <div className="pt-4">
            <div className=" d-flex justify-content-between">
              <p className="fw-bold">Template Name</p>
              <p>{templateData.template_name.length}/60</p>
            </div>
            <input
              type="text"
              name="template_name"
              className="p-2 messagetemplate inputtemp w-100"
              placeholder="template_name"
              onChange={handleChange}
              required
              style={{ marginTop: "-0.6rem" }}
            />
            <label style={{ color: "goldenrod" }}>{errortemplate}</label>
            <label style={{ color: "goldenrod" }}>{errortempuppercase}</label>
          </div>
          {/* Other form fields go here */}
          <label className="mt-3 fw-bold">Language</label>
          
          <div style={{ marginTop: "-0.2rem" }}>
            <select
              class="form-select"
              className="p-2 messagetemplate inputtemp "
              onChange={handleChange}
              name="language"
              style={{ width: "20rem" }}
              required
            >
              <option selected>Select Language</option>
              <option value="en_US">English-US</option>
            </select>
          </div>
          <hr />
          <div>
            <div>
              <span className="fw-bold">Header</span> (Optional)
              <p>Add title that you want to show in message header</p>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <p className="fw-bold">Header Type</p>
                <div style={{ marginTop: "-0.6rem" }}>
                  <select
                    class="form-select"
                    className="p-2 messagetemplate inputtemp "
                    name="header_format"
                    style={{ width: "20rem" }}
                    onChange={handleChange}
                  >
                    <option selected>None</option>
                    <option value="text">Text</option>
                    <option value="image">Image</option>
                  </select>
                </div>
              </div>

              <label>{headerInputtemp}</label>
            </div>
          </div>
          <hr />
          <div>
            <label className="fw-bold">Body</label>
            <p>Enter the text for your message</p>
            <div className="d-flex justify-content-between">
              <p className="fw-bold">Body Content</p>
              <p>{templateData.body.length}/1024</p>
            </div>
            <div style={{ marginTop: "-0.6rem" }}>
              <textarea
                placeholder="Enter text here"
                rows="5"
                name="body"
                onChange={handleChange}
                className="p-2 rounded messagetemplate inputtemp w-100"
                required
              />
              <label style={{ color: "goldenrod" }}>{errorbody}</label>
            </div>
          </div>
          <hr />
          <div>
            <label>{templateData.buttonsData.text}</label>

            <label>
              <span className="fw-bold">Footer</span> (Optional)
            </label>
            <p>
              Add short type of text that shown bottom of your message template
            </p>
            <div className=" d-flex justify-content-between">
              <p className="fw-bold">Footer Text</p>
              <p>{templateData.footer.length}/60</p>
            </div>
            <div style={{ marginTop: "-0.6rem" }}>
              <input
                type="text"
                className="p-2 messagetemplate inputtemp w-100"
                name="footer"
                onChange={handleChange}
                placeholder="Sent via AWS CRM.com"
                value={templateData.footer}
              />
              <label style={{ color: "goldenrod" }}>{errorfooter}</label>
            </div>
          </div>
          <hr />
          {/* Button */}
          <div>
            <span className="fw-bold">Button</span> (Optional)
            <p>
              Create upto 2 buttons that let customer respond to your message or
              take action
            </p>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ width: "56%" }}
          >
            <div className="mt-0">
              {/* Radio button */}
              {(templateData.selectedButtonType === "QUICK_REPLY" ||
                templateData.selectedButtonType === "URL") && (
                <div className="">
                  <p className="fw-bold">Number of Buttons</p>
                  <div
                    className="gap-4 d-flex "
                    style={{ marginTop: "-0.6rem" }}
                  >
                    <div>
                      <input
                        id="1"
                        type="radio"
                        name="numberOfButtons"
                        value={1}
                        onChange={handleNumberOfButtonsChange}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginTop: "0.3rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      <label> One</label>
                    </div>
                    <div>
                      <input
                        id="2"
                        type="radio"
                        name="numberOfButtons"
                        value={2}
                        onChange={handleNumberOfButtonsChange}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginTop: "0.3rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      <label>Two</label>
                    </div>
                    <div>
                      <input
                        id="3"
                        type="radio"
                        name="numberOfButtons"
                        value={3}
                        onChange={handleNumberOfButtonsChange}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginTop: "0.3rem",
                          marginRight: "0.2rem",
                        }}
                      />
                      <label>Three</label>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div>
                <label>
                  <p className="fw-bold">Button Type</p>

                  <select
                    onChange={handleButtonTypeChange}
                    className="p-2 messagetemplate inputtemp"
                    style={{ width: "20rem", marginTop: "-0.6rem" }}
                  >
                    <option value="QUICK_REPLY">Quick Reply</option>
                    <option value="URL">URL</option>
                  </select>
                </label>
              </div>
            </div>
          </div>
          {/* Inputtemp fields for buttons */}
          {templateData.buttonsData.map((button, index) => (
            <div key={index} className="d-flex gap-5">
              <label>
                <p className="fw-bold"> Button {index + 1} Text:</p>
                <div style={{ marginTop: "-0.6rem" }}>
                  <input
                    id={index}
                    type="text"
                    value={button.text}
                    onChange={(e) => handleButtonChange(index, e)} // Pass the index along with the event
                    className="p-2 messagetemplate inputtemp"
                    style={{ width: "20rem" }}
                    name="text" // Specify the name of the inputtemp field as "text"
                  />
                </div>
              </label>
              {templateData.selectedButtonType === "URL" && (
                <label>
                  <p className="fw-bold">Button {index + 1} URL:</p>
                  <div style={{ marginTop: "-0.6rem" }}>
                    <input
                      type="text"
                      value={button.url}
                      placeholder="http://"
                      style={{ width: "20rem" }}
                      onChange={(e) => handleButtonChange(index, e)} // Pass the index along with the event
                      className="p-2 messagetemplate inputtemp"
                      name="url" // Specify the name of the inputtemp field as "url"
                    />
                  </div>
                </label>
              )}
            </div>
          ))}
        </div>
        <hr />
        <button
          type="submit"
          className="p-2 inputtemp fw-bold"
          style={{
            background: "goldenrod",
            color: "aliceblue",
            border: "none",
          }}
        >
          Next
        </button>
        <span
          className="text-center"
          style={{ position: "relative", top: "-3.5rem" }}
        >
          <FadeLoader color="white" loading={loading} />
        </span>
      </form>
    </div>
  );
}
const Module = ({ templateData }) => {
  return (
    <>
      {/* Template Preview */}
      <div>
        <div
          className="rounded Theme position-fixed"
          style={{ width: "22rem", top: "40%" }}
        >
          <p className="p-3 pb-2 fw-bolder fs-5">Template Preview</p>
          <div style={{ background: "rgb(234, 211, 186)" }} className="p-2">
            <div className="p-2 rounded Theme w-100">
              {templateData.header_format === "image" ? (
                <img
                  src={templateData.header}
                  alt="HeaderImage"
                  className="mb-2 img-fluid"
                />
              ) : (
                <p className="mt-2 fw-bold fs-6">{templateData.header}</p>
              )}
              {templateData.body === "" ? (
                <p className=" fw-bold" style={{ marginTop: "-0.7rem" }}>
                  Body text here
                </p>
              ) : (
                <p style={{ fontWeight: "380", marginTop: "-0.7rem" }}>
                  {templateData.body}
                </p>
              )}
              <p style={{ fontWeight: "lighter" }}>{templateData.footer}</p>
              <hr />
              {templateData.buttonsData.map((button, index) => (
                <NavLink
                  key={index}
                  className="gap-1 d-flex fw-bold fs-6 justify-content-center align-items-center text-decoration-none"
                >
                  <FaExternalLinkAlt />
                  {button.text}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Messagetemplate;
