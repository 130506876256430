import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { BsPerson } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import phone input styles
import axios from "axios";
import { useNavigate } from "react-router-dom";
//import { useAuth } from "../Auth/AuthContext"; // Import your authentication context
import Warning, { ApiEndPoint } from "../components/utils/utlis";
import loginStyle from "../ContactForm/contact.module.css";
import defaultProfilePic from "../components/assets/icons/person.png";
import { MdOutlineAttachEmail } from "react-icons/md";
import { TbNumber6Small } from "react-icons/tb";
import { RiLockPasswordLine } from "react-icons/ri";
const ContactForm2 = ({ onClose }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [username, setUserName] = useState("");
  const [Password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [validFields, setValidFields] = useState(false); // This will track if all fields are valid

  const handleCloseWarning = () => setShowWarning(false);
  const [profilePicBase64, setProfilePicBase64] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        setProfilePicBase64(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click(); // Trigger click on the hidden file input
  };

  // Email validation regex pattern
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Validate form fields
  const validateForm = () => {
    const isNameValid = username.trim() !== "";
    const isEmailValid = emailPattern.test(email);
    const isPasswordValid = Password.length >= 6 && Password.length <= 16;
    setNameError(!isNameValid);
    setEmailError(!isEmailValid);
    setPasswordError(!isPasswordValid);
    setValidFields(isNameValid && isEmailValid && isPasswordValid);
  };

  useEffect(() => {
    validateForm(); // Re-validate whenever any field changes
  }, [username, email, Password]);

  const handleNavigation = async (event) => {
    event.preventDefault();

    if (!validFields) {
      setErrorMessage("Please fill in all fields correctly.");
      return;
    }

    try {
      const requestData = {
        username: username,
        email: email,
        password: Password,
        role: "admin",
        phone: phoneNumber,
        profilePicture: profilePicBase64,
      };

      const response = await axios.post(`${ApiEndPoint}/admin`, requestData);
      if (response.status === 200) {
        onClose();
        // Handle success scenario
      }
    } catch (error) {
      if (error.response.status === 409) {
        setErrorMessage("Client already exists.");
        setShowWarning(true);
      } else {
        setErrorMessage("An error occurred. Please try again later.");
        setShowWarning(true);
      }
    }
  };
  const handleFocus = (e) => {
    e.target.style.borderColor = "#25D366";
    e.target.style.boxShadow = "none";
    e.target.style.fill = "#25D366";
  };
  
  const handleBlur = (e) => {
    e.target.style.borderColor = "#d3d3d3";
    e.target.style.boxShadow = "none";
    e.target.style.fill = "#25D366";
  };
  return (
    <div>
      <form className="d-flex flex-column justify-content-center">
        <div className="mb-2 text-center avatar-container">
          <label htmlFor="profilePicInput">
            <img
              src={profilePicBase64 ? `data:image/jpeg;base64,${profilePicBase64}` : defaultProfilePic}
              alt="Profile"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
                minWidth: "120px",
                minHeight: "120px",
                borderRadius: "50%",
                border: "1px solid goldenrod",
                boxShadow: "goldenrod 0px 2px 5px",
              }}
              className="avatar-img"
            />
          </label>
          <input
            type="file"
            accept="image/*"
            id="profilePicInput"
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </div>
  
        <div className="text-start d-flex flex-column gap-4">
          {/* Name Input */}
          <div style={{ marginTop: -20 }}>
            <label className="form-label font-weight-bold">
              <p className="ml-3 fw-bold" style={{ marginLeft: "3px", fontSize: "1.05rem" }}>
                Name
              </p>
            </label>
            <div className="input-group bg-soft-light rounded-2" style={{ marginTop: -8 }}>
              <span className="input-group-text">
                <BsPerson />
              </span>
              <input
                className="form-control-1 form-control-md form-control"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                id="Name"
                name="username"
                placeholder="Enter Name"
                type="text"
                title="Please Enter User Name"
                required
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
            {nameError && <p className="text-danger">Name is required.</p>}
          </div>
  
          {/* Email Input */}
          <div className="text-start" style={{ marginTop: -20 }}>
            <label className="form-label font-weight-bold">
              <p className="fw-bold" style={{ marginLeft: "3px", fontSize: "1.05rem" }}>
                Email
              </p>
            </label>
            <div className="input-group bg-soft-light rounded-2" style={{ marginTop: -8 }}>
              <span className="input-group-text">
                <MdOutlineAttachEmail />
              </span>
              <input
                className="form-control-1 form-control-md form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="Email"
                name="email"
                placeholder="Enter Email"
                type="email"
                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                title="Enter a valid email address"
                required
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
            {emailError && <p className="text-danger">Please enter a valid email address.</p>}
          </div>
  
          {/* Password Input */}
          <div style={{ marginTop: -20 }}>
            <label className="form-label font-weight-bold">
              <p className="ml-3 fw-bold" style={{ marginLeft: "3px", fontSize: "1.05rem" }}>
                Password
              </p>
            </label>
            <div className="input-group bg-soft-light rounded-2" style={{ marginTop: -8 }}>
              <span className="input-group-text">
                <RiLockPasswordLine />
              </span>
              <input
                className="form-control-1 form-control-md form-control"
                value={Password}
                onChange={(e) => {
                  const input = e.target.value;
                  setPassword(input);
                  setPasswordError(input.length < 6 || input.length > 16);
                }}
                id="Password"
                name="password"
                placeholder="Enter Password"
                type="password"
                title="Please Enter User Password"
                required
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
            {passwordError && <p className="text-danger">Password must be between 6 and 16 characters.</p>}
          </div>
  
          {/* WhatsApp Number Input */}
          <div className="text-start" style={{ marginTop: -20 }}>
            <label className="form-label" style={{ marginBottom: "-0.8rem" }}>
              <p className="fw-bold" style={{ marginLeft: "3px", fontSize: "1.05rem" }}>
                WhatsApp Number
              </p>
            </label>
            <div>
              <PhoneInput
                containerClass="form-control-md"
                inputProps={{
                  name: "phone",
                  required: false,
                  autoFocus: true,
                  onFocus: handleFocus,
                  onBlur: handleBlur,
                }}
                containerStyle={{
                  borderRadius: "10px",
                }}
                enableSearch={true}
                searchStyle={{
                  width: "100%",
                }}
                inputStyle={{
                  width: "100%",
                  border: "1px solid",
                  boxShadow: "none",
                  height: "37px",
                }}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
                country={"us"}
              />
            </div>
          </div>
  
          <div className="d-grid">
            {errorMessage && <p style={{ marginTop: -28 }} className="text-danger">{errorMessage}</p>}
          </div>
        </div>
  
        {/* Submit Button */}
        <div className="d-flex justify-content-center">
          <button
            disabled={!validFields}
            style={{ backgroundColor: "#A66CFF" }}
            onMouseEnter={(e) => e.target.style.backgroundColor = "#FF7640"}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#A66CFF'}
            className="btn btn-primary mt-3 border border-none"
            type="submit"
            onClick={handleNavigation}
          >
            Add User
          </button>
        </div>
      </form>
  
      {/* Warning Dialog */}
      {showWarning && (
        <div className="warning-dialog">
          <div className="dialog-content">
            <p>{errorMessage}</p>
            <button onClick={handleCloseWarning}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
  
};


export const UpdateForm2 = ({ user }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUserName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();

  const handleCloseWarning = () => setShowWarning(false);
  const [profilePicBase64, setProfilePicBase64] = useState("");
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Extract base64 string without data URL prefix
        setProfilePicBase64(base64String);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleUpdate = async (event) => {
    event.preventDefault();

    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    try {
      console.log(
        "UserName:",
        username,
        "Email:",
        email,
        "phoneNumber:",
        phoneNumber,
        "image:",
        profilePicBase64
      );

      const response = await axios.post(`${ApiEndPoint}/update`, {
        username: username,
        email: email,
        phoneNumber: phoneNumber,
        profilePicBase64: profilePicBase64,
      });
      console.log("USER Data:", response.data);
      if (response.status === 200) {
        console.log("USER success:", response);
        // Handle success scenario
      }
    } catch (error) {
      console.error("Error:", error.response.status);
      if (error.response.status === 409) {
        setErrorMessage("Client already exists.");
        setShowWarning(true); // Show warning modal for user already exists
      } else {
        setErrorMessage("An error occurred. Please try again later.");
        setShowWarning(true); // Show warning modal for other errors
      }
    }
  };
  return (
    <div>
      <form className=" Theme3">
        <div className="mb-2 text-center avatar-container">
          <label htmlFor="profilePicInput">
            <img
              src={
                user.profilePic
                  ? `data:image/jpeg;base64,${user.profilePic}`
                  : defaultProfilePic
              }
              alt="Profile"
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
                minWidth: "120px",
                minHeight: "120px",
                borderRadius: "50%",
                border: "1px solid goldenrod",
                boxShadow: "goldenrod 0px 2px 5px",
              }}
              className="avatar-img"
            />
          </label>
          <input
            type="file"
            accept="image/*"
            id="profilePicInput"
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </div>

        <div className="text-center">
          <label className="form-label font-weight-bold">
            <p
              className="fw-bold"
              style={{ marginLeft: "3px", fontSize: "1.05rem" }}
            >
              Profile Photo
            </p>
          </label>
        </div>
        <div className={" text-start d-flex flex-column gap-4"}>
          <div>
            <label
              className={"form-label font-weight-bold"}
              style={{ marginBottom: "15px" }}
            >
              <p
                className="fw-bold"
                style={{ marginLeft: "3px", fontSize: "1.05rem" }}
              >
                Name
              </p>
            </label>
            <div
              className={"input-group   bg-soft-light rounded-2 "}
              style={{ marginTop: "-1.8rem" }}
            >
              <span className={" input-group-text"}>
                <BsPerson />
              </span>
              <input
                className={
                  loginStyle["form-control-1"] + " form-control-md form-control"
                }
                value={user.name}
                onChange={(e) => setUserName(e.target.value)}
                id="Name"
                name="username"
                placeholder="Enter Name"
                type="text"
                title="Please Enter Client Name"
                required
              />
            </div>
          </div>

          <div className={" text-start"}>
            <label
              className={"form-label font-weight-bold"}
              style={{ marginBottom: "15px" }}
            >
              <p
                className=" fw-bold"
                style={{ marginLeft: "3px", fontSize: "1.05rem" }}
              >
                Email
              </p>
            </label>
            <div
              className={"input-group   bg-soft-light rounded-2"}
              style={{ marginTop: "-1.8rem" }}
            >
              <span className={" input-group-text"}>
                <MdOutlineAttachEmail />
              </span>
              <input
                className={
                  loginStyle["form-control-1"] + " form-control-md form-control"
                }
                value={user.email ? user.email : ""}
                onChange={(e) => setEmail(e.target.value)}
                id="Email"
                name="email"
                placeholder="Enter Email"
                type="email"
                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                title="Enter a valid email address"
                required
              />
            </div>
          </div>
          <div className=" text-start">
            <label className={"form-label"}>
              <p
                className="fw-bold "
                style={{
                  marginLeft: "3px",
                  fontSize: "1.05rem",
                  marginBottom: "-2rem",
                }}
              >
                WhatsApp Number
              </p>
            </label>
            <div>
              <PhoneInput
                containerClass="form-control-md"
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,

                  onFocus: (e) => {
                    // Set multiple styles on focus
                    e.target.style.borderColor = "goldenrod"; // Change border color
                    // Change border color

                    // Add box shadow
                  },

                  onBlur: (e) => {
                    e.target.style.borderColor = "#ccc"; // Revert border color on blur
                    e.target.style.boxShadow = "none"; // Remove box shadow on blur
                  },
                }}
                containerStyle={{
                  borderRadius: "10px",
                }}
                // enableSearch={true}
                // searchStyle={{
                //   width: "100%",
                // }}
                disableSearchIcon={true}
                inputStyle={{
                  width: "100%",
                  border: "1px solid",
                  boxShadow: "none",
                  height: "37px",
                }}
                buttonStyle={{}}
                country={"us"}
                value={user.phone}
                onChange={(phone) => setPhoneNumber(phone)}
              />
            </div>
          </div>

          <div className="d-grid">
            <button
              className={
                loginStyle["btn-color"] +
                " btn btn-warning d-block w-100 mb-1 mt-3 text-light fw-bold"
              }
              type="submit"
            >
              Update
            </button>
          </div>
        </div>
        {/* {errorMessage && <p className="text-danger">{errorMessage}</p>} */}
      </form>
    </div>
  );
};
const ContactFormModal2 = ({ isOpen, onClose }) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered
      // size="md"
    >
      {" "}
      <Modal.Header closeButton>
        <Modal.Title>Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContactForm2 onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
};

export default ContactFormModal2;
export { ContactForm2 };
