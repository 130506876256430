import React, { useEffect, useRef, useState } from "react";
import "../chat/Chat.module.css";
import UserListWidget from "./widgets/UserListWidget";
import ChatField from "./widgets/ChatField";
import axios from "axios";

//import ExcelUploadPopup from "../ContactForm/dropzonComponent";
import { useAuth } from "../Auth/AuthContext";
import { ApiEndPoint, decodeToken } from "../components/utils/utlis";
import { useNavigate } from "react-router-dom";



export default function Chat({ isCollapsed }) {
  //const Users = useSelector((state) => state.Data.usersdetail);

  const [users, setUsers] = useState([]);
  const { token, userId, login } = useAuth();
  const navigate = useNavigate();
  const [selectUser, setSelectedUser] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (token) {
      const { isExpired } = decodeToken(token);
      if (isExpired) {
        navigate("/");
      }
    }
  }, [navigate, token]); // State for search query

  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${ApiEndPoint}/getAllUsers`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter users based on search query
  const filteredUsers = users
    .filter((user) => {
      // Check if user.name is not null and not undefined
      if (user.name && typeof user.name === "string") {
        const lowerCaseName = user.name.toLowerCase();
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        return lowerCaseName.includes(lowerCaseSearchQuery);
      }
      return false; // Filter out user with null or non-string name
    });

  return (
    <div

      className=" p-0 m-0 d-flex flex-row"
      style={{
        borderRadius: '0',
        height: "86vh",
        width: "100%",
        margin: "0px",
        padding: "0px",

        // Add padding to show grey background
      }}
    >
      <div
        className="col-lg-3 col-md-3 col-sm-12  border-end cursor-pointer p-1"
        style={{
          maxHeight: "90vh",
          overflowY: "auto",
          width: '21%',
          backgroundColor: "#fff", // White background for the user list
          borderRadius: "10px", // Rounded corners for card-like appearance
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for card effect

        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <input
            type="search"
            className="form-control p-2 my-1 rounded"
            placeholder="Search User"
            onChange={handleSearchChange}
            value={searchQuery}
          />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "20px",
            fontSize: "1.1rem",
            maxHeight: "calc(82vh - 70px)",
            overflowY: "auto",
          }}
        >
          {filteredUsers.map((val) => (
            <UserListWidget
              key={val.id}
              user={val}
              onClick={() => handleUserClick(val)}
              picture={val.profilePic}
              color={val.profilePic}
              isChat={val.pendingMessagesCount ? true : false}

            />
          ))}
        </div>
      </div>
      <div
        className="gap-3 text-center d-flex flex-column justify-content-center align-items-center h-100 w-100"
        style={{
          width: '78.5%',
          backgroundColor: "#fff", // White background for the chat field
          borderRadius: "10px", // Rounded corners for card-like appearance
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for card effect
          marginLeft: "10px", // Space between the two inner divs

        }}
      >
        <ChatField user={selectUser} isCollapsed={isCollapsed} />
      </div>
    </div>

  );


}
